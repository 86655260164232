/**
 * Enum housing the commonly used content types across CMS content.
 * Helps reduce the amount of magic strings in the codebase.
 */
enum CMSContentTypes {
  Image = 'CMPictureImpl',
  Video = 'CMVideoImpl',
  Download = 'CMDownloadImpl',
  Teaser = 'CMTeaserImpl',
  Collection = 'CMCollectionImpl',
  Link = 'CMExternalLinkImpl',
  Channel = 'CMChannelImpl',
  Fragment = 'CMHTMLImpl'
}

export default CMSContentTypes;
