import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { useEffect, useLayoutEffect } from 'react';

/**
 * A hook that uses `useLayoutEffect` on the client and `useEffect` on the server.
 * @see {@link https://kentcdodds.com/blog/useeffect-vs-uselayouteffect#uselayouteffect useLayoutEffect vs useEffect}
 */
export const useIsomorphicLayoutEffect = (typeof window !== "undefined")
  ? useLayoutEffect
  : useEffect;
