'use client';

import { useEffect } from 'react';

/**
 * Disable document scrolling when condition is true.
 * @param condition - The condition.
 */
export default function useConditionallyDisableScrolling(
  condition: boolean
): void {
  useEffect(() => {
    if (condition) {
      // When Modal is open, we want to disable the scrolling for the entire page to have a smoother scrolling experience within the Modal.
      document.documentElement.style.overflowY = 'hidden';

      return () => {
        document.documentElement.style.overflowY = '';
      };
    }

    return undefined;
  }, [condition]);
}
