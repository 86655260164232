import { FunctionComponent, PropsWithChildren } from 'react';

import { ContentItemModel, IContentItem } from '@/services/models/Content';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { Link } from '../../../../core-ui/Link';

import {
  getContainerOptions,
  getCustomStyling
} from '../../../utils/styling-utils';
import { ContentImage } from '../../core/ContentImage';
import { TextBlock } from '../../core/TextBlock';

import S from './styles.module.scss';

export interface ISplitTileProps extends PropsWithChildren {
  /** The content item. */
  item: IContentItem;
}

/**
 * A tile with text and a cta on one side and an image on the other.
 * Takes up two tiles accross.
 */
export const SplitTile: FunctionComponent<ISplitTileProps> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const { custom, items, image } = itemModel;

  const styling = getCustomStyling(custom);
  const { flip } = getContainerOptions(custom);

  if (image) {
    image.aspectRatio = 'portrait_ratio3x4';
    image.mobileAspectRatio = 'portrait_ratio1x1';
  }

  return (
    <div className={classes(S.container, flip ? S.flip : '')} style={styling}>
      <div className={S.textSection}>
        <TextBlock item={itemModel} className={S.block} />
      </div>
      <div className={S.imageSection}>
        <Link {...itemModel.link} className={S.link}>
          {itemModel.image && <ContentImage image={itemModel.image} />}
        </Link>
      </div>
    </div>
  );
};
