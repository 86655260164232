'use client';

import React, {
  FC,
  useCallback,
  useContext,
  useMemo,
  MouseEvent,
  KeyboardEvent
} from 'react';
import { AnchorContext } from './AnchorContext';
import { Anchor } from './Anchor';

/**
 * The interface describing the objects returned from the useAnchor hook.
 */
interface IAnchor {
  /**
   * The anchor component {@link Anchor}.
   */
  Anchor: FC;
  /**
   * Scroll to the anchor. This will set the current anchorEl in the context, which
   * will trigger the scroll.
   * @param event - The event that kicks off the scroll.
   */
  scrollTo: (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
  ) => void;
  /**
   * Whether the anchor context is available.
   */
  hasContext: boolean;
}

/**
 * Anchor hook synchronizes the clicked anchor with the anchor context. This will
 * trigger the scroll to the anchor target, which is the {@link Anchor} component or a component wrapped
 * in {@link WithAnchor}.
 * @returns The anchor component and the scrollTo function.
 * @throws Will throw an error if used outside a {@link AnchorContextProvider}.
 */
export const useAnchor = (): IAnchor => {
  const { setAnchorEl, scope } = useContext(AnchorContext);
  const hasContext = useMemo(() => scope, [scope]);

  const scrollTo = useCallback(
    (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const target = event.currentTarget;
      setAnchorEl(target);
    },
    [setAnchorEl]
  );

  return {
    Anchor,
    scrollTo,
    hasContext
  };
};
