'use client';

import {
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useScroll } from 'framer-motion';
import type { IContentItem } from '@/services/models/Content';
import type { AspectRatioName } from '@/services/models/Media/ContentImage';
import { ContentItemModel } from '@/services/models/Content';
import { Slider, Slide, ISliderProps } from '../../../../Slider';
import { ScrollSlider } from './ScrollSlider';
import { useBreakpoints } from '../../../../../hooks/useBreakpoints';
import { DragCursor } from '../../../../utility/DragCursor';

import {
  Breakpoint,
  Breakpoints,
  Default
} from '../../../../core-ui/Breakpoints';
import { SlideContent } from './SlideContent';

import S from './styles.base.module.scss';

export interface IOverlaidNavigationSliderProps {
  /**
   * The {@link IContentItem} from which the width and height data and image
   * sources are extracted from to render the lifestyle slides.
   */
  item: IContentItem;
}

const DEFAULT_DESKTOP_RATIO = 'portrait_ratio1x1' as AspectRatioName;
const DEFAULT_MOBILE_RATIO = 'portrait_ratio3x5' as AspectRatioName;

/**
 * Generates slides based on an array of items.
 * @param items - Images off the product model.
 * @param aspectRatio - Aspect ratio.
 * @param size - Screen size reference - determines which image to grab.
 * @returns An array of react elements.
 */
function generateSlides(
  items: ReadonlyArray<ContentItemModel> | undefined,
  aspectRatio: AspectRatioName,
  size: string
): Array<ReactElement> {
  if (!items) return [];

  return items?.map((item, index) => {
    const key = `${item.id}_${index}`;

    return (
      <Slide key={key}>
        {({ isActive }) => (
          <div className={S.slideContent}>
            {size === 'desktop' ? (
              <DragCursor>
                <SlideContent
                  item={item}
                  aspectRatio={aspectRatio}
                  size={size}
                  isActive={isActive}
                />
              </DragCursor>
            ) : (
              <SlideContent
                item={item}
                aspectRatio={aspectRatio}
                size={size}
                isActive={isActive}
              />
            )}
          </div>
        )}
      </Slide>
    );
  });
}

/**
 * Slider that provides a scrolling advance system on mobile, and side
 * swiping on desktop.
 */
export const OverlaidNavigationSlider: FunctionComponent<
  IOverlaidNavigationSliderProps
> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const { custom, items } = itemModel;

  let { mainAspectRatio = DEFAULT_DESKTOP_RATIO } = custom as Record<
    string,
    AspectRatioName
  >;
  let { mobileAspectRatio = DEFAULT_MOBILE_RATIO } = custom as Record<
    string,
    AspectRatioName
  >;
  // Ensure values have actually been set...
  mainAspectRatio = mainAspectRatio ?? DEFAULT_DESKTOP_RATIO;
  mobileAspectRatio = mobileAspectRatio ?? DEFAULT_MOBILE_RATIO;

  const desktopItems = useMemo(
    () => generateSlides(items, mainAspectRatio, 'desktop'),
    [items, mainAspectRatio]
  );
  const mobileItems = useMemo(
    () => generateSlides(items, mobileAspectRatio, 'mobile'),
    [items, mobileAspectRatio]
  );

  const targetRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isSmMin } = useBreakpoints();

  // Set up our scroll progress tracker.
  const { scrollYProgress } = useScroll({
    // Track from the START (top) of the target hitting the
    // START (top) of the viewing pane, to the END (bottom) of
    // the target hitting the END (bottom) of the viewing pane.
    offset: ['start start', 'end end'],
    target: targetRef
  });

  // Amount of screen to scroll through for 1 slide view duration.
  const SLIDE_SCROLL_DISTANCE = 1.5;

  // Calculate and set the height of the target container element
  // for scroll tracking purposes, based on the number of slides.
  useEffect(() => {
    const targetHeight = mobileItems.length * SLIDE_SCROLL_DISTANCE * 100;
    if (targetRef?.current?.style && !isSmMin) {
      targetRef.current.style.height = `${targetHeight}vh`;
    }
  }, [mobileItems, targetRef, isSmMin]);

  const sliderConfig = {
    height: 'auto',
    loop: isSmMin,
    spaceBetween: 1,
    navigation: {
      enabled: false
    },
    pagination: {
      enabled: true,
      clickable: false,
      inset: true,
      activeClassName: S.activeBullet,
      wrapperClassName: S.paginationWrapper,
      buttonClassName: S.bullet,
      direction: 'horizontal'
    },
    transitionSpeedMS: 500,
    allowTouchMove: isSmMin
  } as ISliderProps;

  useEffect(() => {
    // Remove any direct height styling assignments that may
    // have been set in mobile view.
    if (isSmMin && targetRef) {
      const stylable: HTMLElement | null =
        targetRef?.current as HTMLElement | null;
      if (stylable) {
        setTimeout(() => {
          stylable.style.height = '';
        }, 10);
      }
    }
  }, [isSmMin, targetRef]);

  return (
    <div className={S.root} ref={targetRef}>
      <div className={S.container} ref={containerRef}>
        <Breakpoints>
          <Breakpoint media={['xxs', 'xs']}>
            <ScrollSlider
              {...sliderConfig}
              scrollYProgress={scrollYProgress}
              containerRef={containerRef}
            >
              {mobileItems}
            </ScrollSlider>
          </Breakpoint>
          <Default>
            <Slider {...sliderConfig}>{desktopItems}</Slider>
          </Default>
        </Breakpoints>
      </div>
    </div>
  );
};
