/**
 * Enumerates valid [Cloudinary Asset Types](https://cloudinary.com/documentation/image_transformations#transformation_url_structure).
 */
enum CloudinaryAssetType {
  Image = 'image',
  Video = 'video',
  Raw = 'raw'
}

export default CloudinaryAssetType;
