import { FunctionComponent } from 'react';

import { Slider, Slide } from '../../../../Slider';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { ImageBlock } from '../../core/ImageBlock';

export interface IImageSliderProps {
  /** The content item. */
  item: IContentItem;
}

/**
 * `HeroImageSlider` allows us to add slider images in Hero components.
 */
export const HeroImageSlider: FunctionComponent<IImageSliderProps> = ({
  item
}) => {
  const itemModel = ContentItemModel.from(item);

  if (itemModel.images && itemModel.images.length < 2) {
    return itemModel.image ? <ImageBlock image={itemModel.image} /> : null;
  }

  return (
    <Slider
      loop
      autoplay={{
        loop: true,
        delay: 4000
      }}
      navigation={false}
      height="auto"
    >
      {itemModel.images?.map((image) => {
        return (
          <Slide key={item.id}>
            <ImageBlock image={image} />
          </Slide>
        );
      })}
    </Slider>
  );
};
