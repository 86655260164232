'use client';

import { FunctionComponent, CSSProperties, useRef } from 'react';
import {
  IContentItem,
  ContentItemModel,
  ContentPositionX
} from '@/services/models/Content';
import type { Nullable } from '@/type-utils';
import { Accordion } from '../../../../core-ui/Accordion';
import INestableContent from '../../../traits/INestableContent';
import { IconTypes } from '../../../../core-ui/Icon';
import { FragmentParser } from '../../../../utility/FragmentParser';
import { msg } from '@/services/isomorphic/I18NService';
import classNames from 'classnames';
import { Button } from '../../../../core-ui/Button';
import { TextBlock } from '../TextBlock';
import {
  getContainerOptions,
  getItemOverlay
} from '../../../utils/styling-utils';
import S from './styles.module.scss';
import { general_readMore } from "@/lang/__generated__/ahnu/general_readMore";
import { general_close } from "@/lang/__generated__/ahnu/general_close";

/**
 * ContentAccordion variant type.
 */
export type ContentAccordionVariant = 'text' | 'collection' | 'default';

/**
 * Get the core styling from the variant string.
 * @param variant - String that represents styling variant.
 * @returns Returns a className from the styling import or an empty string.
 */
const getStylingFromVariant = (
  variant: Nullable<ContentAccordionVariant>
): string => {
  switch (variant) {
    case 'text':
      return S.text;
    case 'collection':
      return S.collection;
    case 'default':
    default:
      return '';
  }
};

export interface IContentAccordionProps extends INestableContent {
  /** The content item. */
  item: IContentItem;

  /** String representing a class that gets added. */
  variant?: ContentAccordionVariant;
}

/**
 * A ContentAccordion provides a generic way of rendering an accordion using
 * a collection.
 *
 * If it is a collection, it will render the first teaser in the collection as the
 * toggle and the remaining items as the content.
 *
 * In both cases, the clickable toggle will read "Show more" or "Show less" depending
 * on the state of the accordion.
 */
export const ContentAccordion: FunctionComponent<IContentAccordionProps> = ({
  item,
  variant,
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);
  const variantClass = getStylingFromVariant(variant);
  const { items, custom, settings } = itemModel;
  const isCollection = items && items.length > 1;
  const isText = variant === 'text';
  const styling = getContainerOptions(custom);
  const overlay = getItemOverlay(settings);
  const textRef = useRef<HTMLDetailsElement>(null);

  let positionXClass: string;
  switch (overlay.positionX) {
    case ContentPositionX.Left: {
      positionXClass = S.left;
      break;
    }
    case ContentPositionX.Right: {
      positionXClass = S.right;
      break;
    }
    default: {
      positionXClass = S.center;
      break;
    }
  }

  return (
    <div
      className={classNames(
        variantClass,
        styling?.containerClass ? S[styling.containerClass] : ''
      )}
      style={
        {
          ...styling,
          '--open': `${JSON.stringify(msg(general_readMore))}`,
          '--close': `${JSON.stringify(msg(general_close))}`
        } as CSSProperties
      }
    >
      {isText ? (
        <div className={classNames(overlay.text ?? '', positionXClass)}>
          <details className={classNames(S.details)} ref={textRef}>
            <summary className={S.summary}>
              <FragmentParser string={itemModel.title ?? ''} />
            </summary>
            <FragmentParser string={itemModel.text ?? ''} />
          </details>
          <Button
            className={S.button}
            variant="text"
            onClick={() => {
              if (textRef.current) {
                textRef.current.open = !textRef.current.open;
              }
            }}
          />
        </div>
      ) : (
        <Accordion
          title={isCollection ? contentResolver(items[0]) : item.title}
          titleClassName={S['button-row']}
          iconDown={IconTypes.Plus}
          iconUp={IconTypes.Minus}
          startsExpanded={false}
          variant="collection"
        >
          <div className={classNames(S.content, overlay.text)}>
            {isCollection ? (
              items.slice(1).map((item) => contentResolver(item))
            ) : (
              <TextBlock item={item} />
            )}
          </div>
        </Accordion>
      )}
    </div>
  );
};
