import { CSSObjectFit } from '@/type-utils/css';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';

import Service from '../../../Service';
import ConfigurationService, { Config } from '../../ConfigurationService';

import type { ICloudinaryAsset, ICloudinaryTransformation } from '.';
import CloudinaryAssetType from './CloudinaryAssetType';
import CloudinaryDeliveryType from './CloudinaryDeliveryType';
import siteCached from '../../../utils/siteCached';
import type { CloudinaryFitType } from './CloudinaryFitType';

/**
 * Integration service for [Cloudinary](https://cloudinary.com/).
 *
 * **From the docs:**.
 *
 * Cloudinary is a Software-as-a-Service (SaaS) solution for managing all your web or mobile
 * application's media assets in the cloud. Cloudinary offers an end-to-end solution for all
 * your image and video needs, including upload, storage, administration, transformation and
 * optimized delivery.
 *
 * Media upload, processing, and delivery are handled on Cloudinary's servers, which
 * automatically scale for handling high load and bursts of traffic.
 */
class CloudinaryService extends Service {
  /** @inheritdoc */
  @siteCached
  private get config(): Config<'cloudinary'> {
    return ConfigurationService.getConfig('cloudinary');
  }

  /** @inheritdoc */
  @siteCached
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- Let inference determine the type.
  private get transformationsConfig() {
    return this.config.getSetting('transformations').value;
  }

  /**
   * Transforms a {@link CSSObjectFit} into a Cloudinary crop.
   * @param fit - Object fit to convert.
   * @returns The equivalent Cloudinary crop.
   * @throws An {@link InvalidArgumentError} if the supplied fit is not valid.
   */
  public fitToCrop(fit: CloudinaryFitType): ICloudinaryTransformation['crop'] {
    switch (fit) {
      case 'contain':
        return 'fit';

      case 'cover':
        return 'fill';

      case 'fill':
        return 'scale';

      case 'none':
        return 'lfill';

      case 'auto':
        return 'auto';

      default:
        throw new InvalidArgumentError(
          `Supplied fit is not a valid CSS object fit. Received ${fit}`
        );
    }
  }

  /**
   * Parses one or more transformations into a single transformations string that
   * can be added to a Cloudinary URL.
   *
   * @param transformations - Transformations to parse.
   * @returns A string with the transformations.
   *
   * @example
   *
   * ```ts
   * const transformations: Array<ICloudinaryTransformation> = [{
   *    width: 1500,
   *    height: 750
   * },
   * {
   *    quality: "auto",
   *    dpr: 2.0
   * }]
   * ```
   * ```text
   *
   * Parses to:
   * "w_500,h_750/q_auto,dpr_2.0"
   * ```
   */
  public getTransformationsString(
    transformations: Array<ICloudinaryTransformation>
  ): string {
    const validTransformationKeys = Object.keys(this.transformationsConfig);

    const chainedTransformations = transformations.map((t) => {
      const localTransformations: Array<string> = [];

      for (const k of Object.keys(t)) {
        if (!validTransformationKeys.includes(k))
          throw new InvalidArgumentError(
            `No configuration was found for the transformation key "${k}".`
          );

        const qualifier =
          this.transformationsConfig[
            k as keyof typeof this.transformationsConfig
          ].qualifier.value;

        localTransformations.push(
          `${qualifier}_${t[k as keyof ICloudinaryTransformation]}`
        );
      }

      return localTransformations.join(',');
    });

    const retVal =
      chainedTransformations.length > 1
        ? chainedTransformations.join('/')
        : chainedTransformations[0];

    return retVal;
  }

  /**
   * Verifies if a string contains Cloudinary transformation syntax.
   * Useful for telling apart URL segments that contain transformations.
   *
   * @param string - String to check.
   * @returns `true` if the string contains transformation syntax (`<qualifier>_<value>`).
   *
   * @example
   *
   * ```ts
   * stringContainsTransformations("w_500,h_750"); // true
   * stringContainsTransformations("v2448324"); // false
   * ```
   *
   */
  private stringContainsTransformations(string: string): boolean {
    for (const transformationKey of Object.keys(this.transformationsConfig)) {
      const qualifier =
        this.transformationsConfig[
          transformationKey as keyof typeof this.transformationsConfig
        ].qualifier.value;

      if (string.includes(`${qualifier}_`)) return true;
    }

    return false;
  }

  /**
   * Parses a string with transformation syntax to an {@link ICloudinaryTransformation}.
   * @param string - String to parse.
   * @returns An equivalent {@link ICloudinaryTransformation}.
   * @throws An {@link InvalidArgumentError} if the supplied string contains invalid qualifiers.
   *
   * @example
   *
   * ```ts
   * transformationFromString("q_auto,dpr_2.0");
   *
   * // Result:
   * ```
   *
   * ```json
   * {
   *    quality: "auto",
   *    dpr: 2.0
   * }
   * ```
   */
  private transformationFromString(string: string): ICloudinaryTransformation {
    if (!/([a-zA-Z]{1,3})_(.+)/.test(string))
      throw new InvalidArgumentError(
        `The supplied string does not resemble a transformation. Expected <qualifier>_<value>.`
      );

    const [qualifier, value] = string.split('_');

    const matchingTransformationKey = Object.keys(
      this.transformationsConfig
    ).find(
      (k) =>
        this.transformationsConfig[k as keyof typeof this.transformationsConfig]
          .qualifier.value === qualifier
    );

    if (!matchingTransformationKey)
      throw new InvalidArgumentError(
        `Could not find a matching transformation for the qualifier "${qualifier}".`
      );

    return {
      [matchingTransformationKey]: value
    };
  }

  /**
   * Creates a URL from an {@link ICloudinaryAsset} object.
   * @param asset - The Cloudinary asset to transform into a URL.
   * @returns A {@link URL} object that points to the supplied asset in Cloudinary.
   */
  public assetToURL(asset: ICloudinaryAsset): URL {
    const base = this.config.getSetting('baseURL').value;
    const cloudName = this.config.getSetting('cloudName').value;

    const {
      deliveryType,
      assetType,
      transformations,
      version,
      publicID,
      extension
    } = asset;

    const path: Array<string> = [];

    path.push(cloudName);
    path.push(assetType);
    path.push(deliveryType);

    if (transformations)
      path.push(this.getTransformationsString(transformations));

    if (version) path.push(`v${version}`);

    path.push(extension ? `${publicID}.${extension}` : publicID);

    return new URL(path.join('/'), base);
  }

  /**
   * Creates an {@link ICloudinaryAsset} object from a valid Cloudinary URL.
   *
   * @param url - The Cloudinary URL to use.
   * @returns An {@link ICloudinaryAsset} object that represents the asset the supplied URL points to.
   * @throws An {@link InvalidArgumentError} if the supplied URL is invalid.
   *
   *
   * @example
   *
   * ```text
   * A Cloudinary URL looks like this:
   *
   * http(s)://<cdn>/<cloud_name>/<asset_type>/<delivery_type>/<transformations>/<version>/<public_id>.<extension>
   * ```
   *
   * @see [Transformation URL syntax | Cloudinary Docs](https://cloudinary.com/documentation/image_transformations#transformation_url_syntax)
   */
  public urlToAsset(url: string | URL): ICloudinaryAsset {
    let urlString =
      url instanceof URL ? url.toString() : url.replaceAll(' ', '');

    const base = this.config.getSetting('baseURL').value;
    const cloudName = this.config.getSetting('cloudName').value;

    // Step 1: Check if this URL has the same CDN as the config
    if (!urlString.startsWith(base))
      throw new InvalidArgumentError(
        `The supplied URL does not match the configured Cloudinary CDN. Received: ${urlString}`
      );

    // If the CDN matches, remove it from the URL string.
    urlString = urlString.slice(base.length);

    // Split the URL
    const segments = urlString.split('/');

    if (segments[0] === '') segments.shift();

    // Validate cloud name
    if (segments.shift() !== cloudName)
      throw new InvalidArgumentError(
        `The supplied URL's cloud name does not match the configured Cloudinary cloud name. Received: ${urlString}`
      );

    // Get the required properties
    const assetType = segments.shift() as CloudinaryAssetType;
    const deliveryType = segments.shift() as CloudinaryDeliveryType;

    if (!assetType)
      throw new InvalidArgumentError(
        `The supplied URL does not have a valid asset type. Received: ${urlString}`
      );

    if (!deliveryType)
      throw new InvalidArgumentError(
        `The supplied URL does not have a valid delivery type. Received: ${urlString}`
      );

    // Get transformations
    const transformations: Array<ICloudinaryTransformation> = [];

    let idx = 0;

    for (; idx < segments.length; idx += 1) {
      const seg = segments[idx];

      if (this.stringContainsTransformations(seg)) {
        const transformationStrings = seg.split(',');

        transformations.push(
          transformationStrings.reduce<Record<string, unknown>>(
            (prev, curr) => {
              return { ...prev, ...this.transformationFromString(curr) };
            },
            {}
          )
        );
      } else {
        break;
      }
    }

    let version: string | undefined;

    if (/v([0-9])+/.test(segments[idx])) {
      // Segment is version number.
      version = segments[idx].substring(1);
      idx += 1;
    }

    let extension: string | undefined;

    if (segments[segments.length - 1].includes('.')) {
      const pathSegments = segments[segments.length - 1].split('.');
      extension = pathSegments.pop();

      segments[segments.length - 1] = pathSegments.join('.');
    }

    const publicID = segments.slice(idx).join('/');

    return {
      assetType,
      deliveryType,
      transformations,
      version,
      publicID,
      extension
    };
  }

  /**
   * Tries to parse a URL into an {@link ICloudinaryAsset}. Will return `null`
   * if the supplied URL cannot be parsed.
   *
   * @param url - URL to parse.
   * @returns An {@link ICloudinaryAsset} if the URL is a valid Cloudinary asset URL, or `null` otherwise.
   * @throws Any error that occurs during parse and is unrelated to the validity of the supplied URL.
   */
  public tryParse(url: string | URL): ICloudinaryAsset | null {
    const urlString = url instanceof URL ? url.toString() : url;

    try {
      return this.urlToAsset(urlString);
    } catch (err) {
      if (!(err instanceof InvalidArgumentError)) {
        throw err;
      }

      return null;
    }
  }

  /**
   * Custom type guard to check if a given object is an {@link ICloudinaryAsset}.
   * @param object - Object to check.
   * @returns `true` if the supplied object is indeed a valid {@link ICloudinaryAsset}.
   */
  private isCloudinaryAsset(object: unknown): object is ICloudinaryAsset {
    if (!object) return false;

    const { publicID, assetType, deliveryType } = object as ICloudinaryAsset;

    if (!publicID || !assetType || !deliveryType) return false;
    if (Object.values(CloudinaryAssetType).includes(assetType)) return false;
    if (Object.values(CloudinaryDeliveryType).includes(deliveryType))
      return false;

    return true;
  }

  /**
   * Removes all instances of the specified transformations from a given
   * {@link ICloudinaryAsset}.
   *
   * @param from - The {@link ICloudinaryAsset} to remove the transformations from.
   * @param transformationsToRemove - Transformations to remove. These must be valid transformation
   * keys matching the `cloudinary` config.
   *
   * @returns A copy of the supplied {@link ICloudinaryAsset}, but without the specified transformations.
   */
  public removeTransformations(
    from: ICloudinaryAsset,
    ...transformationsToRemove: Array<keyof ICloudinaryTransformation>
  ): ICloudinaryAsset;

  /**
   * Removes all instances of the specified transformations from a given
   * {@link ICloudinaryTransformation}.
   *
   * @param from - The array of {@link ICloudinaryTransformation} to remove the tranformations from.
   * @param transformationsToRemove - Transformations to remove. These must be valid transformation
   * keys matching the `cloudinary` config.
   *
   * @returns A copy of the {@link ICloudinaryTransformation} but without the specified transformations.
   */
  public removeTransformations(
    from: Array<ICloudinaryTransformation>,
    ...transformationsToRemove: Array<keyof ICloudinaryTransformation>
  ): Array<ICloudinaryTransformation>;

  /**
   * Removes all instances of the specified transformations from a given
   * {@link ICloudinaryTransformation}.
   *
   * @param from - The {@link ICloudinaryTransformation} to remove the tranformations from.
   * @param transformationsToRemove - Transformations to remove. These must be valid transformation
   * keys matching the `cloudinary` config.
   *
   * @returns A copy of the {@link ICloudinaryTransformation} but without the specified transformations.
   */
  public removeTransformations(
    from: ICloudinaryTransformation,
    ...transformationsToRemove: Array<keyof ICloudinaryTransformation>
  ): ICloudinaryTransformation;

  /**
   * Removes all instances of the specified transformations from a given
   * {@link ICloudinaryAsset}, or {@link ICloudinaryTransformation}.
   *
   * @param from - Struct to remove the tranformations from.
   * @param transformationsToRemove - Transformations to remove. These must be valid transformation
   * keys matching the `cloudinary` config.
   *
   * @returns A copy of the supplied struct but without the specified transformations.
   */
  public removeTransformations(
    from:
      | ICloudinaryAsset
      | Array<ICloudinaryTransformation>
      | ICloudinaryTransformation,
    ...transformationsToRemove: Array<keyof ICloudinaryTransformation>
  ):
    | ICloudinaryAsset
    | Array<ICloudinaryTransformation>
    | ICloudinaryTransformation {
    if (this.isCloudinaryAsset(from)) {
      const newAsset: ICloudinaryAsset = JSON.parse(JSON.stringify(from));

      if (!newAsset.transformations) return newAsset;

      for (const transformation of newAsset.transformations) {
        for (const transformationKey of transformationsToRemove) {
          delete transformation[transformationKey];
        }
      }

      return newAsset;
    }

    if (from instanceof Array) {
      const transformations = [...from];

      for (const transformation of transformations) {
        for (const transformationKey of transformationsToRemove) {
          delete transformation[transformationKey];
        }
      }

      return transformations;
    }

    const newTransformation: ICloudinaryTransformation = { ...from };

    for (const transformationKey of transformationsToRemove) {
      delete newTransformation[transformationKey];
    }

    return newTransformation;
  }
}

export default new CloudinaryService();
