'use client';

import { FunctionComponent, CSSProperties } from 'react';
import { useRouter } from '@/react/utils/router-utils/useRouter';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import type { Nullable } from '@/type-utils';
import {
  AspectRatioName,
  ContentImageModel
} from '@/services/models/Media/ContentImage';
import { ContentVideoModel } from '@/services/models/Media/ContentVideo';
import {
  Breakpoints,
  Default,
  Breakpoint
} from '../../../../core-ui/Breakpoints';
import { ContentMedia } from '../../..';
import { setAspectRatioOverrides } from '../../../utils/image-utils';
import { getItemOverlay } from '../../../utils/styling-utils';

import { TextBlock } from '../TextBlock';

import S from './styles.base.module.scss';

const DEFAULT_DESKTOP_RATIO: AspectRatioName = 'landscape_ratio8x3';
const DEFAULT_MOBILE_RATIO: AspectRatioName = 'portrait_ratio1x1';
/**
 * ContentBlock variant type.
 */
export type ContentBlockVariant =
  | 'side-by-side'
  | 'overlay'
  | 'row'
  | 'accordion-button'
  | 'default';

/**
 * Get the core styling from the variant string.
 * @param variant - String that represents styling variant.
 * @returns Returns a className from the styling import or an empty string.
 */
const getStylingFromVariant = (
  variant: Nullable<ContentBlockVariant>
): string => {
  switch (variant) {
    case 'accordion-button':
      return S.accordionButton;
    case 'row':
      return S.row;
    case 'side-by-side':
      return S.sideBySide;
    case 'overlay':
      return S.overlay;
    case 'default':
    default:
      return '';
  }
};

export interface IContentBlockProps {
  /** The content item. */
  item: IContentItem;

  /** String representing a class that gets added. */
  variant?: ContentBlockVariant;

  /** Whether the underlying text block should have a link wrapped around the headline. */
  headlineLink?: boolean;

  /** Whether the underlying text block should have a link wrapped around the body. */
  bodyLink?: boolean;

  /** Whether elements of the template should be flipped horizontally. */
  flip?: boolean;
}

/**
 * A ContentBlock provides a generic way of rendering a content item.
 * It includes an image and a text block.
 */
export const ContentBlock: FunctionComponent<IContentBlockProps> = ({
  item,
  variant,
  headlineLink = false,
  bodyLink = false,
  flip = false
}) => {
  const itemModel = ContentItemModel.from(item);
  const variantClass = getStylingFromVariant(variant);
  const { title, text, link, image, images, custom, settings, links } =
    itemModel;
  const { props } = getItemOverlay(settings);
  const isBlockClickable = links?.at(0)?.href && !links.at(0)?.isCTA;
  const blockLink: string | undefined = isBlockClickable
    ? links?.at(0)?.href
    : '';
  const router = useRouter();

  const {
    mainAspectRatio = DEFAULT_DESKTOP_RATIO,
    mobileAspectRatio = DEFAULT_MOBILE_RATIO
  } = custom as Record<string, AspectRatioName>;

  if (itemModel.image && itemModel.images) {
    itemModel.images.forEach((image) => {
      setAspectRatioOverrides(image, custom);
    });
  }

  const { widthContain, heightContain, imageWidth } = custom as Record<
    string,
    number
  >;

  const { backgroundColor } = custom as Record<string, string>;

  /**
   * This is a stopgap solution to handle the case where a content item has both
   * an image and a video.
   * In the future, this should be handled in the Content Factory.
   */
  let media: Array<ContentImageModel | ContentVideoModel> = [];

  if (itemModel.image && itemModel.images) {
    media = itemModel.images;
  }

  if (itemModel.video && itemModel.videos) {
    media = [...media, ...itemModel.videos];
  }

  const classes = `${variantClass} ${flip ? S.flip : ''} ${S.container} ${
    widthContain ? S.center : ''
  } ${isBlockClickable ? S.clickable : ''}`;
  const styles = {
    '--width-contain': widthContain || 100,
    '--height-contain': heightContain || 100,
    '--image-width': imageWidth || '100%',
    backgroundColor,
    ...props
  } as CSSProperties;
  const hasTextContent = !!(title || text || link || links?.length);
  const content = (
    <>
      <Breakpoints>
        <Breakpoint media={['phone']}>
          {media && media.length > 0 && (
            <div className={S.image}>
              <ContentMedia item={media.length > 1 ? media[1] : media[0]} />
            </div>
          )}
        </Breakpoint>
        <Default>
          {media && media.length > 0 && (
            <div className={S.image}>
              <ContentMedia item={media[0]} />
            </div>
          )}
        </Default>
      </Breakpoints>
      {hasTextContent && (
        <div className={S.text}>
          <TextBlock
            item={itemModel.item ?? itemModel}
            headlineLink={headlineLink}
            bodyLink={bodyLink}
            variant={variant}
          />
        </div>
      )}
    </>
  );

  /**
   * Make our content block container clickable.
   *
   * @param event - The click event.
   */
  function handleClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
    if (blockLink) {
      router.push(blockLink);
    }
  }

  /**
   * Make our content block linkage accessible via enter keydown.
   *
   * @param event - The keydown event.
   */
  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter' && blockLink) {
      router.push(blockLink);
    }
  }

  return (
    <>
      {isBlockClickable ? (
        <div
          className={classes}
          style={styles}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          role="button"
        >
          {content}
        </div>
      ) : (
        <div className={classes} style={styles}>
          {content}
        </div>
      )}
    </>
  );
};
