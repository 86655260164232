import { IStyle } from './IStyle';

/**
 * Represents the X position of the teaser in the studio.
 */
export enum ContentPositionX {
  Right = 'right',
  Center = 'center',
  Left = 'left'
}

/**
 * Represents the Y position of the teaser in the studio.
 */
export enum ContentPositionY {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom'
}

/**
 * This recreates ICMTeaserOverlayStyles, in case of change on the ContentService side.
 */
export interface ISettings {
  /** Style name to be applied to title, text, and cta. */
  style: IStyle;

  /** Apply the style above. */
  enabled: boolean;

  /** Position the teaser content horizontally. */
  positionX: ContentPositionX;

  /** Position the teaser content vertically. */
  positionY: ContentPositionY;

  /** Decide how much width the item should take up within the content. */
  width: number;

  /** Content typename. */
  __typename: string;
}
