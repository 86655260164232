import { CSSProperties, FunctionComponent } from 'react';

import {
  IContentItem,
  ContentItemModel,
  ContentPositionX
} from '@/services/models/Content';
import { ContentImageModel } from '@/services/models/Media/ContentImage';
import { v4 as uuidv4 } from 'uuid';
import INestableContent from '../../../traits/INestableContent';
import { ContentImage, getCustomStyling, getItemOverlay } from '../../..';
import S from './styles.module.scss';

export interface IOverlayContentProps extends INestableContent {
  /** The collection item, will contain any number of teasers. */
  item: IContentItem;
}

/**
 * Overlay Content can place any number of teasers in a collection over a background
 * image, which is the first image in the collection.
 */
export const OverlayContent: FunctionComponent<IOverlayContentProps> = ({
  item,
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);
  const { items = [], settings, custom } = itemModel;
  // Image teaser setup
  const imageItem = items[0] ?? null;
  let imageModel;
  let imageTeaser;

  if (imageItem != null) {
    imageTeaser = ContentItemModel.from(imageItem);
    const { image } = imageTeaser;
    imageModel = image ? ContentImageModel.from(image) : null;
  }
  if (imageModel == null) {
    console.warn(
      'OverlayContent: No image found in collection item',
      itemModel
    );
  }
  /**
   * Make style available for nested content.
   * For now, the text classes will be used for the nested content.
   */
  const overlay = getItemOverlay(settings);
  const styling = getCustomStyling(custom);

  let positionXClass: string;
  switch (overlay.positionX) {
    case ContentPositionX.Left: {
      positionXClass = S.left;
      break;
    }
    case ContentPositionX.Right: {
      positionXClass = S.right;
      break;
    }
    default: {
      positionXClass = S.center;
      break;
    }
  }

  const { widthContain } = custom as Record<string, number>;

  return (
    <div
      className={S.wrapper}
      style={
        {
          '--max-width': widthContain
        } as CSSProperties
      }
    >
      <div className={S.teasers}>
        {imageModel && <ContentImage image={imageModel} isBackgroundImage />}
        <div
          className={`${S.teaser} ${overlay.text} ${positionXClass}`}
          style={{ ...overlay.props }}
        >
          {items.slice(1).map((item, i) => {
            const viewType = item.viewType || 'collection';
            return (
              <div key={uuidv4()} style={styling}>
                {contentResolver({ ...item, viewType })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
