import { FunctionComponent } from 'react';

import { ContentImageModel } from '@/services/models/Media/ContentImage';
import { IContentItem, ContentItemModel } from '@/services/models/Content';

import { ContentBlock } from '../../core/ContentBlock';
import {
  getCustomStyling,
  getContainerOptions
} from '../../../utils/styling-utils';

import S from './styles.module.scss';

export interface ISideBySideProps {
  /** The content item. */
  item: IContentItem;
}

/** Turns a regular ContentBlock on its side.  */
export const SideBySide: FunctionComponent<ISideBySideProps> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const imageModel = itemModel.image
    ? ContentImageModel.from(itemModel.image)
    : null;
  const { custom } = itemModel;

  if (imageModel) {
    imageModel.aspectRatio = 'landscape_ratio16x9';
    imageModel.mobileAspectRatio = 'landscape_ratio4x3';
  }

  const styling = getCustomStyling(custom);
  const options = getContainerOptions(custom);
  return (
    <div className={S.container} style={styling}>
      <ContentBlock
        item={itemModel}
        variant="side-by-side"
        flip={options.flip}
      />
    </div>
  );
};
