import { createContext } from 'react';

/**
 * This type describes what the AnchorContext.Provider will provide
 * the {@link AnchorContextProvider} component to manage the active anchor element.
 * This context is intended to be used only by the {@link AnchorContextProvider} and
 * the {@link useAnchor} hook, and should not be utilized outside of these components.
 */
type AnchorContext = {
  /**
   * The anchor element that is currently active within the context.
   */
  anchorEl: HTMLElement | null;

  /**
   * Callback used to set the anchor element in the context (which will trigger
   * the scroll). This is utilized by the {@link useAnchor} hook.
   * @param el
   */
  setAnchorEl: (el: HTMLElement | null) => void;

  /**
   * Scope of the anchor element -- whether context is provided. Set in provider
   * to true (as a default) and set to false in the useAnchor hook if the context
   * is not provided.
   */
  scope: boolean;
};

/**
 * Initial context value for the anchor. Intended to be overridden by the
 * {@link AnchorContextProvider} component and the {@link useAnchor} hook.
 */
export const AnchorContext = createContext<AnchorContext>({
  anchorEl: null,
  setAnchorEl: (el: HTMLElement | null) => null,
  scope: false
});
