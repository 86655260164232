'use client';

import { FunctionComponent } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import IStylable from '../../traits/IStylable';

import S from './styles.module.scss';

export interface ISkeletonLoaderProps extends IStylable {
  /**
   * If the Skeleton Loader should be of a lighter or darker
   * color than its background.
   *
   * Use `dark` with light-colored backgrounds, and `light`
   * with dark-colored backgrounds.
   *
   * Defaults to `dark`.
   */
  mode?: 'dark' | 'light';
}

/**
 * Acts as a placeholder for a UI component.
 *
 * [Learn more about skeleton loading screens](https://betterprogramming.pub/the-what-why-and-how-of-using-a-skeleton-loading-screen-e68809d7f702).
 */
const SkeletonLoader: FunctionComponent<ISkeletonLoaderProps> = ({
  mode = 'dark',
  className,
  id,
  style
}) => {
  return (
    <div className={classes(S.skeletonLoader, className)} id={id} style={style}>
      <div
        className={classes(S.innerDecoration, {
          [S.light]: mode === 'light'
        })}
      />
    </div>
  );
};

export default SkeletonLoader;
