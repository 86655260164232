'use client';

import parse from 'html-react-parser';
import { FunctionComponent } from 'react';
import {
  htmlEntitiesToUnicodeEscapes,
  sanitizeMarkup,
  unescapeHTMLEntities
} from '../../../utils/sanitization-utils';

export interface IFragmentParserProps {
  /** String fragment, often from content, possibly containing HTML. */
  string: string;

  /**
   * Render the fragment as a string. This will unescape html entities, but will not render
   * the fragment as HTML, all tags will remain unprocessed.
   */
  renderAsString?: boolean;
}

/**
 * TODO: This is basically a stub that solves an immediate problem.
 * This should be monitored with a specific eye towards what the UI
 * devs are doing in CoreMedia. In the immediate future, this serves
 * to render the "teaser" blocks, which always contain HTML.
 */

/**
 * Used to display markup and text from outside sources. It accepts text
 * or raw HTML, sanitizes it, and inserts it into the DOM.
 *
 * **Note:** _Do not_ inject unvalidated user input into the DOM under
 * any circumstances.
 */
export const FragmentParser: FunctionComponent<IFragmentParserProps> = ({
  string = '',
  renderAsString = false
}) => {
  return (
    <>
      {typeof string === 'string' && renderAsString
        ? htmlEntitiesToUnicodeEscapes(unescapeHTMLEntities(string))
        : parse(sanitizeMarkup(string))}
    </>
  );
};

export default FragmentParser;
