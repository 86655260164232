import { FunctionComponent } from 'react';
import type { IContentItem } from '@/services/models/Content';
import { CMSContentTypes, ContentItemModel } from '@/services/models/Content';
import {
  ContentVideoModel,
  IContentVideo
} from '@/services/models/Media/ContentVideo';
import { IContentImage } from '@/services/models/Media/ContentImage';
import LoggerService from '@/services/isomorphic/LoggerService';
import { MediaPlayer } from '../../../../core-ui/Video/MediaPlayer';
import { ContentImage } from '../ContentImage';
import { ContentVideo } from '../ContentVideo';

export interface IContentMediaProps {
  /** A single ContentItem that represents one piece of content. */
  item: IContentItem | IContentVideo | IContentImage;

  /** Class to assign to the media element. */
  className?: string;
}

/**
 * Stateless component that delegates the rendering of a ContentItem, if an image or video, to the
 * appropriate default component when no parent layout variant handles its rendering.
 * @param item - A single ContentItem that represents one piece of content. It can also take a video or image.
 */
export const ContentMedia: FunctionComponent<IContentMediaProps> = ({
  item,
  className: wrapperClass = ''
}) => {
  if (!item) {
    LoggerService.error('ContentMedia: No item provided.');
    return null;
  }
  const isImage = (
    item: IContentItem | IContentVideo | IContentImage
  ): item is IContentImage => {
    return item?.type === CMSContentTypes.Image;
  };
  const isVideo = (
    item: IContentItem | IContentVideo | IContentImage
  ): item is IContentVideo => {
    return item?.type === CMSContentTypes.Video;
  };

  if (isImage(item)) {
    return <ContentImage image={item} className={wrapperClass} />;
  }
  if (isVideo(item)) {
    const itemModel = ContentItemModel.from(item as IContentItem);
    let video;
    if (itemModel.video) {
      video = itemModel.video;
    } else {
      video = ContentVideoModel.from(item);
    }
    return (
      <MediaPlayer video={video}>
        <ContentVideo video={video} className={wrapperClass} />
      </MediaPlayer>
    );
  }
  /** Fallback to the default ContentItem component, and get itemModel. Then, check for type. */
  const itemModel = ContentItemModel.from(item);
  return (
    <>
      {(itemModel.type === CMSContentTypes.Image ||
        itemModel.type === CMSContentTypes.Teaser) &&
        itemModel.image && (
          <ContentImage image={itemModel.image} className={wrapperClass} />
        )}
      {itemModel.type === CMSContentTypes.Video && itemModel.video && (
        <MediaPlayer video={itemModel.video}>
          <ContentVideo video={itemModel.video} className={wrapperClass} />
        </MediaPlayer>
      )}
    </>
  );
};
