import { FunctionComponent } from 'react';

import { ContentImageModel } from '@/services/models/Media/ContentImage';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { getCustomStyling } from '../../../utils/styling-utils';
import { ContentImage } from '../../core/ContentImage';

import S from './styles.module.scss';

export interface IZoomTileProps {
  /** The content item. */
  item: IContentItem;
}

/** A tile like piece of content has an image that zooms in slightly on hover.  */
export const ZoomTile: FunctionComponent<IZoomTileProps> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const imageModel = itemModel.image
    ? ContentImageModel.from(itemModel.image)
    : null;
  const { custom } = itemModel;

  if (imageModel) {
    imageModel.aspectRatio = 'landscape_ratio16x9';
  }

  const styling = getCustomStyling(custom);
  return (
    <div className={S.container} style={styling}>
      <div className={S.image}>
        {imageModel && <ContentImage animation={false} image={imageModel} />}
      </div>
      <h4 className={S.headline}>{item.title}</h4>
    </div>
  );
};
