/**
 * The allowable kinds of text tracks.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/track#attr-kind
 */
enum TrackKinds {
  /** The track is a subtitle track. */
  Subtitles = 'subtitles',
  /** The track is a caption track. */
  Captions = 'captions',
  /** The track is a description track. */
  Descriptions = 'descriptions',
  /** The track is a chapter track. */
  Chapters = 'chapters',
  /** The track is a metadata track. */
  Metadata = 'metadata'
}

export default TrackKinds;
