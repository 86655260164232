'use client';

import { FunctionComponent, useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import S from './styles.module.scss';

/**
 * Props for the UserInfo component.
 */
interface IUserInfoProps {
  /** Whether the user info is in view. */
  isInView: boolean;
}

/**
 * A component that renders user information.
 * @param props - The props for the component.
 * @returns The UserInfo component.
 */
const UserInfo: FunctionComponent<IUserInfoProps> = ({ isInView }) => {
  // Current implementation will show coordinates for corp headquarters
  const CORP_COORDS: string = '34°25\'47.1"N 119°51\'38.6"W';

  const [time, setTime] = useState('00:00');
  const [date, setDate] = useState('000000');

  useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(() => {
      const now = DateTime.now();
      setTime(now.toLocaleString(DateTime.TIME_24_SIMPLE));
      setDate(now.toFormat('MMddyy'));
    }, 1000);
  }, []);

  return (
    <div className={S.container}>
      {CORP_COORDS} / {time} / {date}
    </div>
  );
};

export default UserInfo;
