/**
 * Aspect ratio strings defined in CM Studio. These are used
 * to assemble images.
 */
type AspectRatioName =
  | 'landscape_ratio16x9'
  | 'landscape_ratio17x4'
  | 'landscape_ratio2x1'
  | 'landscape_ratio4x3'
  | 'landscape_ratio7x1'
  | 'landscape_ratio3x1'
  | 'landscape_ratio8x3'
  | 'landscape_ratio3x1'
  | 'landscape_ratio24x1'
  | 'portrait_ratio3x5'
  | 'portrait_ratio3x4'
  | 'portrait_ratio1x1';

/** This is used to verify the type. It should always match AspectRatioName. */
export const AspectRatioNameList = [
  'landscape_ratio16x9',
  'landscape_ratio17x4',
  'landscape_ratio2x1',
  'landscape_ratio4x3',
  'landscape_ratio7x1',
  'landscape_ratio3x1',
  'landscape_ratio8x3',
  'landscape_ratio24x1',
  'portrait_ratio3x5',
  'portrait_ratio3x4',
  'portrait_ratio1x1'
] as const;

export default AspectRatioName;
