import { DTO } from '@/type-utils';
import Model from '../../Model';
import { toStatelessImmutable } from '@/utils/object-utils';
import { ITrack, TrackKinds } from '.';

/**
 * Represents a track to be added to a video.
 */
export default class TrackModel<
  T extends DTO<ITrack> = DTO<ITrack>
> extends Model<T> {
  /** The src of the track. */
  public readonly src!: string;

  /** The srcLang of the track. */
  public srclang!: string;

  /** A string that determines which kind of track to display. */
  public readonly kind: TrackKinds;

  /** A string that determines the label. */
  public readonly label!: string;

  /**
   * Builds a model from any Track representation.
   * @param track - A Track representation.
   */
  public constructor(track: T) {
    super(track);
    this.kind = track.kind in TrackKinds ? track.kind : TrackKinds.Captions;
    this.label = track.label || '';
    if (track.src) {
      if (!this.isVTT(track.src)) {
        console.warn('`Track` must be a vtt file.');
      }
      this.src = track.src;
    }
    if (track.srclang) {
      this.srclang = track.srclang;
    }
  }

  /**
   * Checks if the url is a vtt file.
   * @param url - The url to check.
   * @private
   * @returns True if the url is a vtt file.
   */
  private isVTT(url: string): boolean {
    return url.endsWith('.vtt');
  }

  /**
   * Creates a DTO representation of this Model.
   * @returns A DTO representation of this Track Model.
   */
  public toDTO(): T {
    const { src, srclang, kind, label } = this;
    return toStatelessImmutable<Partial<DTO<ITrack>>>({
      src,
      srclang,
      kind,
      label
    }) as T;
  }
}
