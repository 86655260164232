import { FunctionComponent } from 'react';

import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { ContentBlock } from '../../core/ContentBlock';
import { getCustomStyling } from '../../../utils/styling-utils';

import S from './styles.module.scss';

export interface IFullWidthProps {
  /** The content item. */
  item: IContentItem;
}

/** A full width banner with one image that crops on mobile.  */
export const FullWidth: FunctionComponent<IFullWidthProps> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const { custom } = itemModel;
  if (itemModel.image) {
    itemModel.image.aspectRatio = 'landscape_ratio8x3';
    itemModel.image.mobileAspectRatio = 'landscape_ratio4x3';
  }

  const styling = getCustomStyling(custom);
  return (
    <div className={S.container} style={styling}>
      <ContentBlock item={itemModel} />
    </div>
  );
};
