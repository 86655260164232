'use client';

import { useIsomorphicLayoutEffect } from '@/react/hooks/useIsomorphicLayoutEffect';
import {
  motion,
  useReducedMotion,
  useScroll,
  useSpring,
  useTransform
} from 'framer-motion';
import debounce from 'lodash/debounce';
import { ReactNode, useRef, useState } from 'react';

/** Types for our Parallax component. */
interface IParallaxProps {
  /** The children to render. */
  children: ReactNode;
  /** The offset to use for the parallax effect. */
  offset?: number;
  /**
   * Style information to be applied to the parallax container. Useful
   * for setting the height of the container, for situations where the
   * child content is absolute positioned. Also useful for setting the
   * z-index of the container, to ensure it appears on the desired layer.
   */
  style?: React.CSSProperties;
}

/**
 * A component that applies a parallax effect to its children.
 */
export const Parallax = ({
  children,
  offset = 50,
  style
}: IParallaxProps): JSX.Element => {
  const prefersReducedMotion = useReducedMotion();
  const [elementTop, setElementTop] = useState(0);
  const [elementHeight, setElementHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  const { scrollY } = useScroll();

  // Start the animation when the element is in view
  const initial = elementTop - clientHeight;
  // End the animation when the element is out of view, which is
  // either the height of view, or the height of the element,
  // whichever is greater.
  const final =
    elementTop + Math.max(clientHeight, elementHeight) + Math.abs(offset);

  const yRange = useTransform(scrollY, [initial, final], [offset, -offset]);
  const y = useSpring(yRange, { stiffness: 400, damping: 90 });

  useIsomorphicLayoutEffect(() => {
    const element = ref.current;
    const resizeHandler = (): void => {
      setElementTop(
        element
          ? element.getBoundingClientRect().top + window.scrollY
          : window.scrollY
      );
      setElementHeight(
        element ? element.getBoundingClientRect().height : window.innerHeight
      );
      setClientHeight(window.innerHeight);
      setDocumentHeight(document.documentElement.scrollHeight);
    };
    resizeHandler();

    const onResize = debounce(() => {
      resizeHandler();
    }, 500);

    // This will track any width or height changes in the document.
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, [ref]);

  return (
    <motion.div ref={ref} style={{ y, ...style }}>
      {children}
    </motion.div>
  );
};
