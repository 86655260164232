import { DTO, Nullable } from '@/type-utils';
import { ResourceNotFoundError } from '@/utils/errors';
import { toStatelessImmutable } from '@/utils/object-utils';
import { action, computed, makeObservable, observable } from 'mobx';
import LoggerService from '../../../isomorphic/LoggerService';
import { CMSContentTypes, IPlayerSettings } from '../../Content';
import {
  AspectRatioName,
  ContentImageModel,
  IContentImage
} from '../ContentImage';
import { ITrack, ITrackCaption, TrackKinds, TrackModel } from '../Track';
import Model from '../../Model';
import CropTemplates from '../../../serverless/integrations/CoremediaService/__downloaded__/image-crops.json';
import { IContentVideo } from '.';

const DEFAULT_DESKTOP_RATIO: AspectRatioName = 'landscape_ratio4x3';
const DEFAULT_MOBILE_RATIO: AspectRatioName = 'portrait_ratio1x1';

/** Size Object gained downloaded from content service. */
export interface ISize {
  /** An image width. */
  width: number;

  /** An image height. */
  height: number;
}

/** The image crop template defining each image crop type. */
export interface IImageCropTemplate {
  /** Name of the template. */
  name: string;

  /** Aspect ratio with a height and width property. */
  aspectRatio: ISize;

  /** All sizes available in content service. */
  sizes: Array<ISize>;
}

/**
 * This object is used to get a src. Depending on the options supplied
 * it will rely on the current model state or override specific values.
 */
export interface ISrcArguments {
  /** Width of the element currently. */
  elementWidth?: number;
  /** Use this aspect ratio rather than the current state. */
  aspectRatio?: AspectRatioName;
  /** Is the screen currently mobile. */
  isMobile?: boolean;
}

/**
 * This image model is used to construct and manipulate content images.
 */
export default class ContentVideoModel
  extends Model<DTO<IContentVideo>>
  implements IContentVideo
{
  @observable private _src: Nullable<string> = null;
  @observable private _aspectRatio: AspectRatioName;
  @observable private _mobileAspectRatio: Nullable<AspectRatioName>;
  @observable private _poster: Nullable<string> = null;
  @observable private _captions?: ITrackCaption;
  @observable private _settings: IPlayerSettings = {
    autoplay: false,
    muted: true,
    controls: false,
    loop: false
  };

  /** @inheritdoc */
  public readonly uuid!: string;

  /** @inheritdoc */
  public readonly autoplay!: boolean;

  /** @inheritdoc */
  public readonly muted!: boolean;

  /** @inheritdoc */
  public readonly loop!: boolean;

  /** @inheritdoc */
  public readonly hideControls!: boolean;

  /** @inheritdoc */
  public readonly pictures!: Array<IContentImage>;

  /** @inheritdoc */
  public readonly tracks!: Array<ITrack>;

  /** @inheritdoc */
  public readonly type: CMSContentTypes.Video;

  /** @inheritDoc */
  public readonly custom?: Record<string, unknown>;

  /**
   * Alternate src data url, typically used with
   * {@link CloudinaryService }.
   */
  public readonly dataUrl!: string;

  /** @inheritdoc */
  @computed public get src(): string {
    if (!this._src) {
      throw new Error('`ContentVideoModel` was provided no `src` value.');
    }
    return this._src;
  }

  /** @inheritdoc */
  @computed public get poster(): string {
    if (!this._poster) {
      LoggerService.warn('`ContentVideoModel` was provided no `poster` image.');
      return '';
    }
    return this._poster;
  }

  /** @inheritdoc */
  @computed public get aspectRatio(): AspectRatioName {
    return this._aspectRatio;
  }

  /** @inheritdoc */
  public set aspectRatio(ratio: AspectRatioName) {
    this._aspectRatio = ratio;
  }

  /** @inheritdoc */
  @computed public get mobileAspectRatio(): Nullable<AspectRatioName> {
    return this._mobileAspectRatio;
  }

  /** @inheritdoc */
  public set mobileAspectRatio(ratio: Nullable<AspectRatioName>) {
    this._mobileAspectRatio = ratio;
  }

  /** @inheritdoc */
  @observable public activeAspectRatio: Nullable<AspectRatioName>;

  /**
   * Gets the current template by aspectRatio.
   * @returns The Video crop template object from content service.
   */
  private get template(): IImageCropTemplate {
    return this.getTemplate(
      this.activeAspectRatio ? this.activeAspectRatio : this._aspectRatio
    );
  }

  /** Title for the video */
  public readonly title?: Nullable<string>;

  /** Alt text for video. */
  public readonly alt: Nullable<string>;

  /**
   * Gets the height from the largest size available at this aspect ratio.
   * @returns Number to pass to NEXT Video height.
   */
  @computed public get height(): number {
    return this.template.sizes[0].height;
  }

  /**
   * Gets the template width as the largest size available at this aspect ratio.
   * @returns Number to pass to NEXT Video width.
   */
  @computed public get width(): number {
    return this.template.sizes[0].width;
  }

  /**
   * Gets settings for generic Video model.
   * @returns The player settings.
   */
  @computed public get settings(): IPlayerSettings {
    return this._settings;
  }

  /**
   * Sets the player settings.
   * Will check if the settings are valid.
   * @param settings - The player settings.
   * @throws Will throw an error if some settings are invalid.
   * @example setSettings({ autoplay: true, muted: false }); - Will throw an error.
   */
  public setSettings(): void {
    try {
      if (this.autoplay && !this.muted) {
        throw new Error(
          'Cannot autoplay a video if it is not muted. Please set muted to true.'
        );
      }
    } catch (error) {
      console.error(error);
    }
    this._settings = {
      autoplay: this.autoplay,
      muted: this.muted,
      controls: this.hideControls,
      loop: this.loop
    };
  }

  /**
   * Builds a model from any Content Video representation.
   * @param video - An video DTO used to instantiate model.
   */
  public constructor(video: DTO<IContentVideo>) {
    super(video);

    if ('uuid' in video) this.uuid = video.uuid;
    if ('title' in video) this.title = video.title;
    if ('alt' in video) this.alt = video.alt;
    if ('custom' in video) this.custom = video.custom;

    // Settings for the player
    if ('autoplay' in video) this.autoplay = video.autoplay ?? false;
    if ('muted' in video) this.muted = video.muted ?? false;
    if ('loop' in video) this.loop = video.loop ?? false;
    if ('hideControls' in video)
      this.hideControls = video.hideControls ?? false;

    if ('dataUrl' in video) this.dataUrl = video.dataUrl;
    if ('pictures' in video && video.pictures) {
      const [poster] = video.pictures;
      this.pictures = [new ContentImageModel(poster)];
    }

    /**
     * Because a video is a teaser in CM, it needs to pull aspect ratio from
     * its own custom field.
     */
    const {
      mainAspectRatio = DEFAULT_DESKTOP_RATIO,
      mobileAspectRatio = DEFAULT_MOBILE_RATIO
    } =
      video.custom ??
      ({
        mainAspectRatio: DEFAULT_DESKTOP_RATIO,
        mobileAspectRatio: DEFAULT_MOBILE_RATIO
      } as Record<string, AspectRatioName>);

    this._aspectRatio = mainAspectRatio as AspectRatioName;
    if (video.mobileAspectRatio)
      this._mobileAspectRatio = mobileAspectRatio as AspectRatioName;
    if ('tracks' in video && video.tracks) {
      this.tracks = video.tracks.map((track) => new TrackModel(track));
    }
    this.type = CMSContentTypes.Video;
    this.setSrc({ elementWidth: 400, isMobile: true });
    this.setPoster({ elementWidth: 400, isMobile: true });
    this.setSettings();
    this.setCaptions();
    makeObservable(this);
  }

  /**
   * Gets the aspect ratio template from the generated content.
   * @param ratio - The aspect ratio string.
   * @returns The aspect ratio template object generated for content.
   * @throws A {@link ResourceNotFoundError} if no template could be found.
   */
  private getTemplate(ratio: AspectRatioName): IImageCropTemplate {
    const template = (CropTemplates as Array<IImageCropTemplate>).find(
      (template) => template.name === ratio
    );

    if (!template) {
      throw new ResourceNotFoundError(
        `No template was found for \`ContentVideoModel\`.`
      );
    }

    return template;
  }

  /**
   * Sets the current aspect ratio given screen width.
   * @param isMobile - Is the current window under the mobile breakpoint.
   */
  private setActiveAspectRatio(isMobile: boolean): void {
    if (isMobile) {
      this.activeAspectRatio = this._mobileAspectRatio
        ? this._mobileAspectRatio
        : this._aspectRatio;
    } else {
      this.activeAspectRatio = this._aspectRatio;
    }
  }

  /**
   * Gets the updated src value given a certain element width,
   * an aspect ratio, and whether the window has a mobile width or not.
   * It loops through the available image sizes and finds the first
   * one, looping up, that is larger than the image is currently.
   * @param elementWidth - Current width of element.
   * @param isMobile - Is this under the mobile breakpoint.
   * @param aspectRatio - The aspect ratio override.
   */
  @action public setSrc({
    elementWidth,
    aspectRatio,
    isMobile = false
  }: ISrcArguments): void {
    if (aspectRatio) {
      this.activeAspectRatio = aspectRatio;
    } else {
      this.setActiveAspectRatio(isMobile);
    }

    let src: Nullable<string> = null;
    if (this.dataUrl?.length > 0) {
      src = this.dataUrl;
    }
    this._src = src;
  }

  /**
   * This ensures the poster image model matches the properties of the video.
   * @param elementWidth - Current width of element.
   * @param isMobile - Is this under the mobile breakpoint.
   * @param aspectRatio - The aspect ratio override.
   */
  @action public setPoster({
    elementWidth,
    aspectRatio,
    isMobile = false
  }: ISrcArguments): void {
    if (aspectRatio) {
      this.activeAspectRatio = aspectRatio;
    } else {
      this.setActiveAspectRatio(isMobile);
    }

    if (this.pictures?.length > 0) {
      const [poster] = this.pictures;
      const _poster = ContentImageModel.from(poster);
      _poster.setSrc({
        elementWidth,
        aspectRatio: this._aspectRatio ?? undefined,
        isMobile
      });
      this._poster = _poster.src;
    }
  }

  /**
   * Sets captions for the video. A captions track must be of the kind 'subtitles' or 'captions'.
   */
  @action public setCaptions(): void {
    if (this.tracks?.length > 0) {
      const [captions] = this.tracks.filter(
        (track) =>
          track.kind === TrackKinds.Subtitles ||
          track.kind === TrackKinds.Captions
      );

      this._captions = TrackModel.from(captions).toDTO() as ITrackCaption;
    }
  }

  /** @inheritDoc */
  public toDTO(): DTO<IContentVideo> {
    const {
      alt,
      aspectRatio,
      mobileAspectRatio,
      uuid,
      title,
      autoplay,
      loop,
      muted,
      dataUrl,
      hideControls,
      pictures,
      tracks,
      custom,
      _captions: captions
    } = this;
    return toStatelessImmutable<Partial<DTO<IContentVideo>>>({
      alt,
      aspectRatio,
      mobileAspectRatio,
      uuid,
      title,
      autoplay,
      loop,
      muted,
      dataUrl,
      hideControls,
      pictures,
      tracks,
      captions,
      custom
    }) as DTO<IContentVideo>;
  }
}
