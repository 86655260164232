import { FunctionComponent } from 'react';

import { ContentImageModel } from '@/services/models/Media/ContentImage';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { ContentImage } from '../../core/ContentImage';
import { ZoomTile } from '../ZoomTile';
import { CubeBottom } from '../CubeBottom';
import {
  getCustomStyling,
  getContainerOptions
} from '../../../utils/styling-utils';

import S from './styles.module.scss';

export interface ICubeBlockProps {
  /** The content item. */
  item: IContentItem;
}

/** CubeBlock component with a main image two tiles and a sub image.  */
export const CubeBlock: FunctionComponent<ICubeBlockProps> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const mainImage = itemModel.images?.[0]
    ? ContentImageModel.from(itemModel.images[0])
    : null;
  const { custom } = itemModel;

  if (mainImage) {
    mainImage.aspectRatio = 'portrait_ratio1x1';
  }

  const styling = getCustomStyling(custom);
  const options = getContainerOptions(custom);
  return (
    <div className={S.container} style={styling}>
      <div className={S.topSection}>
        <div className={S.tileBlock}>
          {itemModel.items?.[0] && <ZoomTile item={itemModel.items[0]} />}
          {itemModel.items?.[1] && <ZoomTile item={itemModel.items[1]} />}
        </div>
        {mainImage && (
          <div className={S.imageBlock}>
            <ContentImage image={mainImage} />
          </div>
        )}
      </div>
      {itemModel.items?.[2] && <CubeBottom item={itemModel.items?.[2]} />}
    </div>
  );
};
