'use client';

import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { observer } from 'mobx-react-lite';
import { CSSTransition } from 'react-transition-group';
import IStylable from '../../traits/IStylable';
import { Curtain } from '../Curtain';
import useConditionallyDisableScrolling from '../hooks/useConditionallyDisableScrolling';

import S from './styles.base.module.scss';

export interface IModalProps extends IStylable {
  /** Called when the close button is clicked. */
  onClose: () => void;
  /** Called when the close animation is finished. */
  onCloseFinished?: () => void;
  /** Whether the component is currently open. */
  isOpen: boolean;
  /** The contents of the modal. */
  children: ReactNode;
}

/**
 * Modal component provides a solid foundation for creating dialogs, popovers,
 * lightboxes or anything else.
 *
 * @example
 *
 * ```tsx
 * <Modal isOpen={opened} onClose={closeHandler}>
 *   <ModalHeader>
 *     <h2>Modal Title</h2>
 *   </ModalHeader>
 *   <Card>
 *     <p>Modal content</p>
 *   </Card>
 * </Modal>
 * ```
 */
export const Modal = observer(function Modal({
  className,
  style,
  isOpen,
  onClose,
  children,
  onCloseFinished
}: IModalProps) {
  useConditionallyDisableScrolling(isOpen);

  const nodeRef = useRef(null);
  // Should be `true` when rendering on the client side. Set in the `useEffect` hook below.
  const [isMountable, setIsMountable] = useState(false);

  // Should be `true` when the modal is "open" and the component is also mountable.
  const [shouldShow, setShouldShow] = useState(isMountable && isOpen);

  // When `useEffect` is executed on the client side, the modal will be considered
  // to be renderable. This is to prevent the modal from being rendered on the server.
  useEffect(() => {
    if (!isMountable) setIsMountable(true);
    setShouldShow(isOpen && isMountable);
  }, [isOpen, isMountable]);

  return (
    // Only render the React portal when the component is mountable on the client side.
    // This is to prevent the modal from being rendered on the server. This is to
    // prevent the `document` from being accessed on the server where it will not exist.
    (isMountable &&
      ReactDOM.createPortal(
        <>
          {/**
           * The `CSSTransition` component manages the state of the animated transitions via CSS classes.
           * @see http://reactcommunity.org/react-transition-group/css-transition
           */}
          <CSSTransition
            nodeRef={nodeRef}
            appear={shouldShow} // Transition on initial render.
            in={shouldShow}
            timeout={350}
            mountOnEnter
            onExited={onCloseFinished}
            classNames={{
              // States for when the modal is mounted to the DOM.
              appear: S['modal-enter'],
              appearActive: S['modal-enter-active'],
              appearDone: S['modal-enter-done'],
              // States for when the modal being shown
              enter: S['modal-enter'],
              enterActive: S['modal-enter-active'],
              enterDone: S['modal-enter-done'],
              // States for when the modal is being hidden and about to be removed from the DOM.
              exit: S['modal-exit'],
              exitActive: S['modal-exit-active'],
              exitDone: S['modal-exit-done']
            }}
          >
            {/**
             * The "modal" div represents the wrapper for the entire modal dialog box.
             * It's purpose is to contain the CSS transition state classes as passed by the
             * `CSSTransition` parent component.
             */}
            <div ref={nodeRef} className={S['modal-enter']}>
              {/**
               * The "container" div represents the wrapper for the entire viewport which
               * houses the modal dialog box.
               */}
              <div
                className={classNames(S.container)}
                role="dialog"
                aria-modal="true"
              >
                {/**
                 * The `Curtain` component represents the backdrop which covers and
                 * "grays out" the entire viewport.
                 */}
                <Curtain onClose={onClose} />
                {/** The "content" div represents the modal dialog box itself. */}
                <div
                  className={classes(className, S.content)}
                  style={style}
                  tabIndex={-1}
                >
                  {children}
                </div>
              </div>
            </div>
          </CSSTransition>
        </>,
        document.body
      )) ||
    null
  );
});
