/**
 * Escapes any characters in the provided string that would have special meaning
 * in a regular expression. This is useful when creating a regular expression
 * programmatically.
 * @param str - A string which may contain characters with special meaning in a
 * regular expression.
 * @returns The provided string with all characters that have special meaning
 * in a regular expression escaped.
 * @example ```ts
 * const regex = new RegExp(escapeRegEx('abc.def')); // /abc\.def/
 * ```
 */
export const escapeRegEx = (str: string): string => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};
