import { SliderDirection } from '../../ISliderOptions';
import { IconTypes } from '../../../core-ui/Icon';
import { ArrowDirection } from './ISliderNavigationOptions';

/**
 * Just a shortcut for icons for the navigation.
 */
export const ArrowVariants = {
  angle: {
    up: IconTypes.AngleUp,
    right: IconTypes.AngleRight,
    down: IconTypes.AngleDown,
    left: IconTypes.AngleLeft
  },
  angleLight: {
    up: IconTypes.AngleUpLight,
    right: IconTypes.AngleRightLight,
    down: IconTypes.AngleDownLight,
    left: IconTypes.AngleLeftLight
  },
  arrow: {
    up: IconTypes.ArrowUp,
    right: IconTypes.ArrowRight,
    down: IconTypes.ArrowDown,
    left: IconTypes.ArrowLeft
  },
  caret: {
    up: IconTypes.CaretUp,
    right: IconTypes.CaretRight,
    down: IconTypes.CaretDown,
    left: IconTypes.CaretLeft
  }
};

/**
 * Derives a button direction (up, down, left, right) based
 * on the direction of the slider and wether the button is prev or next.
 *
 * @param sliderDirection - Direction of the slider.
 * @param isNext - Wether pressing the button goes to the next slide.
 * @returns Direction of the arrow for this particular button.
 */
export const deriveButtonDirection = (
  sliderDirection: SliderDirection,
  isNext: boolean
): ArrowDirection => {
  if (sliderDirection === 'horizontal') {
    return isNext ? 'right' : 'left';
  }

  return isNext ? 'down' : 'up';
};
