'use client';

import { IVideo, VideoModel } from '@/services/models/Media/Video';
import { TrackKinds } from '@/services/models/Media/Track';
import MediaService from '@/services/isomorphic/MediaService';
import React, { FunctionComponent, useState, useEffect } from 'react';
import IStylable from '../../traits/IStylable';
import { useMediaPlayer } from './MediaPlayer';
import S from './styles.module.scss';

/**
 * Check if a track is a caption track.
 */
export interface IVideoProps extends IStylable {
  /** Video model or DTO to display. */
  video: IVideo;

  /**
   * When `true`, the video will be considered high priority and preload.
   */
  priority?: boolean;
}

/**
 * Video component. This component is a wrapper around the HTML5 video element.
 * Video behavior can be hoisted to the nearest parent `MediaPlayerProvider` component.
 * @param props - Component props.
 * @param props.video - Video model or DTO to display.
 * @param props.width - Represents the _rendered_ width in pixels, so it will affect how large the video appears.
 * @param props.height - Represents the _rendered_ height in pixels, so it will affect how large the video appears.
 * @param props.fill - Causes the video to fill the parent element instead of setting `width` and `height`.
 * @param props.fit - Defines how the video should be resized to fit its container.
 * @param props.priority - When `true`, the video will be considered high priority and preload.
 * @param props.optimizationOptions - Optimization options.
 * @returns Video component.
 */
export const Video: FunctionComponent<IVideoProps> = ({
  video,
  priority = false
}) => {
  const { ref } = useMediaPlayer();
  const [videoModel, setVideoModel] = useState<IVideo>();
  useEffect(() => {
    const videoModel = VideoModel.from(video);
    setVideoModel(videoModel);
  }, [video]);

  return (
    <>
      {videoModel ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption -- Not sure why this is throwing. TrackKinds is set to captions.
        <video // Mute and autoplay are handled by the MediaPlayerProvider.
          controls={videoModel?.settings?.controls ?? false}
          autoPlay={videoModel?.settings?.autoplay ?? false}
          className={S.video}
          muted={videoModel?.settings?.muted ?? false}
          loop={videoModel?.settings?.loop ?? false}
          ref={ref}
          crossOrigin=""
          poster={videoModel.poster}
          preload={priority ? 'auto' : 'metadata'}
          onError={() => {
            MediaService.reportMediaError(videoModel);
          }}
          playsInline
        >
          <source src={videoModel.src} type="video/mp4" />
          {/* TODO: Make this so tracks can be added recursively. */}
          {videoModel.captions?.src && (
            <track
              kind={videoModel.captions?.kind ?? TrackKinds.Captions}
              src={videoModel.captions?.src ?? ''}
              srcLang={videoModel.captions?.srclang.split('-')[0]}
              label={videoModel.captions?.label}
              default
            />
          )}
        </video>
      ) : null}
    </>
  );
};
