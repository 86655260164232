'use client';

import {
  useEffect,
  FunctionComponent,
  useState,
  useCallback,
  CSSProperties
} from 'react';
import classNames from 'classnames';

import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { msg } from '@/services/isomorphic/I18NService';
import { Icon, IconTypes } from '../../../../core-ui/Icon';
import { ContentImage } from '../../core/ContentImage';

import { InnerBanner } from '../InnerBanner';

import S from './styles.base.module.scss';
import type INestableContent from '../../../traits/INestableContent';
import { general_close } from "@/lang/__generated__/ahnu/general_close";

export interface IMarketingBannerProps extends INestableContent {
  /** Type of text banner to render with JS. */
  variant?: 'header' | 'footer';

  /** Add the close button or not. */
  closeButton?: boolean;

  /** Content Item Model used for content options. */
  item: IContentItem;

  /**
   * Whether or not the banner should collapse when scrolled out
   * of view (header banner only)).
   */
  allowBannerScrollCollapse?: boolean;

  /**
   * A number, in pixels. Indicates how much should the user scroll
   * down for the header banner to disappear, or how much the user
   * should scroll back up for it to re-appear.
   */
  scrollThreshold?: number;

  /**
   * Allows the parent component to know if the banner is closed or not
   * and adjust its margins to remove white space.
   * @param isOpen - The new value to set.
   */
  setIsBannerOpen?: (isOpen: boolean) => void;
}

/**
 * A sticky TextBanner that currently encapsulates two components:
 *
 * 1. The header sticky text banner, which hides after scrolling down and appears
 * after scrolling up.
 *
 * 2. The footer banner that sticks to the bottom of the screen, until the
 * footer is reached, after which it sticks to the top of the footer.
 *
 * This is the entrypoint for the InnerBanner and TextBanner components.
 */
export const MarketingBanner: FunctionComponent<IMarketingBannerProps> = ({
  variant = 'header',
  closeButton = true,
  item,
  allowBannerScrollCollapse = true,
  scrollThreshold = 150,
  setIsBannerOpen,
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);

  const { text, link, settings, custom } = itemModel;
  const imageModel = itemModel.image;
  const { style } = settings ?? {};

  const [hidden, setHidden] = useState(true);
  const [closed, setClosed] = useState(true);

  /**
   * The `id` is used to remember when the banner gets closed.
   *
   * Use ContentItemModel.id to differentiate between different banners.
   * If there's no ID, fallback to the variant of the banner.
   * When variant is used, at least we can differentiate between the header
   * and the footer banner, so when the header banner is closed,
   * the footer banner doesn't follow on next
   * re-render, page reload or navigation to another page.
   */
  const id = itemModel.id ?? variant;
  const storageKey = `banner_${id}_closed`;
  const onClick = useCallback((): void => {
    setClosed(true);
    if (setIsBannerOpen) setIsBannerOpen(false);
    sessionStorage.setItem(storageKey, 'true');
  }, [storageKey]);

  /* Check if this banner has been closed by the user during this session. */
  useEffect(() => {
    const isClosed = sessionStorage.getItem(storageKey) === 'true';
    setHidden(isClosed);
    setClosed(isClosed);
    if (setIsBannerOpen) setIsBannerOpen(!isClosed);
  }, [storageKey]);

  /** Listen to scroll events to hide/show the header footer. */
  useEffect(() => {
    if (closed) {
      return () => {};
    }

    // If header banner it should disappear on scroll down and reappear on scroll up.
    if (variant === 'header' && allowBannerScrollCollapse) {
      let currentScrollPosition = window.scrollY;

      const handleScroll = (): void => {
        if (window.scrollY > currentScrollPosition + scrollThreshold) {
          setHidden(true);
          currentScrollPosition = window.scrollY;
        }

        if (window.scrollY < currentScrollPosition - scrollThreshold) {
          setHidden(false);
          currentScrollPosition = window.scrollY;
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }

    return () => {};
  }, [variant, closed, scrollThreshold]);

  /** Allow for icon specification based on brand. */
  const CartIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.Close,
      AHNU: IconTypes.CloseLight
    }),
    []
  );

  /** Initial styling based on variant and base class, does not change after render. */
  const classes = classNames(style?.text && S[style.text], {
    [S.container]: true,
    [S.header]: variant === 'header',
    [S.footer]: variant === 'footer',
    [S.hidden]: hidden || closed
  });

  const styling: CSSProperties = {};

  if (imageModel) {
    imageModel.aspectRatio = 'landscape_ratio24x1';
  } else if (custom?.backgroundColor) {
    styling.backgroundColor = custom.backgroundColor as string;
  }

  return (
    <div className={classes} style={styling}>
      {/* To create the equal spacing on both sides */}
      {closeButton && <div className={S.spacer} />}
      <InnerBanner item={itemModel} contentResolver={contentResolver} />
      {closeButton && (
        <Icon
          onClick={onClick}
          title="bannerCloseBtn"
          className={S.close}
          icon={CartIconType}
          ariaLabel={msg(general_close)}
        />
      )}
      {imageModel && <ContentImage image={imageModel} isBackgroundImage />}
    </div>
  );
};
