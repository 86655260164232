import { FunctionComponent } from 'react';

import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { AspectRatioName } from '@/services/models/Media/ContentImage';
import {
  Slider as GenericSlider,
  Slide,
  ISliderProps as IGenericSliderProps
} from '../../../../Slider';
import {
  Breakpoints,
  Breakpoint,
  Default
} from '../../../../core-ui/Breakpoints';
import { ContentBlock } from '../../core/ContentBlock';
import { getCustomStyling } from '../../../utils/styling-utils';

import S from './styles.module.scss';
import type INestableContent from '../../../traits/INestableContent';

/**
 * The variant of the slider is coming from the CMS.
 * For every variant, the slider and the image aspect ratio vary.
 * This interface describes what is returned from useSliderProps.
 */
interface ISliderVariantProps {
  /** Props that should be passed to the slider component. */
  sliderProps: IGenericSliderProps;

  /** Additional props for the phone version. */
  phoneProps: IGenericSliderProps;

  /** The aspect ration that should be passed to all images. */
  imageAspectRatio: AspectRatioName;
}

/**
 * Based on the viewType (generic-slider, generic-slider-with-navigation, generic-slider-vertical...)
 * returns some props that will customize the slider in a way that will match the expected
 * behaviour of the layout type (viewType).
 * @param viewType - The type of the component coming from CMS.
 * @returns ISliderProps.
 */
const generateSliderProps = (viewType: string): ISliderVariantProps => {
  let sliderProps: IGenericSliderProps = {};
  const phoneProps: IGenericSliderProps = {};
  let imageAspectRatio: AspectRatioName = 'portrait_ratio1x1';

  switch (viewType) {
    case 'full-width':
      sliderProps = {
        direction: 'horizontal',
        loop: true,
        navigation: false,
        centeredSlides: true,
        spaceBetween: 0,
        slidesPerView: 1,
        pagination: {
          inset: false,
          buttonClassName: S.variantHomepagePaginationButton,
          activeClassName: S.variantHomepagePaginationButtonActive
        },
        sliderClassName: S.fullWidthSlider
      };

      imageAspectRatio = 'landscape_ratio16x9';
      break;

    case 'no-control':
      sliderProps = {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 4000
        },
        transitionSpeedMS: 4000,
        navigation: false,
        pagination: false,
        centeredSlides: true,
        spaceBetween: 0,
        slidesPerView: 1,
        style: {
          width: '100%'
        }
      };
      break;

    default:
      break;
  }

  return {
    sliderProps,
    phoneProps,
    imageAspectRatio
  };
};

export interface ISliderProps extends INestableContent {
  /** The content item. */
  item: IContentItem;

  /** The variant of the slider. */
  variant?: string;
}

/**
 * The CMS Slider. Data is coming straight from the Content Service.
 * It is transformed and fed into the actual Slider component.
 */
export const Slider: FunctionComponent<ISliderProps> = ({
  item,
  variant = 'homepage',
  contentResolver
}) => {
  const variantProps = generateSliderProps(variant);
  const itemModel = ContentItemModel.from(item);
  const { custom, items, settings } = itemModel;

  const styling = getCustomStyling(custom);

  // The images have the same aspect ratio for both phones and desktops
  // if they were to differ, we would need duplicates of items with correct aspect
  // ratios set
  item.items?.forEach((item) => {
    itemModel.items?.forEach((item) => {
      const { image } = item;
      if (image) {
        image.aspectRatio = variantProps.imageAspectRatio;
      }
    });
  });

  const slides = items?.map((item, index) => {
    const key = `${item.id}_${index}`;
    return (
      <Slide key={key}>
        <div className={S.slideContent}>
          {/* If it's a collection itself */}
          {(item.items?.length ?? 0) > 0 || item.viewType ? (
            contentResolver(item)
          ) : (
            <ContentBlock item={item} />
          )}
        </div>
      </Slide>
    );
  });

  const style = { ...styling, ...variantProps.sliderProps.style };
  return (
    <Breakpoints>
      <Breakpoint media="phone">
        <GenericSlider
          {...variantProps.sliderProps}
          {...variantProps.phoneProps}
          style={style}
        >
          {slides}
        </GenericSlider>
      </Breakpoint>
      <Default>
        <GenericSlider {...variantProps.sliderProps} style={style}>
          {slides}
        </GenericSlider>
      </Default>
    </Breakpoints>
  );
};
