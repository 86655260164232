import { FunctionComponent } from 'react';
import { ContentItemModel } from '@/services/models/Content';
import type { AspectRatioName } from '@/services/models/Media/ContentImage';
import { ContentImage } from '../../core/ContentImage';
import { Link } from '../../../../core-ui/Link';
import { FragmentParser } from '../../../../utility/FragmentParser';
import { Parallax } from '../../../../utility/Parallax';

import S from './styles.module.scss';

/** Interface for ParallaxItems component. */
interface IParallaxItems {
  /** Description content. */
  items: Array<ContentItemModel> | undefined;
}

/** Component targeting AHNU homepage design, with parallax images with copy overlay. */
export const ParallaxItems: FunctionComponent<IParallaxItems> = ({ items }) => {
  const content = items?.map((item) => {
    const itemModel = ContentItemModel.from(item);
    return itemModel.items?.map((subItem) => {
      const subItemModel = ContentItemModel.from(subItem);
      if (subItemModel.image) {
        const item = subItemModel.image;
        const apsectRatio = 'portrait_ratio3x5' as AspectRatioName;
        item.aspectRatio = apsectRatio;
        return (
          <div key={subItemModel.id} className={S.imageWrapper}>
            <Parallax offset={80}>
              <ContentImage image={item} className={S.image} />
            </Parallax>
          </div>
        );
      }
      if (subItemModel.title ?? subItemModel.text) {
        return (
          <div key={subItemModel.id} className={S.textWrapper}>
            {subItemModel.title && (
              <h2 className={S.title}>
                <FragmentParser string={subItemModel.title ?? ''} />
              </h2>
            )}
            {subItemModel.text && (
              <div className={S.body}>
                <FragmentParser string={subItemModel.text ?? ''} />

                {subItemModel.link && (
                  <Link
                    variant="link"
                    className={S.link}
                    href={subItemModel.link.href}
                  >
                    {subItemModel.link.text}
                  </Link>
                )}
              </div>
            )}
          </div>
        );
      }
      return null;
    });
  });

  return (
    <div className={S.container}>
      <div className={S.innerContainer}>
        {content?.map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key -- Elements are without distinguishing keys.
            <div key={index} className={S.itemContainer}>
              <div className={S.itemInnerContainer}>{item}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
