import { CSSProperties } from 'react';
import {
  ContentPositionX,
  ContentPositionY,
  ISettings
} from '@/services/models/Content';
import { Nullable } from '@/type-utils';

import S from './styles.module.scss';

/**
 * Gets the most common custom settings that come from content.
 * @param custom - Custom object coming from Content.
 * @returns Styling object to be placed on root container.
 * @see {@link IContentItem.custom}
 */
export const getCustomStyling = (
  custom: Nullable<Record<string, unknown>>
): CSSProperties => {
  const styling: Record<string, string> = {};

  if (custom?.backgroundColor && typeof custom.backgroundColor === 'string') {
    // `backgroundColor` contains a CSS value for the background-color, a semi-colon, and
    // then a series of other CSS properties. We split by semi-colons, add the
    // background-color CSS value, and process the remainder as CSS property/value pairs.
    const backgroundColorSplit = custom.backgroundColor.split(';');
    // Add the CSS property to the first element.
    backgroundColorSplit[0] = 'background-color: ' + backgroundColorSplit[0];

    // Iterate over the arrays of CSS strings, split by colon to get keys and values, then
    // convert from kebab-case to camelCase for the property names.
    for (const cssString of backgroundColorSplit) {
      const [property, value] = cssString.split(':').map((item) => item.trim());
      const camelCaseProperty = property.replace(
        /-([a-z])/g,
        (_, letter: string) => letter.toUpperCase()
      );
      styling[camelCaseProperty] = value;
    }
  }
  return styling;
};

/**
 * Holds the overlay styling classes. To modify this,
 * modify the teaser overlay styles in the Content studio.
 */
export interface IItemOverlay {
  /** Headline class. */
  headline: string;

  /** Main text class. */
  text: string;

  /** Button variant. */
  cta: string;

  /** Horizontal positional adjustment. */
  positionX: ContentPositionX;

  /** Vertical positional adjustment. */
  positionY: ContentPositionY;

  /** Width of the item. */
  width: number;

  /** Custom Props
   * Used to pass custom css props to a component.
   * @see {@link parseCustomProperties}
   * */
  props?: CSSProperties;
}

/**
 * Returns headline and text styling. For the cta it provides the variant string.
 * This borrows the CoreMedia naming convention of OverlayStyling to differentiate from
 * raw style tag manipulation done elsewhere.
 * @param settings - Style object from a ContentItem.
 * @returns An object to apply item styling.
 */
export const getItemOverlay = (settings: Nullable<ISettings>): IItemOverlay => {
  const { style, positionX, positionY, width } = settings ?? {};

  /**
   * Flattens the incoming overlay style strings into their corresponding classes
   * and props.
   * @example
   * toStyle('headlineClass1,headlineClass2;--foo:bar;--baz:qux;') =>
   * 'headlineClass1 headlineClass2'
   * @param s - A string of classes and props.
   * @returns A string of classes separated by a space.
   */
  const toClassStyle = (s: string): string => {
    let classes = s.split(';');
    classes = classes[0].split(',') ?? classes;
    const returnString = classes.map((c) => S[c] ?? c);
    return returnString.join(' ');
  };

  /**
   * Flattens the incoming overlay style strings into their corresponding props.
   * @example
   * style =
   * {  headline: 'headlineClass1,headlineClass2;--foo:bar;--baz:qux;',
   *    text: 'textClass1,textClass2;--foo:bar;--baz:qux;',
   *    cta: 'ctaClass1,ctaClass2;--foo:bar;--baz:qux;'
   *    }
   *  =>
   * props = { '--foo': 'bar', '--baz': 'qux' }
   * @param s - A string of classes and props.
   */
  const props = [
    style?.headline ?? '',
    style?.text ?? '',
    style?.cta ?? ''
  ].reduce((acc, cur) => {
    const props = cur?.split(';')[1];
    return props ? { ...acc, ...parseCustomProperties(props) } : acc;
  }, {});
  return {
    headline: toClassStyle(style?.headline ?? ''),
    text: toClassStyle(style?.text ?? ''),
    cta: toClassStyle(style?.cta ?? ''),
    positionX: positionX ?? ContentPositionX.Center,
    positionY: positionY ?? ContentPositionY.Center,
    width: width ?? 100,
    props
  };
};

/**
 * Parses CSS custom properties from a string. Allows for custom properties to be
 * defined in the Content studio and applied to a component. This function can
 * be used in conjunction with {@link getItemOverlay} to apply custom properties to a component.
 * @see {@link /apps/sanuk/src/styles/helpers/_overlayStyles.scss}
 * @param customProperties - A string of custom properties.
 * @returns A CSSProperties object.
 * @example Valid: parseCustomProperties('--foo:bar,--baz:qux') => { '--foo': 'bar', '--baz': 'qux' }
 * @example Invalid: parseCustomProperties('foo:bar,baz:qux;') => {} (empty object) Because prop must be valid CSS custom property (starts with --)
 */
export const parseCustomProperties = (
  customProperties: string
): Record<string, string> => {
  const properties = customProperties
    .split(',')
    .reduce<Record<string, string>>((acc, cur) => {
      const [key, value] = cur.split(':');
      if (key.startsWith('--')) acc[key.trim()] = value.trim();
      return acc;
    }, {});
  return properties;
};

/**
 * Settings to be used as options for templates.
 */
export interface IContainerOptions {
  /** Flip the template, what this means is interpreted by the template. */
  flip?: boolean;
  /** Add a class to the template container. */
  containerClass?: string;
  /** Background color of the template container. */
  backgroundColor?: string;
}

/**
 * Gets the standard container options from the content and normalizes it.
 * @param custom - Custom settings from ContentItem.
 * @returns Some container options.
 */
export const getContainerOptions = (
  custom: Nullable<Record<string, unknown>>
): IContainerOptions => {
  const options: IContainerOptions = {};

  if (custom?.order === 1) {
    options.flip = true;
  }

  if (custom?.widthContain) {
    options.containerClass = custom.widthContain as string;
  }

  if (custom?.backgroundColor) {
    options.backgroundColor = custom.backgroundColor as string;
  }

  return options;
};
