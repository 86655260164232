import { toStatelessImmutable } from '@/utils/object-utils';
import { DTO, Nullable } from '@/type-utils';
import type { IContentItem, ILink, ISettings } from '.';
import Model from '../Model';

import { IContentVideo, ContentVideoModel } from '../Media/ContentVideo';

import { IContentImage, ContentImageModel } from '../Media/ContentImage';

import CMSContentTypes from './CMSContentTypes';

/** @inheritDoc */
export default class ContentItemModel
  extends Model<DTO<IContentItem>>
  implements IContentItem
{
  /** @inheritDoc */
  public readonly id?: string;

  /** @inheritDoc */
  public readonly viewType?: string;

  /** @inheritDoc */
  public readonly items?: Array<ContentItemModel>;

  /** First item of items. */
  public readonly item?: ContentItemModel;

  /** @inheritDoc */
  public readonly title?: string;

  /** @inheritDoc */
  public readonly text?: string;

  /** @inheritDoc */
  public readonly settings?: ISettings;

  /** First link of links. */
  public readonly link?: ILink;

  /** @inheritDoc */
  public readonly links?: Array<ILink>;

  /** First image of images. */
  public readonly image?: Nullable<ContentImageModel>;

  /** @inheritDoc */
  public readonly images?: Array<ContentImageModel>;

  /** @inheritDoc */
  public readonly video?: Nullable<ContentVideoModel>;

  /** @inheritDoc */
  public readonly videos?: Array<ContentVideoModel>;

  /** @inheritDoc */
  public readonly custom?: Record<string, unknown>;

  /** @inheritDoc */
  public readonly type?: CMSContentTypes;

  /** @inheritDoc */
  public readonly html?: string;

  /**
   * Takes in an item representing the data of an item and outputs the ContentItemModel.
   * @param item - Item data that holds the necessary fields.
   */
  public constructor(item: DTO<IContentItem>) {
    super(item);

    if (item === undefined) return;
    this.id = item?.id ?? undefined;

    if (item.viewType) this.viewType = item.viewType;
    if (item.title) this.title = item.title;
    if (item.text) this.text = item.text;
    if (item.custom) this.custom = item.custom;
    if (item.settings) this.settings = item.settings;

    if (item.items) {
      this.items = this.constructChildren(
        item.items as Array<DTO<IContentItem>>
      );
      [this.item] = this.items;
    }

    if (item.links) {
      this.links = [...(item.links as Array<ILink>)];
      [this.link] = item.links as Array<ILink>;
    }

    if (item.images) {
      this.images = this.constructImages(
        item.images as Array<DTO<IContentImage>>
      );
      [this.image] = this.images;
    }

    if (item.videos) {
      this.videos = this.constructVideos(
        item.videos as Array<DTO<IContentVideo>>
      );
      [this.video] = this.videos;
    }

    if (item.type) {
      this.type = item.type;
      // This means the item type is an HTML fragment and will
      // be rendered using one component view-type.
      if (item.type === CMSContentTypes.Fragment) {
        this.viewType = 'html-fragment';
        this.html = item.html;
      }
    } else {
      this.type = CMSContentTypes.Teaser;
    }
  }

  /**
   * Constructs the children models held on the model.
   * @param items - Item data to be turd into models.
   * @returns - Formatted item models.
   */
  private constructChildren(
    items: Array<DTO<IContentItem>>
  ): Array<ContentItemModel> {
    const itemModels: Array<ContentItemModel> = [];
    items.forEach((item) => {
      itemModels.push(ContentItemModel.from(item));
    });

    return itemModels;
  }

  /**
   * Gets the images as models off the IContentItem.
   * @param images - Collection of image objects.
   * @returns ContentImageModels.
   */
  private constructImages(
    images: Array<DTO<IContentImage>>
  ): Array<ContentImageModel> {
    const imageModels: Array<ContentImageModel> = [];
    images.forEach((image) => {
      imageModels.push(ContentImageModel.from(image));
    });

    return imageModels;
  }

  /**
   * Gets the videos as models off the IContentItem.
   * @param videos - Collection of image objects.
   * @returns ContentVideoModels.
   */
  private constructVideos(
    videos: Array<DTO<IContentVideo>>
  ): Array<ContentVideoModel> {
    const videoModels: Array<ContentVideoModel> = [];
    videos?.forEach((video) => {
      videoModels.push(ContentVideoModel.from(video));
    });
    return videoModels;
  }

  /** @inheritDoc */
  public toDTO(): DTO<IContentItem> {
    const {
      id,
      viewType,
      items,
      title,
      text,
      custom,
      links,
      images,
      videos,
      settings,
      type,
      html
    } = this;
    return toStatelessImmutable<Partial<DTO<IContentItem>>>({
      id,
      viewType,
      items,
      title,
      text,
      custom,
      links,
      images,
      videos,
      settings,
      type,
      html
    }) as DTO<IContentItem>;
  }
}
