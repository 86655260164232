import { FunctionComponent, CSSProperties, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  IContentItem,
  ContentItemModel,
  ContentPositionX
} from '@/services/models/Content';
import { Slider, Slide } from '../../../../Slider';
import {
  Breakpoint,
  Breakpoints,
  Default
} from '../../../../core-ui/Breakpoints';
import INestableContent from '../../../traits/INestableContent';
import { getCustomStyling, getItemOverlay } from '../../..';
import S from './styles.base.module.scss';

export interface IMultiItemSliderProps extends INestableContent {
  /** The collection item, will contain any number of teasers. */
  item: IContentItem;
}

/**
 * Multi Item Slider can have any number of teasers as children.
 * Each teaser pulls its style from the viewType setting.
 *
 * For desktop, teasers are shown as a row, which can be configured to be
 * any number of teasers per row. This number is set in the settings of the
 * collection item by using the "widthContain" local setting.
 */
export const MultiItemSlider: FunctionComponent<IMultiItemSliderProps> = ({
  item,
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);
  const { items = [], custom, settings } = itemModel;
  const overlay = getItemOverlay(settings);
  const styling = getCustomStyling(custom);

  const { widthContain = 3, heightContain = 0 } = custom as Record<
    string,
    number
  >;
  for (const item of items) {
    if (item?.image?.aspectRatio) {
      item.image.aspectRatio = 'portrait_ratio3x4';
    }
  }

  let positionXClass: string;
  switch (overlay.positionX) {
    case ContentPositionX.Left: {
      positionXClass = S.left;
      break;
    }
    case ContentPositionX.Right: {
      positionXClass = S.right;
      break;
    }
    default: {
      positionXClass = S.center;
      break;
    }
  }

  const slideColumns = (
    items: Array<ContentItemModel>
  ): Array<Array<ContentItemModel>> => {
    const slides = [];
    /**
     * When heightContain is not 1, we need to break the items into slides
     * based on the heightContain setting.
     *
     * Example: heightContain = 2, items.length = 6, widthContain = 3
     * will result in 3 slides, each containing 2 items.
     *
     * Example: heightContain = 3, items.length = 6, widthContain = 2
     * will result in 2 slides, the first containing 3 items, the second
     * containing 3 items.
     * */
    if (heightContain > 1) {
      const numSlides = Math.ceil(items.length / heightContain);
      for (let i = 0; i < numSlides; i++) {
        const slide = items.slice(i, i + widthContain + 1);
        const first = slide[0];
        const last = slide[slide.length - 1] || null;
        slides.push([first, last]);
      }
    } else {
      items.forEach((item, i) => {
        slides.push([item]);
      });
    }
    return slides;
  };

  return (
    <Breakpoints>
      <Breakpoint media={['desktop', 'tablet']}>
        {/* If items is larger than width contain, break into slider */}
        <div className={S.container} style={{ ...styling } as CSSProperties}>
          {heightContain != null &&
          items.length > widthContain * heightContain ? (
            <Slider
              loop
              navigation={{
                inset: true,
                buttonClassName: S.navigationButton,
                arrowVariant: 'arrow'
              }}
              pagination={{
                inset: false,
                buttonClassName: S.paginationButton,
                activeClassName: S.paginationButtonActive
              }}
              slidesPerView={widthContain}
              height="auto"
              spaceBetween={4}
            >
              {/* Wrap every teaser in a <Slide> component. */}
              {items.map((item) => {
                const viewType = item.viewType || 'content-block';
                return (
                  <Slide key={uuidv4()}>
                    {contentResolver({ ...item, viewType })}
                  </Slide>
                );
              })}
            </Slider>
          ) : (
            <div
              className={S.wrapper}
              style={
                {
                  ...styling,
                  '--num-cols': widthContain,
                  '--num-rows': heightContain
                } as React.CSSProperties
              }
            >
              {items.map((item) => {
                const viewType = item.viewType || 'content-block';
                return (
                  <div key={uuidv4()} className={S.teaser}>
                    {contentResolver({ ...item, viewType })}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Breakpoint>
      <Default>
        {items.length > 1 ? (
          <Slider
            loop
            navigation={false}
            pagination={{
              inset: false,
              buttonClassName: S.paginationButton,
              activeClassName: S.paginationButtonActive
            }}
            slidesPerView={1}
            centeredSlides
            height="auto"
            spaceBetween={4}
          >
            {/* Wrap every teaser in a <Slide> component. */}
            {slideColumns(items).map((col, i) => {
              return (
                <Slide key={uuidv4()}>
                  <div
                    className={S.teaser}
                    style={
                      {
                        '--num-cols': widthContain,
                        '--num-rows': heightContain
                      } as React.CSSProperties
                    }
                  >
                    {col.map((item) => {
                      const viewType = item.viewType || 'content-block';
                      return (
                        <Fragment key={uuidv4()}>
                          {contentResolver({ ...item, viewType })}
                        </Fragment>
                      );
                    })}
                  </div>
                </Slide>
              );
            })}
          </Slider>
        ) : (
          <>
            {items.map((item) => {
              const viewType = item.viewType || 'content-block';
              return (
                <div
                  style={
                    {
                      '--num-cols': widthContain,
                      '--num-rows': heightContain
                    } as React.CSSProperties
                  }
                  className={S.teaser}
                  key={uuidv4()}
                >
                  {contentResolver({ ...item, viewType })}
                </div>
              );
            })}
          </>
        )}
      </Default>
    </Breakpoints>
  );
};
