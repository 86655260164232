'use client';

import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useRef,
  LegacyRef,
  MouseEvent
} from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';

import IStylable from '../../traits/IStylable';

import S from './styles.module.scss';

/**
 * The child component can have a prop that accepts a function named
 * onHideCursor that will allow for the changing of the visibility of
 * the cursor. This allows the child to control when the cursor is visible.
 * @example
 * const MyComponent = ({ onHideCursor }) => {
 *   return (
 *     <div
 *       onMouseEnter={() => onHideCursor(true)}
 *       onMouseLeave={() => onHideCursor(false)}
 *     >
 *      ...
 *     </div>
 *   );
 * };
 */
type ChildComponent =
  | React.ComponentType<{
      onHideCursor?: (isHidden: boolean) => void;
    }>
  | React.ComponentClass<{
      onHideCursor?: (isHidden: boolean) => void;
    }>;

export interface IDragCursorProps extends PropsWithChildren, IStylable {
  /** Label for our drag cursor. */
  label?: string;
}

/**
 * DragCursor component. Renders a cursor that follows the mouse pointer.
 * @param label - Label for our drag cursor.
 * @param className - Additional class names.
 * @param children - Child components.
 */
export const DragCursor: FunctionComponent<IDragCursorProps> = ({
  label = 'drag',
  className,
  children
}) => {
  const containerRef: LegacyRef<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const rect = containerRef.current?.getBoundingClientRect();
  const [visible, setVisible] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0
  });

  /**
   * Update the cursor position.
   * @param e - MouseEvent we pull the position from.
   */
  function handleMove(e: MouseEvent): void {
    setPosition({ x: e.clientX, y: e.clientY });
  }

  /**
   * Handle visibility change.
   * @param isHidden - Whether the cursor should be hidden.
   */
  function handleHideCursor(isHidden: boolean): void {
    setVisible(!isHidden);
  }

  // Add the visibility-changing handler to each child component.
  let childrenWithProps;
  if (children) {
    childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child) && typeof child.type === 'function') {
        const Component = child.type as ChildComponent;
        return <Component {...child.props} onHideCursor={handleHideCursor} />;
      }
      return child;
    });
  }

  return (
    <div
      className={S.root}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onPointerMove={visible ? handleMove : undefined}
      ref={containerRef}
    >
      <div
        style={{
          left: `${position.x - (rect ? rect.left : 0)}px`,
          top: `${position.y - (rect ? rect.top : 0)}px`
        }}
        className={classes(S.cursor, className, {
          [S.visible]: visible
        })}
      >
        {label}
      </div>
      {childrenWithProps}
    </div>
  );
};
