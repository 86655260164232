'use client';

import { FunctionComponent, useEffect, useState, useRef } from 'react';
import { motion, animate } from 'framer-motion';
import { getTimeOfDayColor } from '../animation-utils';

import S from './styles.module.scss';

/**
 * Props for the BackgroundPanel component.
 */
interface IBackgroundPanelProps {
  /** Whether the element is in view. */
  isInView?: boolean;
  /** The duration of the animation. */
  duration?: number;
}

/**
 * A component that renders a background panel with an animated color,
 * based on the time of day.
 * @param props - The props for the component.
 * @returns The BackgroundPanel component.
 */
const BackgroundPanel: FunctionComponent<IBackgroundPanelProps> = ({
  isInView = false,
  duration = 1
}) => {
  const startColor = 'hsl(0, 0, 0)';
  const currentBgColorRef = useRef<string>(startColor);
  const [currentBgColor, setCurrentBgColor] = useState(startColor);
  const [targetBgColor, setTargetBgColor] = useState(currentBgColorRef.current);

  useEffect(() => {
    // Note that isInView will only ever be set true once
    if (isInView) {
      const color = getTimeOfDayColor();
      setTargetBgColor(color);
    }
  }, [isInView]);

  useEffect(() => {
    const controls = animate(currentBgColorRef.current, targetBgColor, {
      duration,
      onUpdate(value) {
        currentBgColorRef.current = value;
        setCurrentBgColor(value);
      }
    });

    return () => controls.stop();
  }, [duration, targetBgColor]);

  return (
    <motion.div
      className={S.container}
      style={{ backgroundColor: currentBgColor }}
    />
  );
};

export default BackgroundPanel;
