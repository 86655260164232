import { Children, ReactNode } from 'react';
import { Nullable } from '@/type-utils';

import {
  ISliderProps,
  ISliderOptions,
  ISliderAutoplayOptions,
  ISliderNavigationOptions,
  ISliderPaginationOptions
} from '../ISliderProps';

/**
 * Slider default options.
 */
export const SliderDefaultOptions: ISliderOptions = {
  direction: 'horizontal',
  height: '350px',

  wrapperClassName: '',
  navigationWrapperClassName: '',
  sliderClassName: '',

  navigation: {
    enabled: true,
    inset: true,
    arrowVariant: 'angle'
  },
  pagination: {
    enabled: true,
    inset: true,
    clickable: true
  },
  autoplay: {
    enabled: false,
    loop: true,
    delay: 3000,
    reverse: false,
    disableOnInteraction: false
  },
  transitionSpeedMS: 300,

  centeredSlides: false,
  slidesPerView: 1,
  spaceBetween: 0,

  loop: false,
  grabCursor: true
};

/**
 * There are some options that are provided in a convenient way for
 * the component props.
 *
 * Ex: navigation={true}  =>  navigation={{ enabled: true, ...someDefaultValues }}.
 *
 * If the prop is not provided, returns the default object
 * If the prop is true, returns the default object, with enabled: true
 * (enabled: true is required since some options are disabled by default)
 * If the prop is an object, merges it with the default option object.
 *
 * @param  value - The value passed in the prop.
 * @param  defaultObject - The default value for this option.
 * @returns Final object representing the option value.
 */
export const getOptionFromProp = <T>(
  value: Nullable<boolean | Partial<T>>,
  defaultObject: Partial<T>
): Partial<T> => {
  if (typeof value === 'boolean') {
    return {
      ...defaultObject,
      enabled: value
    };
  }

  if (!value || typeof value !== 'object') {
    return { ...defaultObject };
  }

  return {
    ...defaultObject,
    /**
     * If an object is provided (for autoplay let's say)
     * autoplay={{ loop: true }}
     * This means, enabled: true, loop: true, and other default options
     * So let's slip in enabled: true in there, just in case.
     * User's can disable this by providing enabled: false in the object.
     */
    enabled: true,
    ...value
  } as Partial<T>;
};

/**
 * Little helper to get the height of the slider from the props.
 * @param value - Props.height.
 * @param defaultValue - SliderDefaultOptions.height.
 * @returns String.
 */
export const getHeight = (
  value: Nullable<string | number>,
  defaultValue: string
): string => {
  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'number') {
    return value.toString() + 'px';
  }

  return defaultValue;
};

/**
 * Given Slider props, derives the slider options with all values strictly set.
 * @param originalProps - Slider component props.
 * @returns ISliderOptions.
 */
export const deriveSliderOptionsFromProps = (
  originalProps: ISliderProps
): ISliderOptions => {
  /* Remove children and style properties so it doesn't end up in options */
  const { children, style, ...props } = originalProps;

  const options: ISliderOptions = {
    ...SliderDefaultOptions,
    ...props,

    height: getHeight(props.height, SliderDefaultOptions.height),
    autoplay: getOptionFromProp<ISliderAutoplayOptions>(
      props.autoplay,
      SliderDefaultOptions.autoplay
    ),
    navigation: getOptionFromProp<ISliderNavigationOptions>(
      props.navigation,
      SliderDefaultOptions.navigation
    ),
    pagination: getOptionFromProp<ISliderPaginationOptions>(
      props.pagination,
      SliderDefaultOptions.pagination
    )
  };

  return options;
};

/** An object that contains the Slider's children split into two: Slide nodes and the rest. */
interface ISliderDividedChildren {
  /** An array of slide nodes created by the <Slide> component. */
  slides: Array<ReactNode>;

  /** Every other node gets thrown in this array. */
  rest: Array<ReactNode>;
}

/**
 * Splits children into an array of slide nodes and an array of everything else.
 * @param children - The children passed to the slider.
 * @returns An object containing two arrays, slides and rest (other nodes, custom buttons, etc).
 */
export const divideChildren = (children: ReactNode): ISliderDividedChildren => {
  const slides: Array<ReactNode> = [];
  const rest: Array<ReactNode> = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Needed to access the .type property.
  Children.forEach(children, (child: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- We're checking safely so no problem.
    if (child?.type?.displayName === 'SwiperSlide') {
      slides.push(child as ReactNode);
    } else {
      rest.push(child as ReactNode);
    }
  });

  return {
    slides,
    rest
  };
};
