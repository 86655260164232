import { createContext } from 'react';
import Swiper from 'swiper/types/swiper-class';
import { Nullable } from '@/type-utils';

import { ISliderOptions } from '../ISliderProps';

/**
 * What the slider context value looks like.
 */
export interface ISliderContext {
  /** The underline slider instance (currently Swiper). */
  slider: Swiper | Nullable<Swiper>;

  /** Options derived from the props. */
  options: ISliderOptions;
}

export const SliderContext = createContext<Nullable<ISliderContext>>(null);
