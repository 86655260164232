'use client';

import { useId, type FC } from 'react';

import { ContentItemModel, IContentItem } from '@/services/models/Content';
import { classes } from '@/next-utils/css-utils/scss-utils';
import S from './styles.base.module.scss';

import { getItemOverlay } from '../../../utils/styling-utils';
import INestableContent from '../../../traits/INestableContent';

export interface IRowProps extends INestableContent {
  /** The content item. Should be a collection. */
  item: IContentItem;
}

/**
 * A very simple class that renders a row of items. Also provides collection
 * with access to custom props usually reserved for content items.
 */
export const Row: FC<IRowProps> = ({ item, contentResolver }) => {
  const itemModel = ContentItemModel.from(item);
  const { settings, items = [] } = itemModel;
  const itemId = useId();

  const { headline: containerClasses, props } = getItemOverlay(settings);

  return (
    <div className={classes(S.container, containerClasses)} style={props}>
      {items.map((subItem, i) => (
        // eslint-disable-next-line react/no-array-index-key -- Can we remove this rule if used as so?
        <div key={`${itemId}-${i}`} className={S.item}>
          {contentResolver(subItem)}
        </div>
      ))}
    </div>
  );
};
