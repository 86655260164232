import { DTO, Nullable } from '@/type-utils';
import { ResourceNotFoundError } from '@/utils/errors';
import { toStatelessImmutable } from '@/utils/object-utils';
import { action, computed, makeObservable, observable } from 'mobx';
import CropTemplates from '../../../serverless/integrations/CoremediaService/__downloaded__/image-crops.json';
import Model from '../../Model';
import { AspectRatioName, IContentImage } from '.';
import { CMSContentTypes } from '../../Content';
import LoggerService from '../../../isomorphic/LoggerService';

/** Size Object gained downloaded from content service. */
export interface ISize {
  /** An image width. */
  width: number;

  /** An image height. */
  height: number;
}

/** The image crop template defining each image crop type. */
export interface IImageCropTemplate {
  /** Name of the template. */
  name: string;

  /** Aspect ratio with a height and width property. */
  aspectRatio: ISize;

  /** All sizes available in content service. */
  sizes: Array<ISize>;
}

/**
 * This object is used to get an src. Depending on the options supplied
 * it will rely on the current model state or override specific values.
 */
export interface ISrcArguments {
  /** Width of the element currently. */
  elementWidth?: number;
  /** Use this aspect ratio rather than the current state. */
  aspectRatio?: AspectRatioName;
  /** Is the screen currently mobile. */
  isMobile?: boolean;
}

/**
 * This image model is used to construct and manipulate content images.
 */
export default class ContentImageModel
  extends Model<DTO<IContentImage>>
  implements IContentImage
{
  @observable private _src: Nullable<string> = null;
  @observable private _aspectRatio: AspectRatioName;
  @observable private _mobileAspectRatio: Nullable<AspectRatioName>;

  /** @inheritdoc */
  public readonly uuid!: string;

  /** @inheritdoc */
  public readonly urlTemplate: string;

  /** @inheritDoc */
  public readonly type?: CMSContentTypes.Image;

  /** @inheritdoc */
  @computed public get src(): string {
    if (!this._src) {
      throw new Error('`ContentImageModel` was provided no `src` value.');
    }
    return this._src;
  }

  /** @inheritdoc */
  @computed public get aspectRatio(): AspectRatioName {
    return this._aspectRatio;
  }

  /** @inheritdoc */
  public set aspectRatio(ratio: AspectRatioName) {
    this._aspectRatio = ratio;
  }

  /** @inheritdoc */
  @computed public get mobileAspectRatio(): Nullable<AspectRatioName> {
    return this._mobileAspectRatio;
  }

  /** @inheritdoc */
  public set mobileAspectRatio(ratio: Nullable<AspectRatioName>) {
    this._mobileAspectRatio = ratio;
  }

  /** @inheritdoc */
  @observable public activeAspectRatio: Nullable<AspectRatioName>;

  /**
   * Gets the current template by aspectRatio.
   * @returns The image crop template object from content service.
   */
  private get template(): IImageCropTemplate {
    return this.getTemplate(
      this.activeAspectRatio ? this.activeAspectRatio : this._aspectRatio
    );
  }

  /** Alt text for image. */
  public readonly alt: Nullable<string>;

  /**
   * Gets the height from the largest size available at this aspect ratio.
   * @returns Number to pass to NEXT Image height.
   */
  @computed public get height(): number {
    return this.template.sizes[0].height;
  }

  /**
   * Gets the template width as the largest size available at this aspect ratio.
   * @returns Number to pass to NEXT Image width.
   */
  @computed public get width(): number {
    return this.template.sizes[0].width;
  }

  /**
   * Builds a model from any Content Image representation.
   * @param image - An image DTO used to instantiate model.
   */
  public constructor(image: DTO<IContentImage>) {
    super(image);
    if ('uuid' in image) this.uuid = image.uuid;
    if ('alt' in image) this.alt = image.alt;
    this.urlTemplate = image.urlTemplate ?? '';
    this.type = CMSContentTypes.Image;

    this._aspectRatio = image.aspectRatio ?? 'landscape_ratio4x3';
    if (image.mobileAspectRatio)
      this._mobileAspectRatio = image.mobileAspectRatio;

    this.setSrc({ elementWidth: 600, isMobile: true });

    makeObservable(this);
  }

  /**
   * Gets the aspect ratio template from the generated content.
   * @param ratio - The aspect ratio string.
   * @returns The aspect ratio template object generated for content.
   * @throws A {@link ResourceNotFoundError} if no template could be found.
   */
  private getTemplate(ratio: AspectRatioName): IImageCropTemplate {
    const template = (CropTemplates as Array<IImageCropTemplate>).find(
      (template) => template.name === ratio
    );

    if (!template) {
      LoggerService.warn(`No template was found for \`ContentImageModel\`.`);

      return (CropTemplates as Array<IImageCropTemplate>)[0];
    }

    return template;
  }

  /**
   * Sets the current aspect ratio given screen width.
   * @param isMobile - Is the current window under the mobile breakpoint.
   */
  private setActiveAspectRatio(isMobile: boolean): void {
    if (isMobile) {
      this.activeAspectRatio = this._mobileAspectRatio
        ? this._mobileAspectRatio
        : this._aspectRatio;
    } else {
      this.activeAspectRatio = this._aspectRatio;
    }
  }

  /**
   * Gets the updated src value given a certain element width,
   * an aspect ratio, and whether the window has a mobile width or not.
   * It loops through the available image sizes and finds the first
   * one, looping up, that is larger than the image is currently.
   * @param elementWidth - Current width of element.
   * @param isMobile - Is this under the mobile breakpoint.
   * @param aspectRatio - The aspect ratio override.
   */
  @action public setSrc({
    elementWidth,
    aspectRatio,
    isMobile = false
  }: ISrcArguments): void {
    if (aspectRatio) {
      this.activeAspectRatio = aspectRatio;
    } else {
      this.setActiveAspectRatio(isMobile);
    }

    let src: Nullable<string> = null;

    if (this.template?.sizes.length > 0) {
      let size: ISize;
      if (typeof elementWidth !== 'number') {
        size = this.template.sizes[this.template.sizes.length - 1];
      } else {
        size =
          this.template.sizes.find((size) => size.width > elementWidth) ??
          this.template.sizes[this.template.sizes.length - 1];
      }

      const url = new URL(this.urlTemplate);
      url.pathname = url.pathname
        .split('/')
        .map((pathPart) => {
          if (pathPart === encodeURI('{cropName}'))
            return this.activeAspectRatio;
          if (pathPart === encodeURI('{width}')) return size.width.toString();
          return pathPart;
        })
        .join('/');
      src = url.toString();
    }

    this._src = src;
  }

  /** @inheritDoc */
  public toDTO(): DTO<IContentImage> {
    const { alt, aspectRatio, mobileAspectRatio, urlTemplate, uuid, type } =
      this;
    return toStatelessImmutable<Partial<DTO<IContentImage>>>({
      alt,
      aspectRatio,
      mobileAspectRatio,
      uuid,
      urlTemplate,
      type
    }) as DTO<IContentImage>;
  }
}
