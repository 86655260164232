import { FunctionComponent } from 'react';

import { AspectRatioName } from '@/services/models/Media/ContentImage';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import {
  getContainerOptions,
  getCustomStyling,
  getItemOverlay
} from '../../../utils/styling-utils';
import { ContentBlock } from '../../core/ContentBlock';

import S from './styles.base.module.scss';
import type INestableContent from '../../../traits/INestableContent';

export interface IHeroCollectionProps extends INestableContent {
  /** The content item. */
  item: IContentItem;
}

/**
 * Hero Collection content, typically with a video and 1x1 slider
 * element shown side by side on desktop.
 */
export const HeroCollection: FunctionComponent<IHeroCollectionProps> = ({
  item,
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);
  const { custom, items, settings } = itemModel;
  const styling = getCustomStyling(custom);
  const { containerClass } = getContainerOptions(custom);
  const overlay = getItemOverlay(settings);

  return (
    <div
      className={`${S.root} ${containerClass}`}
      style={{
        ...styling
      }}
    >
      {items?.map((item, index) => {
        // We make some opinionated assumptions about content configuration
        // here -- if a block (likely video) has an aspect ratio assigned,
        // we use that to determine its desktop width, with the expectation
        // that the accompanying item in the row will be a 1x1 media element.
        const itemRatio: AspectRatioName =
          (item?.custom?.mainAspectRatio as AspectRatioName) ??
          'portrait_ratio1x1';

        return (
          <div
            className={`${S.itemContainer} ${overlay.headline} ${S[itemRatio]}`}
            key={item.id as string}
            data-banner-index={index}
          >
            <div className={S.embeddedContainer}>
              {/*
                If the sub-item has a view type, let the GenericItem determine 
                what to render, otherwise render a ContentBlock.
              */}
              {item.viewType ? (
                contentResolver(item)
              ) : (
                <ContentBlock item={item} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
