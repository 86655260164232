import { FunctionComponent } from 'react';
import { ILink } from '@/services/models/Content';
import { Link } from '../../../../../core-ui/Link';
import { FragmentParser } from '../../../../../utility/FragmentParser';

interface ITextWithLinkProps {
  /**
   * Text fragment, often from content, possibly containing HTML.
   */
  text: string;
  /**
   * Link object to be used to construct a Call to Action button
   * Essentially a marketing button defined in the content.
   */
  link?: ILink;
}

/**
 * `TextWithLink` allows us to add any text fragment and a link as a Call to Action in the UI.
 */
export const TextWithLink: FunctionComponent<ITextWithLinkProps> = ({
  text,
  link
}) => (
  <>
    <FragmentParser string={text} />
    {link && (
      <Link variant="link" href={link.href}>
        {link.text}
      </Link>
    )}
  </>
);
