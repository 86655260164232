'use client';

import { FunctionComponent } from 'react';

import { IContentItem } from '@/services/models/Content';
import { TextBlock } from '../../core/TextBlock';

import S from './styles.module.scss';

export interface ILinkListProps {
  /** The content item. */
  item: IContentItem;
}

/** A group of links displayed in a row.  */
export const LinkList: FunctionComponent<ILinkListProps> = ({ item }) => {
  return (
    <div className={S.container}>
      <TextBlock buttonContainerStyle={S.buttonContainerStyle} item={item} />
    </div>
  );
};
