'use client';

import React, { FC, useCallback, useEffect, useContext, useRef } from 'react';
import { AnchorContext } from './AnchorContext';
import S from './styles.module.scss';

/**
 * The Anchor component - the target of the scroll when used in conjunction with
 * the {@link AnchorContextProvider}. Manages the element ref and event handlers.
 * Must be provided via the hook {@link useAnchor}.
 * @returns The anchor element.
 */
export const Anchor: FC = () => {
  const { anchorEl } = useContext(AnchorContext);
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    if (anchorEl && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [anchorEl, ref.current]);

  /**
   * This pattern (where the element is clicked in the useEffect) is necessary
   * to ensure that the event is triggered even when the same element is clicked
   * multiple times.
   */
  useEffect(() => {
    if (anchorEl && ref.current) {
      anchorEl.onclick = handleClick;
      anchorEl.click();
    }
    return () => {
      if (anchorEl && ref.current) {
        anchorEl.onclick = null;
      }
    };
  }, [anchorEl, ref.current, handleClick]);

  return <div ref={ref} className={S.anchor} />;
};
