import { FunctionComponent } from 'react';

import { ContentImageModel } from '@/services/models/Media/ContentImage';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { Link } from '../../../../core-ui/Link';
import { Button, ButtonVariant } from '../../../../core-ui/Button';
import { FragmentParser } from '../../../../utility/FragmentParser';

import { ContentImage } from '../../core/ContentImage';
import {
  getCustomStyling,
  getContainerOptions,
  getItemOverlay
} from '../../../utils/styling-utils';

import S from './styles.module.scss';

export interface ICubeBottomProps {
  /** The content item. */
  item: IContentItem;
}

/**
 * CubeBottom component is a small component with a background
 * image and some text with a button. Designed to fit under the CubeBlock.
 */
export const CubeBottom: FunctionComponent<ICubeBottomProps> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const imageModel = itemModel.image
    ? ContentImageModel.from(itemModel.image)
    : null;
  const { custom, settings } = itemModel;

  const { link, text } = itemModel;

  if (imageModel) {
    imageModel.aspectRatio = 'landscape_ratio7x1';
    imageModel.mobileAspectRatio = 'landscape_ratio2x1';
  }

  const overlay = getItemOverlay(settings);
  const styling = getCustomStyling(custom);
  const options = getContainerOptions(custom);
  return (
    <div className={S.container} style={styling}>
      <div className={S.block}>
        <div className={S.text}>
          {text && (
            <div className={overlay.text}>
              <FragmentParser string={text} />
            </div>
          )}
        </div>
        {link && (
          <div className={S.button}>
            <Link variant="text" href={link.href}>
              <Button size="lg" variant={overlay.cta as ButtonVariant}>
                {link.text}
              </Button>
            </Link>
          </div>
        )}
      </div>
      {imageModel && <ContentImage image={imageModel} />}
    </div>
  );
};
