import { CommonErrorBoundary } from '@/react/components/errors/boundaries/CommonErrorBoundary';
import { ContentItemModel, IContentItem } from '@/services/models/Content';
import classNames from 'classnames';
import { FC, FunctionComponent } from 'react';
import {
  getContainerOptions,
  getCustomStyling
} from '../../../utils/styling-utils';

import type INestableContent from '../../../traits/INestableContent';
import S from './styles.module.scss';

export interface ICollectionProps extends INestableContent {
  /** Individual content item with an items property. */
  item: IContentItem;
}

/**
 * The collection component, used to render a single collection.
 * This component is a generic one that holds subitems.
 * It can have a layout variant, but in that case this component will not
 * be used. Additional levers can be added on the studio end and will
 * need to be caught here.
 */
export const Collection: FunctionComponent<ICollectionProps> = ({
  item,
  contentResolver
}) => {
  const { custom } = ContentItemModel.from(item);
  const options = custom ? getContainerOptions(custom) : null;

  const style = getCustomStyling(custom);
  return (
    <div
      className={classNames(
        options?.containerClass ? S[options.containerClass] : ''
      )}
      style={style}
    >
      <CommonErrorBoundary>
        {item?.items?.map((subItem: IContentItem) => (
          <SubItem
            key={subItem.id}
            subItem={subItem}
            contentResolver={contentResolver}
          />
        ))}
      </CommonErrorBoundary>
    </div>
  );
};

interface ISubItemProps extends INestableContent {
  /** Individual content item. */
  subItem: IContentItem;
}

/**
 * The subitem component, used to render a single subitem of a collection.
 */
const SubItem: FC<ISubItemProps> = ({ subItem, contentResolver }) => {
  return <>{contentResolver(subItem)}</>;
};
