import { FunctionComponent } from 'react';

import S from './styles.module.scss';

export interface IProgressIndicatorProps {
  /** Current progress of the associated video element. */
  progress: number | undefined;
}

/**
 * Progress indicator component for a video element.
 * @param progress - Current progress of the video (0-1 value).
 * @returns ProgressIndicator component.
 */
export const ProgressIndicator: FunctionComponent<IProgressIndicatorProps> = ({
  progress
}) => {
  const size: number = 100;
  const stroke: number = 5;

  const center: number = size / 2;
  const radius: number = (size - stroke) / 2;
  const circumference: number = radius * 2 * Math.PI;
  const offset: number = progress ? progress * circumference : 0;

  return (
    <div className={S.container}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        className={S.progress}
      >
        <circle
          fill="none"
          stroke="white"
          strokeWidth={stroke}
          strokeDasharray={`${circumference}, ${circumference}`}
          strokeDashoffset={offset}
          cx={center}
          cy={center}
          r={radius}
        />
      </svg>
    </div>
  );
};
