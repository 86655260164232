'use client';

import NextImage from 'next/image';
import { FunctionComponent, PropsWithChildren, useMemo } from 'react';

import { IImage, ImageLayout, ImageModel } from '@/services/models/Media/Image';
import { Nullable } from '@/type-utils';
import {
  Breakpoint,
  Breakpoints,
  Default,
  MediaBreakpoint,
  MediaBreakpoints
} from '../Breakpoints';
import { reconstructChildren } from '../../../utils/children-utils';

/**
 * Image load callback. Gets passed a `result` object.
 */
export type OnLoad = (result: {
  /** Natural width of the picture. */
  naturalWidth: number;

  /** Natural height of the picture. */
  naturalHeight: number;
}) => void;

export interface ISourceProps {
  /** The breakpoint(s) for which this image should be visible. */
  media: MediaBreakpoint | MediaBreakpoints;

  /** The image. */
  image: IImage;
}

export interface IImgProps {
  /** The default image to show. */
  image: IImage;

  /**
   * A callback for when an image loads. Since this component uses `NextImage` and the `onLoad`
   * is a proxy for the `NextImage.onLoadingComplete`, and it fires for the following cases:
   *
   * 1. When the first ever image is loaded (the initial image matching the first render breakpoint).
   * 2. When the user resizes the screen, if the breakpoint changes, this causes a different image to mount
   * so this also triggers the event.
   * 3. When the breakpoint changes back, the old image gets mounted in again, and even though the image
   * is saved in memory, the event is still triggered.
   *
   * So basically, this event fires anytime the image changes for the user.
   */
  onLoad?: OnLoad;

  /**
   * NEXT Image layout.
   * @see https://nextjs.org/docs/api-reference/next/image#layout
   */
  layout: ImageLayout;
}

/**
 * A next.js version of the famous HTML `<picture>` tag.
 * Allows conditional CSS display of images based on {@link MediaBreakpoint}-s.
 * Currently uses {@link Breakpoints} under the hood.
 * Must be used with the {@link Source} and {@link Img} tag.
 *
 * @example ```tsx
 * <Picture>
 *   <Source media="phone" image={IImage} />
 *   <Source media="tablet" image={IImage} />
 *   <Img image={IImage} onLoad={(result) => {}} />
 * </Picture>
 * ```
 *
 * @throws If no `<Img>` element is present in children.
 * @throws If anything except `<Source>` and `<Img>` is present in children.
 * @throws If any of the elements doesn't have the required `image` prop.
 */
export const Picture: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const breakpoints = useMemo(() => {
    // Reconstruct the children so we can identify Img and Source elements
    const reconstructed = reconstructChildren(children);
    // First find the `<Img>` tag and grab the `onLoad` handler, if it has one.
    const defaultImg = reconstructed.find(({ type }) => type === Img);
    if (!defaultImg) {
      throw new Error('The <Picture> component is missing an <Img> child.');
    }

    const onLoad = defaultImg?.props?.onLoad;
    const layout = defaultImg?.props?.layout as Nullable<ImageLayout>;

    const breakpoints = reconstructed.map(({ key, name, type, props }) => {
      // Img and Source both have the required `image` prop.
      if (!props.image) {
        throw new Error(
          `The <Picture> component contains a child <${name}> without an image prop`
        );
      }

      const imageModel = ImageModel.from(props.image as IImage);
      const image = (
        <NextImage
          src={imageModel.src}
          alt={imageModel.alt}
          title={imageModel?.caption ?? ''}
          height={imageModel.height ?? undefined}
          width={imageModel.width ?? undefined}
          layout={layout ?? 'responsive'}
          onLoadingComplete={(onLoad as OnLoad) ?? undefined}
        />
      );

      if (type === Source) {
        return (
          <Breakpoint media={props.media as ISourceProps['media']} key={key}>
            {image}
          </Breakpoint>
        );
      }

      if (type === Img) {
        return <Default key={key}>{image}</Default>;
      }

      throw new Error(
        `The <Picture> component contains a <${name}>. Only <Source> and <Img> components are supported.`
      );
    });

    return breakpoints;
  }, [children]);

  return <Breakpoints>{breakpoints}</Breakpoints>;
};

/**
 * Provides an `IImage` for a specific `media` breakpoint
 * to the `<Picture>` component.
 */
export const Source: FunctionComponent<ISourceProps> = ({ media, image }) => {
  // The logic is implemented by the parent `<Picture>` component
  return null;
};

/**
 * Provides the default `IImage` if no other `<Source>`-s
 * match with their media breakpoints.
 */
export const Img: FunctionComponent<IImgProps> = ({ image }) => {
  // The logic is implemented by the parent `<Picture>` component
  return null;
};
