'use client';

/**
 * The events are only to initiate analytics events and are not interactive from the
 * user perspective. These should only be used to trigger an interaction element on the link.
 * For everything else the Button component should be used.
 */
/* eslint-disable jsx-a11y/no-static-element-interactions -- See above comment. */
/* eslint-disable jsx-a11y/anchor-is-valid -- See above comment. */

import NextLink from 'next/link';
import type { FC } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { useInteraction } from '../../../hooks/useInteraction';
import { Box } from '../Box';
import { ILinkProps } from './ILinkProps';

import S from './styles.module.scss';

/**
 * Get the core styling from the variant string.
 * @param variant - String representing a link styling type.
 * @returns Returns a className from the styling import.
 */
const getStylingFromVariant = (variant: string): string => {
  switch (variant) {
    case 'link':
      return S.link;
    case 'text':
      return S.text;
    case 'text-underline':
      return S.textUnderline;
    default:
      return '';
  }
};

/**
 * Customized next/link with analytics and composbale styling.
 */
export const Link: FC<ILinkProps> = ({
  href,
  className = '',
  passHref = false,
  variant = 'base',
  as = 'span',
  children,
  title,
  ariaLabel,
  ariaLabelledBy,
  openInNewTab,
  onClick,
  onKeyPress,
  interactionDetails,
  datatestID,
  id,
  style
}) => {
  const classString = classes(className, getStylingFromVariant(variant));

  const { onClickEvent, onKeyPressEvent } = useInteraction(
    onClick,
    onKeyPress,
    interactionDetails
  );

  if (href) {
    return (
      <NextLink
        href={href}
        passHref={passHref}
        id={id}
        style={style}
        className={classString}
        title={title}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        target={openInNewTab ? '_blank' : ''}
        rel={openInNewTab ? 'noopener' : ''}
        onClick={onClickEvent}
        onKeyUp={onKeyPressEvent}
        data-testid={datatestID}
      >
        {children}
      </NextLink>
    );
  }

  if (as || className) {
    return (
      <Box
        className={classString}
        as={as}
        title={title}
        ariaLabel={ariaLabel}
        onClick={onClickEvent}
        onKeyPress={onKeyPressEvent}
        data-testid={datatestID}
        style={style}
        id={id}
      >
        {children}
      </Box>
    );
  }

  return <>{children}</>;
};

export * from './ILinkProps';
