import { CSSProperties, FunctionComponent } from 'react';
import { msg } from '@/services/isomorphic/I18NService';
import { Link } from '../../../../core-ui/Link';
import {
  AspectRatioName,
  ContentImageModel
} from '@/services/models/Media/ContentImage';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { ContentImage } from '../../core/ContentImage';
import { getCustomStyling } from '../../../utils/styling-utils';

import S from './styles.module.scss';
import { general_soldOut } from "@/lang/__generated__/ahnu/general_soldOut";

export interface IOverlayImageGridProps {
  /** The content item. */
  item: IContentItem;
}

/** Takes a collection of images and overlays teaser title at bottom. */
export const OverlayImageGrid: FunctionComponent<IOverlayImageGridProps> = ({
  item
}) => {
  /** Fallback if items is empty. Can still display single item. */
  const teasers =
    item.items?.length ?? -1 > 0
      ? item.items?.map((item) => ContentItemModel.from(item))
      : [ContentItemModel.from(item)];

  const { custom } = item;
  const styling = getCustomStyling(custom);
  /** Defaults to Sold Out, but would like to make this customizable via custom. */
  const badgeText = custom?.badgeText ?? `${msg(general_soldOut)}`;

  return (
    <div
      className={S.grid}
      style={
        {
          ...styling,
          '--content': `${JSON.stringify(badgeText)}`
        } as CSSProperties
      }
    >
      {teasers?.map((teaser) => {
        const imageModel = ContentImageModel.from(teaser.image as any);
        imageModel.aspectRatio =
          (custom?.desktopAspectRatio as AspectRatioName) ??
          'portrait_ratio1x1';
        imageModel.mobileAspectRatio =
          (custom?.mobileAspectRatio as AspectRatioName) ?? 'portrait_ratio1x1';
        const hasLinks = teaser && teaser.links && teaser.links.length > 0;
        return (
          <Link
            as={hasLinks ? 'a' : 'div'}
            href={hasLinks ? teaser.links[0].href : undefined}
            key={teaser.id}
            className={`${S.item} ${!hasLinks ? `${S.badge}` : ''}`}
          >
            <ContentImage image={imageModel} />
            <div className={S.overlay}>
              <h3 className={S.title}>{teaser.title}</h3>
            </div>
          </Link>
        );
      })}
    </div>
  );
};
