import axios, { AxiosError } from 'axios';

import MemoryCache from '@/utils/MemoryCache';
import type { IMedia } from '@/services/models/Media';
import Service from '../../Service';
import ConfigurationService from '../ConfigurationService';
import LoggerService from '../LoggerService';

import CloudinaryService, {
  ICloudinaryTransformation
} from '../integrations/CloudinaryService';

import { ConfigModel } from '../../models/Config';
import type { IImage } from '../../models/Media/Image';
import type { IVideo } from '../../models/Media/Video';

import IMediaOptimizationOptions from './IMediaOptimizationOptions';
import ConfiguredMediaSize from './ConfiguredMediaSize';

/** Abstracts functionalities related to media such as images and videos. */
class MediaService extends Service {
  private imageAspectRatioCache = new MemoryCache<number>();

  /**
   * Find the smallest {@link ConfiguredMediaSize} that is greater than the specified width.
   * @param width - Desired render width. If absent, the largest configured size will be returned.
   * @returns The best matching {@link ConfiguredMediaSize}.
   */
  private findSmallestApplicableSize(width?: number): ConfiguredMediaSize {
    let newWidth: number | undefined;

    const sizeConfig = ConfigurationService.getConfig('media').getSetting(
      'imageSizes'
    ).value as Record<ConfiguredMediaSize, ConfigModel<number>>;

    // Get all configured sizes in a sorted array.
    const configuredSizes = Object.keys(
      sizeConfig
    ) as unknown as Array<ConfiguredMediaSize>;

    const sortedSizes = configuredSizes.sort(
      (a, b) => sizeConfig[a].value - sizeConfig[b].value
    );

    // If no width was specified, go with the largest configured size.
    if (!width) return sortedSizes[sortedSizes.length - 1];

    // Set newWidth to the smallest configured width greater or eaual to the original width.
    for (const size of sortedSizes) {
      if (this.getConfiguredSize(size) >= width) {
        newWidth = size;
        break;
      }
    }

    return newWidth ?? sortedSizes[sortedSizes.length - 1];
  }

  /**
   * Retreieves the actual width in pixels for a configured size.
   * @param configuredSize - The {@link ConfiguredMediaSize} to get the width for.
   * @returns The actual width in pixels.
   */
  private getConfiguredSize(configuredSize: ConfiguredMediaSize): number {
    const sizeConfig = ConfigurationService.getConfig('media').getSetting(
      'imageSizes'
    ).value as Record<ConfiguredMediaSize, ConfigModel<number>>;

    return sizeConfig[configuredSize].value;
  }

  /**
   * Create a new, optimized version of the supplied media item. This will also generate and
   * include an optimized `blurURL` for the image.
   *
   * @param media - The image or video to optimize.
   * @param options - Optimization options. It is recommended to at least include `width`.
   *
   * @returns A new {@link IImage} representing the optimized version of the supplied image.
   *
   * **NOTE:** Optimizing non-Cloudinary images is not supported at the moment. If this method
   * is passed an image with a non-Cloudinary `src` URL, it will return the original image.
   */
  public optimizeMedia(
    media: IMedia,
    options: IMediaOptimizationOptions = {}
  ): IMedia {
    const { src } = media;
    const {
      width,
      size,
      quality,
      format,
      aspectRatio,
      gravity,
      omitNamedTransformation = false
    } = options;

    // The configured size to use will be...
    const configuredSize =
      // ...either the supplied size if present...
      size ??
      // ...or the smallest configured size that applies to this display width...
      this.findSmallestApplicableSize(width ?? media.width ?? undefined);

    const newWidth = this.getConfiguredSize(configuredSize);

    // Try to parse this image as a Cloudinary Asset.
    const cloudinaryAsset = CloudinaryService.tryParse(src);

    if (cloudinaryAsset) {
      const isVideo = cloudinaryAsset.assetType === 'video';

      // First, get rid of the extension and the transformations that will be applied.
      delete cloudinaryAsset.extension;
      const newAsset = CloudinaryService.removeTransformations(
        cloudinaryAsset,
        'quality',
        'format',
        'dpr',
        'crop',
        'width',
        'gravity',
        'aspectRatio'
      );

      if (!newAsset.transformations) newAsset.transformations = [];

      const configuredSizeTransformations = ConfigurationService.getConfig(
        'cloudinary'
      ).getSetting('configuredSizeTransformations') as Record<
        ConfiguredMediaSize,
        ConfigModel<string>
      >;

      const transformationToApply =
        configuredSizeTransformations[configuredSize].value;

      const namedTransformation = omitNamedTransformation
        ? []
        : [{ namedTransformation: transformationToApply }];

      const aspectRatioTransform = aspectRatio ? { aspectRatio } : {};
      const widthTransform = width && omitNamedTransformation ? { width } : {};
      const gravityTransorm = gravity ? { gravity } : {};

      const fit = isVideo ? null : options.fit ?? 'fill';
      const cropTransform = fit
        ? { crop: CloudinaryService.fitToCrop(fit) }
        : {};

      // This is the transformation that will be applied to the optimized image.
      const optimizationTransformations: Array<ICloudinaryTransformation> = [
        ...namedTransformation,
        {
          quality: quality ?? 'auto',
          format: format ?? 'auto',
          dpr: 'auto',
          ...cropTransform,
          ...aspectRatioTransform,
          ...gravityTransorm,
          ...widthTransform
        }
      ];

      // This other transformation will be applied to the new blur image.
      const blurTransformations: Array<ICloudinaryTransformation> = [
        { namedTransformation: 'blur_placeholder' },
        {
          quality: quality ?? 'auto',
          format: format ?? 'auto',
          dpr: 'auto',
          ...cropTransform
        }
      ];

      // Make the main src by spreading the optimization transformation over
      // the new asset.
      const src = CloudinaryService.assetToURL({
        ...newAsset,
        transformations: [
          ...newAsset.transformations,
          ...optimizationTransformations
        ]
      }).toString();

      // Make the blur src by spreading the blur transformation over
      // the new asset.
      const blurURL =
        newAsset.assetType === 'image'
          ? [
              CloudinaryService.assetToURL({
                ...newAsset,
                transformations: [
                  ...newAsset.transformations,
                  ...blurTransformations
                ]
              }).toString()
            ]
          : [];

      return {
        ...media,
        ...blurURL,
        src,
        // If no new width was calculated, use the media's original width.
        width: newWidth ?? media.width
      };
    }

    LoggerService.warn(
      'Optimizing non-Cloudinary images is not supported at the moment. Returning original image.'
    );

    return media;
  }

  /**
   * Report an error that occured when trying to display/utilize the supplied image.
   * If the error is not supplied, `MediaService` will try to find out more about it.
   *
   * @param media - The image or video that caused the error.
   * @param [error] - The error caused.
   */
  public async reportMediaError(
    media: IImage | IVideo,
    error?: Error
  ): Promise<void> {
    // If the actual error was supplied, log immediately.
    if (error) {
      LoggerService.error(
        new Error(
          `An error occurred when trying to display Cloudinary image with src "${media.src}".`,
          { cause: error }
        )
      );

      return;
    }

    // If not, make a HEAD request to find out more about the nature of the error.
    try {
      const response = await axios.head(media.src);

      // Fetch was okay, try to find error headers.

      const headers = response.headers as Record<string, unknown>;

      if (headers?.['x-cld-error']) {
        // Cloudinary error header present. Log accordingly.
        LoggerService.error(
          `An error occurred when trying to display Cloudinary image with src "${media.src}": ${headers?.['x-cld-error']}`
        );
      } else {
        // Nothing else to check.
        LoggerService.error(
          `An unknown error occurred when trying to display image with src "${media.src}".`
        );
      }
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        // If this is an axios error, log relevant information.
        const headers = axiosError.response.headers as Record<string, unknown>;

        if (headers?.['x-cld-error']) {
          // Cloudinary error header present. Log accordingly.
          LoggerService.error(
            `An error occurred when trying to display Cloudinary image with src "${media.src}": ${headers?.['x-cld-error']}`
          );
        } else {
          // Nothing else to check.
          LoggerService.error(
            `An unknown error occurred when trying to display image with src "${media.src}".`
          );
        }
      } else {
        // If something else, rethrow.
        throw error;
      }
    }
  }

  /**
   * Get the aspect ratio of the supplied image.
   * @param image - The image to get the aspect ratio of.
   * @returns The aspect ratio of the image.
   */
  public async getImageAspectRatio(image: IImage): Promise<number> {
    if (this.imageAspectRatioCache.has(image.src)) {
      return this.imageAspectRatioCache.get(image.src);
    }

    // Get the `probe-image-size` module.
    const imageProbe = (await import('probe-image-size')).default;
    // Probe the image source URL for its dimensions.
    const result = await imageProbe(image.src);
    const aspectRatio = result.width / result.height;
    this.imageAspectRatioCache.add(image.src, aspectRatio);
    // Return the aspect ratio as a result of the width divided by the height.
    return aspectRatio;
  }
}

export default new MediaService();
