'use client';

import { useState, useCallback, useEffect, useContext } from 'react';
import Swiper from 'swiper/types/swiper-class';
import { VirtualOptions } from 'swiper/types/components/virtual';
import { Nullable } from '@/type-utils';

import { SliderContext } from './context';
import { ISliderOptions, SliderDirection } from '../ISliderOptions';

/**
 * Slider controller abstraction of the underline slider controller instance.
 *
 * The abstraction should be done in the useSlider hook, to wrap any
 * third party library to comply with this interface, thus leaving
 * navigation / pagination unaffected.
 */
export interface ISliderController {
  /** Goes to the next slide. */
  next: () => void;

  /** Goes to the previous slide. */
  previous: () => void;

  /**
   * Goes to a specific slide.
   * @param index - Index of the slide, starts from zero.
   */
  goto: (index: number) => void;

  /** Toggles the autoplay. */
  toggleAutoplay: () => void;
}

/**
 * Abstracts the state of the slider.
 */
export interface ISliderState {
  /** Direction of the slider. */
  direction: SliderDirection;

  /** Whether loop is on. */
  loop: boolean;

  /** Index of the current slide. */
  current: number;

  /** Number of total slides. */
  total: number;

  /** Wether autoplay is running (Not wether it is enabled). */
  autoplayRunning: boolean;

  /** If there is a previous slide. (Always true in loop mode). */
  hasPrevious: boolean;

  /** If there is a next slide. (Always true in loop mode). */
  hasNext: boolean;
}

/**
 * Interface of the useReactiveSlider hook.
 */
export interface IReactiveSlider {
  /** The underlying slider instance (Swiper in the current implementation). */
  slider: Nullable<Swiper>;

  /**
   * Function that should be called when the swiper instance is retrieved.
   * @param slider - The swiper instance given to the onSwiper event.
   */
  onSlider: (slider: Swiper) => void;

  /** Last time the slider was updated (Used for re-rendering). */
  lastUpdated: number;
}

/**
 * Returns a reactive version of the swiper instance, which
 * listens to events and updates on slide changes.
 *
 * The onSlider returned by this hook has to be called
 * after the slider instance is initialized (in this case Swiper instance).
 *
 * This is only used by the Slider component to grab the swiper
 * and provide it using the SliderContext.
 * @returns A reactive version of the swiper instance.
 */
export const useReactiveSlider = (): IReactiveSlider => {
  const [slider, setSlider] = useState<Nullable<Swiper>>(null);
  /**
   * I'm not sure if this is stupid, but this was the only way to force
   * a re-render when the slider object gets updated
   * Since the slider object is actually an instance somewhere in memory
   * and the only way to make it Reactive is to setState on some variable
   * to force a re-render
   * Could also be a nonce, or a counter instead of date.
   */
  const [lastUpdated, setLastUpdated] = useState(Date.now());

  /**
   * There are 2 ways to make Swiper reactive.
   *
   * 1. Use swiper.onAny() to capture every event and update the state
   * 2. Handpick important events that we care about and update only for them.
   *
   * 1. Is obviously more absolute, however more exhaustive since swiper fires
   * a lot of events.
   *
   * 2. Might cause swiper not to be updated when we actually want it to be
   * updated.
   *
   * I'm going with number 1 and see if there are some performance issues.
   */
  const onUpdate = useCallback(() => {
    setLastUpdated(Date.now());
  }, [slider]);

  const onSlider = useCallback(
    (slider: Swiper) => {
      if (slider) {
        slider.onAny(onUpdate);
      }
      setSlider(slider);
    },
    [slider]
  );

  useEffect(() => {
    return () => {
      if (slider) {
        slider.offAny(onUpdate);
      }
    };
  }, [slider]);

  return {
    slider,
    onSlider,
    lastUpdated
  };
};

/**
 * An abstraction for controlling the slider
 * Any method that works with the slider should be abstracted here
 * to keep the real slider implementation hidden from the components.
 * @returns The slider controller object that can be used to manipulate the slider.
 */
export const useSliderController = (): ISliderController => {
  const { slider } = useContext(SliderContext) ?? {};

  /**
   * The below code is commented out to show bad practice
   * Returning before the useCallback is a bad idea since
   * once slider gets defined in the context tree, useCallbacks
   * will run, meaning a different hook order, which will cause bugs.
   *
   * Better to check if slider is defined in the callbacks and keep
   * everything tight.
   */
  // if (!slider) {
  //   return null;
  // }

  const next = useCallback(() => {
    slider?.slideNext();
  }, [slider]);

  const previous = useCallback(() => {
    slider?.slidePrev();
  }, [slider]);

  /* Going to a specific slide index is different if we're looping */
  const goto = useCallback(
    (index: number) => {
      if (!slider?.params?.slidesPerGroup || !slider?.loopedSlides) {
        slider?.slideTo(index);
        return;
      }

      let realIndex = index;

      /* Taken from the Swiper pagination */
      realIndex *= slider.params.slidesPerGroup;
      if (slider.params.loop) {
        realIndex += slider.loopedSlides;
      }

      slider.slideTo(realIndex);
    },
    [slider]
  );

  const toggleAutoplay = useCallback(() => {
    if (!slider?.autoplay) return;

    if (slider.autoplay.running) {
      slider.autoplay.stop();
    } else {
      slider.autoplay.start();
    }
  }, [slider]);

  return {
    next,
    previous,
    goto,
    toggleAutoplay
  };
};

/**
 * A little helper to get the total slides from swiper.
 * The logic for determining this is taken from the swiper pagination module.
 *
 * @param swiper - The swiper instance.
 * @returns The total slides in the swiper.
 */
const getTotalSlides = (swiper: Swiper): number => {
  const { params } = swiper;

  /**
   * The .enabled is part of the swiper.params.virtual object (if present)
   * So let's add it.
   */
  type VirtualOptionsEnabled = VirtualOptions & {
    enabled?: boolean;
  };

  const virtual = params.virtual
    ? (params.virtual as VirtualOptionsEnabled)
    : null;

  const slidesN =
    virtual?.enabled && virtual.slides
      ? virtual.slides.length
      : swiper.slides.length;

  if (swiper.params.loop) {
    return Math.ceil(
      (slidesN - (swiper.loopedSlides ?? 0) * 2) / (params.slidesPerGroup ?? 1)
    );
  }

  /**
   * The snapGrid is not part of the Swiper typescript definition
   * however it is added in the Swiper instance. So we can be quite aggresive
   * with typescript on this one.
   */
  type IShouldGetFired = Swiper & {
    /** SnapGrid is an array that gets added to the swiper instance. */
    snapGrid: Array<unknown>;
  };

  return (swiper as IShouldGetFired)?.snapGrid.length || 0;
};

/**
 * An abstraction for the slider state
 * Returns variables that describe the current state of the slider.
 * @returns The state of the slider up the context tree.
 * @throws An error if the slider context cannot be obtained.
 */
export const useSliderState = (): ISliderState => {
  const { slider } = useContext(SliderContext) ?? {};

  if (!slider) {
    throw new Error(
      'Unable to get slider for `useSliderState` because `slider` was undefined.'
    );
  }

  const { isBeginning, isEnd, realIndex } = slider;

  const isLoop = slider.params.loop;
  const total = getTotalSlides(slider);

  return {
    direction: slider.params.direction ?? 'horizontal',
    loop: isLoop ?? false,
    current: realIndex,
    total,
    autoplayRunning: slider.autoplay.running,
    hasPrevious: isLoop ? true : !isBeginning,
    hasNext: isLoop ? true : !isEnd
  };
};

/**
 * Sometimes it's just useful to know the options that the Slider works with
 * For example, for the navigation and pagination components.
 * @returns The options of the slider up the context tree.
 */
export const useSliderOptions = (): ISliderOptions => {
  return useContext(SliderContext)?.options as ISliderOptions;
};
