'use client';

// This file needs to be a client component since it passes functions to
// other client components.

import { FunctionComponent, ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { msgf } from '@/services/isomorphic/I18NService';
import type { IContentItem, IPlacement } from '@/services/models/Content';

import { ContentItemModel } from '@/services/models/Content';
import { FragmentParser } from '../../utility/FragmentParser';
import { ContentAccordion } from '../components/core/ContentAccordion';
import {
  OffsetOverlay,
  OffsetOverlayVariant
} from '../components/main/OffsetOverlay';
/** External Components */

/** Core components */
import { Collection } from '../components/core/Collection';
import { ContentBlock } from '../components/core/ContentBlock';
import { ContentMedia } from '../components/core/ContentMedia';
import { TextBlock, TextBlockVariant } from '../components/core/TextBlock';

/** Main components */
import { CategoryBanner } from '../components/main/CategoryBanner';
import { CubeBlock } from '../components/main/CubeBlock';
import { FiveImageSlider } from '../components/main/FiveImageSlider';
import { FullWidth } from '../components/main/FullWidth';
import { HeroBanner } from '../components/main/HeroBanner';
import { HeroCollection } from '../components/main/HeroCollection';
import { HeroSlider } from '../components/main/HeroSlider';
import { ImageSequenceRolling } from '../components/main/ImageSequenceRolling';
import { ImageSlider } from '../components/main/ImageSlider';
import { LinkList } from '../components/main/LinkList';
import { LinkedMedia } from '../components/main/LinkedMedia';
import { MultiItemSlider } from '../components/main/MultiItemSlider';
import { OverlaidNavigationSlider } from '../components/main/OverlaidNavigationSlider';
import { OverlayContent } from '../components/main/OverlayContent';
import { OverlayImageGrid } from '../components/main/OverlayImageGrid';
import { PdpSlider } from '../components/main/PdpSlider';
import { PdpSliderLifestyle } from '../components/main/PdpSliderLifestyle';
import { PlpSlider } from '../components/main/PlpSlider';
import { Row } from '../components/main/Row';
import { SideBySide } from '../components/main/SideBySide';
import { Slider } from '../components/main/Slider';
import { SplitBanner } from '../components/main/SplitBanner';
import { SwappableImage } from '../components/main/SwappableImage';
import { ZoomTile } from '../components/main/ZoomTile';
/** Tiles components. */
import { SplitTile } from '../components/tiles/SplitTile';
/** Navigation. */
import { CommonErrorBoundary } from '../../errors/boundaries/CommonErrorBoundary';
import { MarketingBanner } from '../components/navigation/MarketingBanner';
import { content_errors_viewTypeNotFound } from "@/lang/__generated__/ahnu/content_errors_viewTypeNotFound";

/**
 * Gets the component or components for a given piece of content.
 * @param item - {@link IContentItem} To generate the content.
 * @returns Returns the component or components for this item.
 */
export const getComponent = (item: IContentItem): ReactElement => {
  const itemModel = ContentItemModel.from(item);
  const { viewType } = itemModel;

  switch (viewType) {
    case 'html-fragment':
      return <FragmentParser string={item.html ?? ''} key={item.id} />;
    case 'hero-banner':
      return <HeroBanner item={item} key={item.id} />;
    case 'hero-collection':
      return (
        <HeroCollection
          item={item}
          key={item.id}
          contentResolver={getComponents}
        />
      );
    case 'split-banner':
      return (
        <SplitBanner
          item={item}
          key={item.id}
          contentResolver={getComponents}
        />
      );
    case 'text-banner':
      return (
        <MarketingBanner
          item={item}
          key={item.id}
          contentResolver={getComponents}
        />
      );
    case 'cat-banner':
      return <CategoryBanner item={item} key={item.id} />;
    case 'link-list':
      return <LinkList item={item} key={item.id} />;
    case 'linked-media':
      return <LinkedMedia item={item} />;
    case 'cube-block':
      return <CubeBlock item={item} key={item.id} />;
    case 'zoom-tile':
      return <ZoomTile item={item} key={item.id} />;
    case 'full-width':
      return <FullWidth item={item} key={item.id} />;
    case 'side-by-side':
      return <SideBySide item={item} key={item.id} />;
    // Sliders
    case 'multi-item-slider':
      return (
        <MultiItemSlider
          item={item}
          key={item.id}
          contentResolver={getComponents}
        />
      );
    case 'offset-overlay':
    case 'offset-overlay-parallax':
      return (
        <OffsetOverlay
          item={item}
          key={item.id}
          variant={
            viewType === 'offset-overlay'
              ? 'default'
              : (viewType.slice(15) as OffsetOverlayVariant)
          }
          contentResolver={getComponents}
        />
      );
    case 'overlay-content':
      return (
        <OverlayContent
          item={item}
          key={uuidv4()}
          contentResolver={getComponents}
        />
      );
    case 'accordion-text':
    case 'accordion-collection':
      return (
        <ContentAccordion
          item={item}
          key={item.id}
          contentResolver={getComponents}
          variant={viewType.slice(10) as 'text' | 'collection'}
        />
      );
    case 'five-image-slider':
      return <FiveImageSlider item={item} key={item.id} />;
    case 'image-slider':
      return <ImageSlider item={item} key={item.id} />;
    case 'image-sequence-rolling':
      return <ImageSequenceRolling item={item} key={item.id} />;
    case 'overlaid-navigation-slider':
      return <OverlaidNavigationSlider item={item} key={item.id} />;
    case 'pdp-slider':
      return (
        <PdpSlider item={item} key={item.id} contentResolver={getComponents} />
      );
    case 'pdp-slider-lifestyle':
      return <PdpSliderLifestyle item={item} key={item.id} />;
    case 'plp-slider':
      return <PlpSlider item={item} key={item.id} />;
    case 'slider-full-width':
    case 'slider-no-control':
    case 'slider':
      // Slice off the `slider-` prefix to extract the variant (homepage, full-width, etc)
      return (
        <Slider
          item={item}
          key={item.id}
          variant={viewType === 'slider' ? 'default' : viewType.slice(7)}
          contentResolver={getComponents}
        />
      );
    case 'swappable-image':
      return <SwappableImage item={item} key={item.id} />;
    case 'bottom-content-slim':
      return (
        <HeroBanner item={item} key={item.id} variant="bottom-content-slim" />
      );
    case 'bottom-banner-overlay':
      return (
        <HeroBanner item={item} key={item.id} variant="bottom-banner-overlay" />
      );
    case 'split-tile':
      return <SplitTile item={item} key={item.id} />;
    case 'hero-slider':
      return <HeroSlider item={item} key={item.id} />;
    case 'text-block':
    case 'text-block-overlay-mobile':
    case 'text-block-scroll-effect':
      // Slice off the `text-block-` prefix to extract the variant
      // (eg. scroll-effect)
      return (
        <TextBlock
          item={item}
          key={item.id}
          variant={
            viewType.length > 10
              ? (viewType.slice(11) as TextBlockVariant)
              : ('default' as TextBlockVariant)
          }
        />
      );
    case 'marketing-banner':
      return (
        <MarketingBanner
          item={item}
          key={item.id}
          contentResolver={getComponents}
        />
      );
    case 'overlay-image-grid':
      return <OverlayImageGrid item={item} key={item.id} />;
    case 'content-block':
      return <ContentBlock item={item} key={item.id} />;
    case 'content-block-overlay':
      return <ContentBlock item={item} key={item.id} variant="overlay" />;
    case 'row':
      return <ContentBlock item={item} key={item.id} variant="row" />;
    case 'accordion-button':
      return (
        <ContentBlock item={item} key={item.id} variant="accordion-button" />
      );
    case 'content-media':
      return <ContentMedia item={item} key={item.id} />;
    case 'collection-row':
      return <Row item={item} key={item.id} contentResolver={getComponents} />;

    /**
     * Viewtype null, means that a viewtype has not been selected, which generally
     * means it is a collection that contains further items. Include collection so it can be passed
     * to the content resolver.
     */
    case 'collection':
    case null:
      return (
        <Collection item={item} key={item.id} contentResolver={getComponents} />
      );

    /**
     * TODO: Add an error-boundary component to handle for cases like this.
     * Currently this is a development aid for when building a new template
     * or replacing a composable one. This default means that field name does
     * not appear in this switch statement.
     */
    default:
      return (
        <div style={{ margin: '20px auto', textAlign: 'center' }}>
          {msgf(content_errors_viewTypeNotFound, { viewType: viewType! })}
        </div>
      );
  }
};

/**
 * Matches a {@link IContentItem} to its corresponding component. Will accept an arbitrary
 * number of items and return an array of components for each item. This is useful for
 * when you have a collection of items that need to be rendered.
 * @param items - An array of {@link IContentItem}s to be rendered.
 * @returns An array of components that match the input items.
 */
export const getComponents = (
  ...items: Array<IContentItem>
): Array<ReactElement> => {
  return items.map((item) => getComponent(item));
};

export interface IGenericItemProps {
  /** A single ContentItem that represents one piece of content. */
  item: IContentItem;
}

/**
 * Gets the component for a single item as opposed to a grouping of them.
 * This is mostly used by the GenericContent component. In theory, levers could
 * be added here based on what is in the studio. These would live in the
 * custom property of the item.
 */
export const GenericItem: FunctionComponent<IGenericItemProps> = ({ item }) => {
  return <>{getComponent(item)}</>;
};

export interface IGenericContentProps {
  /**
   * A collection of content items. Each one represents a collection of data
   * that can be rendered as a single piece of content. They can be anything,
   * but take a specific shape so the corresponding templates know how to handle
   * them.
   */
  placement: IPlacement;
}

/**
 * Loops over a placements, named groupings of ContentItems that
 * exist at the top level of a page, and renders them as a component.
 */
export const GenericContent: FunctionComponent<IGenericContentProps> = ({
  placement
}) => {
  return (
    <CommonErrorBoundary>
      {placement?.items?.map((item, index) => (
        /* eslint-disable-next-line react/no-array-index-key
          -- The order of the items is not expected to change.
          Hence, using the index for the key should not cause data-binding issues. */
        <GenericItem key={index} item={item} />
      ))}
    </CommonErrorBoundary>
  );
};
