'use client';

import React, { FunctionComponent, useMemo, useCallback, useRef } from 'react';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { AspectRatioName } from '@/services/models/Media/ContentImage';
import { Slider, Slide, ISliderProps } from '../../../../Slider';
import { TextBlock } from '../../core/TextBlock';
import { ImageBlock } from '../../core/ImageBlock';
import { getCustomStyling, getItemOverlay } from '../../..';
import {
  Breakpoints,
  Breakpoint,
  Default
} from '../../../../core-ui/Breakpoints';
import S from './styles.module.scss';

export interface IPLPSliderProps {
  /**
   * The {@link IContentItem} from which the width and height data and image
   * sources are extracted from to render the lifestyle slides.
   */
  item: IContentItem;
}
const DEFAULT_ASPECT_RATIO = 'landscape_ratio4x3' as AspectRatioName;
const DEFAULT_MOBILE_ASPECT_RATIO = 'landscape_ratio2x1' as AspectRatioName;
/**
 * A slider intended to show product lifestyle images on the PLP.
 */
export const PlpSlider: FunctionComponent<IPLPSliderProps> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const { settings, custom, items } = itemModel;
  const { headline: headlineStyle, text: textStyle } = getItemOverlay(settings);
  const styling = getCustomStyling(custom);

  const sliderProps = {
    direction: 'horizontal',
    loop: true,
    centeredSlides: true,
    navigation: false,
    pagination: {
      inset: false,
      buttonClassName: S.paginationButton,
      activeClassName: S.paginationButtonActive
    },
    autoplay: true,
    height: 'auto'
  } as ISliderProps;

  /**
   * Generates slides for the slider when slidesPerView changes.
   * @returns {@link Slide} - Array of slides to be rendered in the slider.
   */
  const slides = useMemo(() => {
    return items?.map((item, index) => {
      const key = `${item.id}_${index}`;
      const itemModel = ContentItemModel.from(item);
      const { image, images, custom } = itemModel;

      const {
        mainAspectRatio = DEFAULT_ASPECT_RATIO,
        mobileAspectRatio = DEFAULT_MOBILE_ASPECT_RATIO
      } = custom as Record<string, AspectRatioName>;

      const { order = 0 } = custom as Record<string, number>;

      if (!image || !images || images.every((image: any) => !image)) {
        return null;
      }
      images?.forEach((image) => {
        image.mobileAspectRatio = mobileAspectRatio;
        image.aspectRatio = mainAspectRatio;
      });
      const cls = order ? S.flipped : '';
      return items.length === 1 ? (
        <div className={`${S.slideContent} ${cls}`} key={item.id}>
          <div className={S.slideText}>
            <TextBlock className={S.slideText} item={item} />
          </div>
          <div className={`${S.slideImage}`}>
            <Breakpoints>
              <Breakpoint media="phone">
                <ImageBlock image={images?.[0] ?? image} />
              </Breakpoint>
              <Default>
                <ImageBlock image={images?.[1] ?? image} />
              </Default>
            </Breakpoints>
          </div>
        </div>
      ) : (
        <Slide key={key}>
          <div className={`${S.slideContent} ${cls}`}>
            <div className={S.slideText}>
              <TextBlock className={S.slideText} item={item} />
            </div>
            <div className={`${S.slideImage}`}>
              <Breakpoints>
                <Breakpoint media="phone">
                  <ImageBlock image={images?.[0] ?? image} />
                </Breakpoint>
                <Default>
                  <ImageBlock image={images?.[1] ?? image} />
                </Default>
              </Breakpoints>
            </div>
          </div>
        </Slide>
      );
    });
  }, []);

  return (
    <div className={S.contentContainer}>
      {items?.length === 1 ? (
        [slides]
      ) : (
        <Slider
          key={`${item.id}_slider`}
          {...sliderProps}
          initialSlide={0}
          slidesPerView={1}
          sliderClassName={S.sliderContainer}
          style={styling}
        >
          {slides}
        </Slider>
      )}
    </div>
  );
};
