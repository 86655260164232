'use client';

import { FunctionComponent, useId } from 'react';

import { AspectRatioName } from '@/services/models/Media/ContentImage';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { Link } from '@/react/components/core-ui/Link';

import { ISliderProps, Slider, Slide } from '../../../../Slider';
import { ContentMedia } from '../../core/ContentMedia';

import {
  Breakpoint,
  Breakpoints,
  Default
} from '../../../../core-ui/Breakpoints';

import { WithAnchorScroll } from '../../../../core-ui/Anchor/WithAnchorScroll';
import { ContentImage } from '../../core/ContentImage';

import S from './styles.base.module.scss';

const DEFAULT_DESKTOP_RATIO: AspectRatioName = 'landscape_ratio8x3';
const DEFAULT_MOBILE_RATIO: AspectRatioName = 'portrait_ratio1x1';

export interface ICategoryBannerProps {
  /** The content item. */
  item: IContentItem;
}

/**
 * Category banner takes one or more teasers as children and displays
 * them as a carousel banner.
 *
 * Category banner should have teaser objects as children, which contain two images:
 * Images[0] = Image for desktop.
 * Images[1] = Image for mobile.
 */
export const CategoryBanner: FunctionComponent<ICategoryBannerProps> = ({
  item
}) => {
  const sliderId = useId();
  const itemModel = ContentItemModel.from(item);
  const { custom, items } = itemModel;

  const {
    mainAspectRatio = DEFAULT_DESKTOP_RATIO,
    mobileAspectRatio = DEFAULT_MOBILE_RATIO
  } = custom as Record<string, AspectRatioName>;

  const sliderProps = useBrandLocaleValue<ISliderProps>(
    () => ({
      default: {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          loop: true,
          delay: 4000
        },
        centeredSlides: false,
        navigation: {
          inset: true,
          arrowVariant: 'arrow',
          buttonClassName: S.navigation
        },
        height: 'auto'
      },
      AHNU: {
        height: 'auto',
        loop: true,
        navigation: {
          inset: true,
          arrowVariant: 'angleLight',
          buttonClassName: S.navigation
        },
        watchOverflow: true,
        wrapperClassName: S.wrapper,
        navigationWrapperClassName: S.navigationWrapper,
        pagination: {
          inset: false,
          activeClassName: S.activeCircle,
          wrapperClassName: S.pagination,
          buttonClassName: S.circle,
          direction: 'horizontal',
          clickable: true
        }
      }
    }),
    []
  );
  if (!items || items.length === 0) {
    return null;
  }

  // Update the images to force an aspect ratio
  for (const item of items) {
    if (item.images && item.images.length > 0) {
      item.images.forEach((image) => {
        image.aspectRatio = mainAspectRatio;
        image.mobileAspectRatio = mobileAspectRatio;
      });
    }

    if (item.videos && item.videos.length > 0) {
      item.videos[0].aspectRatio = mainAspectRatio;
      if (item.videos[1]) {
        item.videos[1].aspectRatio = mobileAspectRatio;
      }
    }
  }

  if (items.length === 1) {
    const images = items[0].images ?? [];
    images.forEach((image) => {
      image.aspectRatio = mainAspectRatio;

      image.mobileAspectRatio = mobileAspectRatio;
    });
    const videos = items[0].videos ?? [];
    const media = [...videos, ...images];
    if (media.length === 0) {
      return null;
    }
    return (
      <div className={S.wrapper}>
        <Breakpoints>
          <Breakpoint media="phone">
            <WithAnchorScroll>
              <ContentMedia item={media[1] ?? media[0]} />
            </WithAnchorScroll>
          </Breakpoint>
          <Default>
            <WithAnchorScroll>
              <ContentMedia item={media[0]} />
            </WithAnchorScroll>
          </Default>
        </Breakpoints>
      </div>
    );
  }

  return (
    <Slider key={sliderId} {...sliderProps} initialSlide={0} slidesPerView={1}>
      {items.map((item) => {
        const images = item.images ?? [];
        if (images.length === 0) {
          return null;
        }
        const { link } = item;
        return (
          <Slide key={item.id}>
            <Breakpoints>
              <Breakpoint media="phone">
                {link ? (
                  <Link href={link.href}>
                    <ContentImage image={images[1] ?? images[0]} />
                  </Link>
                ) : (
                  <WithAnchorScroll>
                    <ContentImage image={images[1] ?? images[0]} />
                  </WithAnchorScroll>
                )}
              </Breakpoint>
              <Default>
                {link ? (
                  <Link href={link.href}>
                    <ContentImage image={images[0]} />
                  </Link>
                ) : (
                  <WithAnchorScroll>
                    <ContentImage image={images[0]} />
                  </WithAnchorScroll>
                )}
              </Default>
            </Breakpoints>
          </Slide>
        );
      })}
    </Slider>
  );
};
