import {
  AspectRatioNameList,
  ContentImageModel,
  IContentImage
} from '@/services/models/Media/ContentImage';
import { Nullable } from '@/type-utils';

/**
 * Override aspect ratios from teaser. This method should modify
 * the content image so that it takes in overrides for aspect ratio
 * and mobile aspect ratio.
 * @param image - A content image to be modified.
 * @param custom - The custom property off of a content item.
 */
export const setAspectRatioOverrides = (
  image: IContentImage,
  custom: Nullable<Record<string, unknown>>
): void => {
  const imageModel = ContentImageModel.from(image);

  const assignAspectRatio = (
    customRatio: unknown,
    isMobileRatio: boolean = false
  ): void => {
    if (customRatio && typeof customRatio === 'string') {
      /**
       * This is a type guard to make sure the string we get from Coremedia is a real aspect ratio.
       */
      const aspectRatio = AspectRatioNameList.find(
        (ratio) => ratio === customRatio
      );

      if (aspectRatio) {
        if (isMobileRatio) {
          imageModel.mobileAspectRatio = aspectRatio;
        } else {
          imageModel.aspectRatio = aspectRatio;
        }
      }
    }
  };

  // Attempts to assign the optional override to the main aspect ratio on the image model.
  const mainAspectRatio = custom?.mainAspectRatio;
  assignAspectRatio(mainAspectRatio);

  // Attempts to assign the optional override to the mobile aspect ratio on the image model.
  const mobileAspectRatio = custom?.mobileAspectRatio;
  assignAspectRatio(mobileAspectRatio, true);
};
