'use client';

import React, {
  FC,
  PropsWithChildren,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { IVideo, VideoModel } from '@/services/models/Media/Video';

/**
 * Basic ref type.
 */
type MediaPlayerRef = RefObject<HTMLVideoElement> | null;

/**
 * The media player context. This describes what the MediaPlayerProvider
 * will provide to its children.
 */
interface IMediaPlayerContext {
  /**
   * The video ref. Pass this ref around to other components.
   * This will allow them to control the video.
   */
  ref?: MediaPlayerRef;
  /**
   * Playing state.
   */
  playing?: boolean | null;
  /**
   * Muted state.
   */
  muted?: boolean;
  /**
   * Fullscreen state.
   */
  fullscreen: boolean;
  /**
   * Current playback progress of video.
   */
  progress?: number;
  /**
   * Handle pause. Use this to trigger a paused state.
   */
  handlePause?: () => void;
  /**
   * Handle play. Use this to trigger a play state.
   * Will update whenever the component is mounted.
   */
  handlePlay?: () => void;
  /**
   * Handle fullscreen. Use this to trigger a fullscreen state.
   * @param fullscreen - The current fullscreen state.
   * @returns Promise.
   */
  handleFullscreen?: (fullscreen: boolean) => Promise<void>;
  /**
   * Loading state.
   */
  loading?: boolean;
  /**
   * Handle Load. Use this to trigger a loading state.
   */
  handleLoad?: () => void;
  /**
   * Handle model change. You can pass in a new video model.
   * @param model
   */
  handleModel?: (model: IVideo) => void;
}

/**
 * The media player context.
 */
const mediaPlayerContext = createContext<IMediaPlayerContext>({
  playing: false,
  muted: false,
  loading: true,
  fullscreen: false,
  progress: 0
});

/**
 * Hook makes the media player context available.
 * @returns The media player context.
 * @throws Will throw an error if used outside of a MediaPlayerProvider.
 */
export const useMediaPlayer = (): IMediaPlayerContext => {
  const context = useContext(mediaPlayerContext);
  if (!context) {
    throw new Error('useMediaPlayer must be used within a MediaPlayerProvider');
  }
  return context;
};

/**
 * The media player action interface. Applies to the media player reducer.
 */
interface IMediaPlayerAction {
  /**
   * Name of the action type - which action should I take?
   */
  type: string;
  /**
   * Data to be passed to the action. This is optional, as some actions
   * are switches.
   */
  payload?: any | null | undefined;
}
/**
 * The media player reducer. This helps centralize the state of the media player.
 * This is useful because state updates are coming from the same place.
 * @param state - The media player state.
 * @param action - The media player action.
 * @returns The media player state.
 */
const mediaPlayerReducer = (
  state: IMediaPlayerContext,
  action: IMediaPlayerAction
): IMediaPlayerContext => {
  switch (action.type) {
    case 'play':
      return { ...state, playing: true };
    case 'mute':
      return { ...state, muted: true };
    case 'unmute':
      return { ...state, muted: false };
    case 'pause':
      return { ...state, playing: false };
    case 'loading':
      return { ...state, loading: action.payload };
    case 'fullscreen':
      return { ...state, fullscreen: action.payload };
    case 'progress':
      return { ...state, progress: action.payload };
    default:
      return state;
  }
};

/**
 * The media player 'hook' used below in the provider. This should not be used
 * outside of this file.
 * @param video - The video model.
 * @returns The media player props.
 */
const useMediaPlayerContext = (video: IVideo): IMediaPlayerContext => {
  const [state, dispatch] = React.useReducer(mediaPlayerReducer, {
    playing: false,
    muted: false,
    loading: true,
    fullscreen: false,
    progress: 0
  });

  const [observer, setObserver] = useState<IntersectionObserver>();
  const [visible, setVisible] = useState<IntersectionObserverEntry>();
  const [model, setModel] = useState<VideoModel>(VideoModel.from(video));

  const ref = useRef<HTMLVideoElement>(null);

  const videoElement = useMemo(() => {
    if (!ref.current) {
      dispatch({
        type: 'loading',
        payload: true
      });
      return null;
    }
    dispatch({
      type: 'loading',
      payload: false
    });
    return ref.current;
  }, [ref?.current?.readyState, state.loading, model]);

  const handleModel = (model: IVideo): void => {
    setModel(VideoModel.from(model));
  };

  /**
   * Handle load. Will dispatch a loading state.
   */
  const handleLoad = (): void => {
    const video = videoElement;
    if (video) {
      dispatch({
        type: 'loading',
        payload: false
      });
    } else {
      dispatch({
        type: 'loading',
        payload: true
      });
    }
  };

  /**
   * Will pause the video. Always dispatches a pause.
   */
  const handlePause = (): void => {
    const video = videoElement;
    if (video) {
      if (!video.paused) video.pause();
    }
    dispatch({
      type: 'pause'
    });
  };

  /**
   * Will try to play the video. If it fails, it will dispatch a pause.
   * Always dispatches a play if there is no video.
   */
  const handlePlay = (): void => {
    const video = videoElement;
    if (video && video.paused) {
      video
        .play()
        .then(() => dispatch({ type: 'play' }))
        .catch(() => dispatch({ type: 'pause' }));
    } else dispatch({ type: 'play' }); // No video, but we want to play.
  };

  /**
   * Handle fullscreen. Will dispatch a fullscreen state.
   * @param fullscreen - Whether or not to go fullscreen.
   */
  const handleFullscreen = async (): Promise<void> => {
    const { fullscreen } = state;
    const video = videoElement;
    const requestFullscreen = (): Promise<void> =>
      video?.requestFullscreen() ?? Promise.resolve();

    /**
     * If we are not fullscreen, request fullscreen.
     */
    if (!fullscreen) {
      await requestFullscreen();
    }
  };
  /**
   * Handle intersection observer. Currently, threshold is set to 50%.
   * @param entries - The intersection observer entries.
   * @returns Void.
   */
  const handleIntersection = useCallback(
    (entries: Array<IntersectionObserverEntry>): void => {
      const [entry] = entries;
      if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
        setVisible(entry);
      } else {
        setVisible(undefined);
      }
    },
    [videoElement, ref.current]
  );

  /**
   * Handle visible state.
   * If it is visible, play the video; but only if autoplay is enabled.
   * If it is not visible, pause the video. If autoplay is disabled, the video
   * will not resume play when it becomes visible again.
   */
  useEffect(() => {
    const video = videoElement;
    if (video) {
      if (visible && model.settings.autoplay) {
        handlePlay();
      } else {
        handlePause();
      }
    }
  }, [visible, videoElement]);

  /**
   * Setup the intersection observer.
   * @returns Teardown function for observer.
   */
  useEffect(() => {
    // Setup video events.
    const video = videoElement;
    if (video) {
      const observer = new IntersectionObserver(handleIntersection, {
        rootMargin: '0px',
        threshold: 0.5
      });
      observer.observe(videoElement);
      setObserver(observer);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [videoElement, model]);

  /**
   * Ensure that state is playing when the video is playing.
   * Otherwise, if video is not loaded, set loading to true.
   */
  useEffect(() => {
    const video = videoElement;
    if (video) {
      video.onplay = handlePlay;
      video.onfullscreenchange = () => {
        if (document.fullscreenElement) {
          dispatch({
            type: 'fullscreen',
            payload: true
          });
        } else {
          dispatch({
            type: 'fullscreen',
            payload: false
          });
        }
      };
      video.ontimeupdate = () => {
        const progress = video.currentTime / video.duration;
        dispatch({
          type: 'progress',
          payload: progress
        });
      };
    } else {
      handleLoad();
    }
    return () => {
      if (video) {
        video.onplay = null;
        video.onfullscreenchange = null;
        video.ontimeupdate = null;
      }
    };
  }, [videoElement, state.loading, handleLoad, handlePlay]);

  /**
   * Handle autoplay and muted settings that exist on the model. This syncs
   * the playing and muted state with the ref.
   */
  useEffect(() => {
    if (model.settings.autoplay) {
      handlePlay();
    } else {
      handlePause();
    }
    if (model.settings.muted) {
      dispatch({ type: 'mute' });
    } else {
      dispatch({ type: 'unmute' });
    }
  }, []);

  useEffect(() => {
    const video = videoElement;
    if (video) {
      video.onplay = handlePlay;
      video.onfullscreenchange = () => {
        if (document.fullscreenElement) {
          dispatch({
            type: 'fullscreen',
            payload: true
          });
        } else {
          dispatch({
            type: 'fullscreen',
            payload: false
          });
        }
      };
    } else {
      handleLoad();
    }
    return () => {
      if (video) {
        video.onplay = null;
      }
    };
  }, [videoElement, state.loading]);

  return {
    playing: state.playing,
    muted: state.muted,
    loading: state.loading,
    fullscreen: state.fullscreen,
    progress: state.progress,
    handlePause,
    handlePlay,
    handleLoad,
    handleModel: (model?: IVideo) => handleModel(model || video),
    handleFullscreen, // If no model is passed, use the video model.
    ref
  };
};

/**
 * Interface used to extend FC with the media player props.
 */
interface IMediaPlayerProps extends PropsWithChildren {
  /**
   * The video model.
   */
  video: IVideo;
}

/**
 * The media player provider.
 * @param children - The children.
 */
export const MediaPlayer: FC<IMediaPlayerProps> = ({ video, children }) => {
  const context: IMediaPlayerContext = useMediaPlayerContext(video);
  return (
    <mediaPlayerContext.Provider value={context}>
      {children}
    </mediaPlayerContext.Provider>
  );
};
