import { FunctionComponent } from 'react';
import classNames from 'classnames';

import { IContentImage, ContentImageModel } from '@/services/models/Media/ContentImage';

import IStylable from '../../../../traits/IStylable';
import { ContentImage } from '../ContentImage';

import S from './styles.module.scss';

export interface IImageBlockProps extends IStylable {
  /** The content image. */
  image: IContentImage;
}

/**
 * `ImageBlock` allows us to easily handle image aspect ratio
 * and display it in a image block.
 * It can be used wherever you want to display images.
 * Common usages: `HeroImageSlider` and `HeroBanner`.
 */
export const ImageBlock: FunctionComponent<IImageBlockProps> = ({
  className,
  image
}) => {
  const imageModel = ContentImageModel.from(image);
  const classes = classNames(S.container, className);

  return (
    <div className={classes}>
      <ContentImage image={imageModel} />
    </div>
  );
};
