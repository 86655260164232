'use client';

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { Nullable } from '@/type-utils';
import { useEffect, useState } from 'react';
import { measureScrollbarWidth } from '../utils/dom-utils';

/**
 * A collection of data which represents the current zoom level of the browser window.
 *
 * This data is returned by the `useZoomLevel` hook.
 */
export interface IZoomData {
  /**
   * The current zoom ratio of the window. This is effectively the ratio of the visual viewport
   * to the layout viewport.
   */
  zoomRatio: number;
  /**
   * Whether the current zoom state is zoomed in or not.
   * This is equivalent to `zoomRatio > 1.1`.
   */
  isZoomedIn: boolean;
  /**
   * Whether the current zoom state is zoomed out or not.
   * This is equivalent to `zoomRatio < 0.9`.
   *
   * Note: Most browsers are not expected to zoom out beyond a scale of 1. However, this
   * property is included for completeness.
   */
  isZoomedOut: boolean;
}

/**
 * For performance reasons, we want to measure the scrollbar width once and then cache
 * the result. This constant will hold the width of the scrollbar on the current system.
 * We subtract this value from the width of the window to get the usable width of the
 * window. On the server side, this value will be 0.
 * @returns The width of the scrollbar on the current system.
 */
const getScrollbarWidth = (): Nullable<number> => {
  if ((typeof window === "undefined")) {
    return null;
  }

  return measureScrollbarWidth();
};

/**
 * A fallback value for the scrollbar width. This value is used when the scrollbar width
 * cannot be ascertained such as when rendering on the server side.
 */
const defaultScrollbarWidth = 10;

/**
 * The currently known width of the scrollbar on the current system. This value is used to
 * calculate the usable width of the window. On the server side, this value will use a
 * reasonable default value of 10px.
 */
let scrollbarWidthGlobal = getScrollbarWidth();

/**
 * Get the current zoom amount of the window. For a page that is not zoomed, this will
 * return 100. This value may not perfectly represent the zoom level of the window, but
 * is useful for tracking differences in zoom level over time. If called from the
 * server-side this function will assume the value of `1`.
 * @returns The current zoom ratio of the window.
 */
const getCurrentZoomAmount = (): number => {
  // If on the server-side, we don't have access to the window object, so we can't
  // calculate the zoom level. In this case, we return `1`.
  if ((typeof window === "undefined")) {
    return 1;
  }

  // If the browser supports the `visualViewport` API, we can use it to get the zoom
  // level of the window. This API is supported in modern browsers and provides a
  // reliable way to get the zoom level of the window, particularly on mobile devices.
  if (window.visualViewport) {
    return window.visualViewport.scale * 100;
  }

  // If the `visualViewport` API is not supported, we can use the ratio of the outer
  // width to the inner width of the window to calculate the zoom level. This method is
  // less reliable than the `visualViewport` API, but it is a reasonable fallback for
  // older browsers.
  return (
    ((window.outerWidth - (scrollbarWidthGlobal ?? defaultScrollbarWidth)) /
      window.innerWidth) *
    100
  );
};

/**
 * The initial zoom level of the window. Should initialize roughly to 100%.
 */
const initialDimension = getCurrentZoomAmount();

/**
 * The current zoom level of the window.Should initialize roughly to 100%. Unlike
 * {@link initialDimension}, this value is updated when the window is resized. This allows
 * us to track the zoom level of the window in real time across instantiations of this hook.
 */
let currentDimensionGlobal = getCurrentZoomAmount();

/**
 * Handles the current zoom level of the window.
 * @returns An object containing the zoom ratio and other relevant data.
 *
 * Note: The result of this hook should be destructured into individual properties to
 * avoid unnecessary re-renders.
 *
 * Note: On the server side, the zoom level will always be 1 and the `isZoomedIn` and
 * `isZoomedOut` properties will always be `false`.
 */
export const useZoomLevel = (): IZoomData => {
  const [currentDimension, setCurrentDimension] = useState<number>(
    currentDimensionGlobal ?? 0
  );
  const [scrollbarWidth, setScrollbarWidth] = useState<Nullable<number>>(
    getScrollbarWidth()
  );

  useEffect(() => {
    const updateDimensions = (): void => {
      const zoomLevel = getCurrentZoomAmount() ?? 0;
      // Update the current dimension state with the new zoom level. This state update
      // allows components to re-render when the zoom level changes.
      setCurrentDimension(zoomLevel);
      // Update the global zoom level with the new zoom level. This global variable
      // allows us to track the zoom level across instantiations of this hook.
      currentDimensionGlobal = zoomLevel;

      // If we were previously unable to measure the scrollbar width, try to measure it
      // again.
      if (scrollbarWidth === null) {
        const currentScrollbarWidth = getScrollbarWidth();
        setScrollbarWidth(currentScrollbarWidth);
        scrollbarWidthGlobal = currentScrollbarWidth;
      }
    };

    // If the browser supports the `visualViewport` API, bind to the `resize` event of
    // the `visualViewport` object. This allows us to track the zoom level of the window
    // in real time.
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', updateDimensions);

      return () =>
        window.visualViewport!.removeEventListener('resize', updateDimensions);
    }
    // If the browser does not support the `visualViewport` API, bind to the `resize`
    // event of the window object. This is a legacy method of tracking the zoom level of
    // the window and is less reliable than the `visualViewport` API.

    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [scrollbarWidth]);

  // Otherwise, we calculate the zoom level by dividing the current dimension by the
  // initial dimension. We round this number to the nearest hundredth to avoid
  // floating point errors.
  const zoomRatio = Number((currentDimension / initialDimension).toFixed(2));

  // TODO: Update comments to explain how the threshold is used.
  const zoomThreshold = 0.1;

  // We consider the window to be zoomed in if the zoom ratio is greater than 1.1. This
  // allows for a reasonable margin of error in the zoom level. particularly on mobile
  // devices where a slight amount of zoom does not necessarily indicate that the user
  // has intentionally zoomed in.
  const isZoomedIn = zoomRatio >= 1 + zoomThreshold;
  const isZoomedOut = zoomRatio <= 1 - zoomThreshold;

  return {
    zoomRatio,
    isZoomedIn,
    isZoomedOut
  };
};
