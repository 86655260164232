'use client';

import IStylable from '../../traits/IStylable';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import S from './styles.module.scss';

/**
 * Curtain props.
 */
export interface ICurtainProps extends IStylable {
  /**
   * Called when user indicates they want to close whatever is on top of the curtain.
   */
  onClose?: () => void;
}

/**
 * Darkens the background when opening a modal or a drawer.
 */
export const Curtain: FunctionComponent<ICurtainProps> = ({
  onClose,
  className,
  style
}) => {
  return (
    <div
      onClick={onClose}
      onKeyPress={onClose}
      aria-hidden
      className={classNames(S.curtain, className)}
      style={style}
    />
  );
};
