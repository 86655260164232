'use client';

import {
  useMemo,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  RefObject
} from 'react';
import { Swiper } from 'swiper/react';
import { useInView } from 'framer-motion';
import { SliderPagination } from '../../../../../Slider/components';

import {
  ISliderProps,
  ISliderOptions
} from '../../../../../Slider/ISliderProps';
import {
  SliderContext,
  ISliderContext
} from '../../../../../Slider/utils/context';
import { useReactiveSlider } from '../../../../../Slider/utils/hooks';
import {
  deriveSliderOptionsFromProps,
  divideChildren
} from '../../../../../Slider/utils/helpers';

import 'swiper/swiper-bundle.css';

import S from './styles.module.scss';

/**
 * Highly customizable Slider component.
 */
export const ScrollSlider: FunctionComponent<ISliderProps> = (props) => {
  /** Transform the props into options. */
  const options: ISliderOptions = deriveSliderOptionsFromProps(props);
  const {
    direction,
    wrapperClassName,
    sliderClassName,
    pagination,
    transitionSpeedMS,
    allowTouchMove = true
  } = options;

  const { slider, onSlider } = useReactiveSlider();
  const context = useMemo<ISliderContext>(() => {
    return {
      slider,
      options
    } as ISliderContext;
  }, [slider, options]);

  const { children, containerRef, scrollYProgress } = props;
  const { slides } = divideChildren(children);
  const wrapperRef = useRef(null);

  /**
   * Set up our scroll functionality.
   */
  const [currSlideIndex, setCurrSlideIndex] = useState(0);

  const isInView = useInView(containerRef as RefObject<Element>, {
    amount: 0.85,
    once: false
  });

  // When the slider is in view, we want to assign an additional
  // class to scale the slider up to full size.
  const wrapperClasses = useMemo(() => {
    const classes = [S.wrapper, wrapperClassName];
    if (isInView) {
      classes.push(S.wrapperInView);
    }
    return classes.join(' ');
  }, [isInView, wrapperClassName]);

  useEffect(() => {
    /**
     * Check for the need for a slide update, and execute if needed.
     *
     * @param scrollProgress - 0-1 value for scroll progress.
     */
    function checkForSlideUpdate(scrollProgress: number): void {
      let index = Math.floor(scrollProgress * slides.length);
      // Boundary check...
      if (index >= slides.length) {
        index = slides.length - 1;
      }
      if (index !== currSlideIndex) {
        setCurrSlideIndex(index);
        slider?.slideTo(index);
      }
    }
    const unsubscribe = scrollYProgress?.on('change', (latest) => {
      checkForSlideUpdate(latest);
    });
    return unsubscribe;
  }, [slider, currSlideIndex, scrollYProgress, slides.length]);

  return (
    <SliderContext.Provider value={context}>
      <div className={wrapperClasses} ref={wrapperRef}>
        <Swiper
          onSwiper={onSlider}
          spaceBetween={1}
          direction={direction}
          className={S.swiper + ' ' + sliderClassName}
          speed={transitionSpeedMS}
          allowTouchMove={allowTouchMove}
        >
          {slides}
        </Swiper>
        {slider && pagination.enabled && <SliderPagination />}
      </div>
    </SliderContext.Provider>
  );
};
