import { Nullable } from '@/type-utils';
import { isNullOrEmpty } from '@/utils/null-utils';
import { ISliderPaginationThumbnail } from './ISliderPaginationOptions';

/**
 * Returns a thumbnail object based on the thumbnail item.
 * @param thumbnail - Item in the thumbnails array.
 * @param index - Index of the thumbnail (used for alt text generation).
 * @returns Pagination thumbnail object.
 */
export const getThumbnail = (
  thumbnail: Nullable<ISliderPaginationThumbnail> | string,
  index: number
): Nullable<ISliderPaginationThumbnail> => {
  if (isNullOrEmpty(thumbnail)) {
    return null;
  }

  const defaultAlt = `Slide ${index + 1}`;

  if (typeof thumbnail === 'string') {
    return {
      src: thumbnail,
      alt: defaultAlt,
      className: ''
    } as ISliderPaginationThumbnail;
  }

  return {
    src: thumbnail.src,
    alt: thumbnail.alt ?? defaultAlt,
    className: thumbnail.className ?? ''
  } as ISliderPaginationThumbnail;
};
