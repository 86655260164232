import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Nullable } from '@/type-utils';

import { IContentItem, ContentItemModel } from '@/services/models/Content';
import IStylable from '../../../../traits/IStylable';
import { IContentImage, ContentImageModel } from '@/services/models/Media/ContentImage';
import { ContentImage } from '../ContentImage';
import { TextBlock } from '../TextBlock';

import S from './styles.module.scss';

/**
 *  `TextWithBackgroundVariant` type allows us to change the size of Text Block.
 */
type TextWithBackgroundVariant = 'default' | 'large-headline';

interface ITextWithBackgroundProps extends IStylable {
  /** The content item. */
  item: IContentItem;

  /**
   * The variant to change text block style.
   */
  variant?: TextWithBackgroundVariant;

  /**
   * The background image for headline section.
   */
  backgroundImage?: Nullable<IContentImage>;
}

/**
 * `TextWithBackground` display a `headline` including `title`,
 * `teaser text` and a `CTA` button, as well as a background
 * image.
 */
export const TextWithBackground: FunctionComponent<
  ITextWithBackgroundProps
> = ({ className, item, variant = 'default', backgroundImage }) => {
  const itemModel = ContentItemModel.from(item);
  const imageModel = backgroundImage
    ? ContentImageModel.from(backgroundImage)
    : null;

  if (imageModel) {
    imageModel.aspectRatio = 'portrait_ratio1x1';
  }

  const classes = classNames(S.container, className);

  return (
    <div className={classes}>
      <TextBlock variant={variant} item={itemModel} />
      {imageModel && <ContentImage image={imageModel} isBackgroundImage />}
    </div>
  );
};
