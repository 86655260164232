'use client';

import {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  ContentImageModel,
  IContentImage
} from '@/services/models/Media/ContentImage';
import { ImageLayout, ImageModel } from '@/services/models/Media/Image';
import { classes } from '@/next-utils/css-utils/scss-utils';

import LoggerService from '@/services/isomorphic/LoggerService';
import S from './styles.module.scss';
import { Img, Picture, Source } from '../../../../core-ui/Picture';

/**
 * Initial width of an image when rendered in SSR. Since SSR can't really know
 * the size of the image element (the bounding box), we can't correctly
 * optimize for it. Meaning, if the width of the image element is 760px,
 * it would be optimal to load a 800px variant of the image, but that's not possible
 * during SSR. Our best approach is running a `useEffect` on the client side and
 * determining the width of the element there.
 *
 * This variable controls the initial assumption of the size of the image.
 * In the {@link ContentImageModel}, the initial assumption is 600px.
 * If everything goes right, this image variant shouldn't be loaded at all and
 * useEffect should determine the correct size in time, however it's probably best to
 * for SSR to ship URL's for small images, just in case the client ends up
 * loading them before the client-side useEffect kicks in. That's why
 * I think it's best to keep this number small.
 */
const ssrImageWidth = 320;

export interface IContentImageProps {
  /** Image object that is either a DTO or the full ContentImageModel. */
  image: IContentImage;

  /** Add placeholder animation. */
  animation?: boolean;

  /** NEXTJS layout property. */
  layout?: ImageLayout;

  /** Is a background image.  */
  isBackgroundImage?: boolean;

  /** Class to apply to the container element. */
  className?: string;
}

/**
 * Based on a {@link ContentImageModel}, returns two {@link ImageModel}-s,
 * the first one describing the image for a desktop device, and the second one for
 * the mobile device.
 *
 * @param image - The Content image object.
 * @param elementWidth - The width of the image element.
 * @returns A `[desktop, mobile]` pair of `ImageModels`.
 */
const constructImageModels = (
  image: IContentImage,
  elementWidth: number
): [ImageModel, ImageModel] => {
  // The image is either a IContentImage or a ContentImageModel, since
  // sometimes we pass the ContentImageModel.from() as props.
  // Either way, get a final ContentImageModel to work from
  const imageModel = ContentImageModel.from(image);
  // Create clones to safely work on
  // TODO: This may create issues when different images are intended for mobile and desktop
  const desktop = ContentImageModel.from(imageModel.toDTO());
  const mobile = ContentImageModel.from(imageModel.toDTO());

  // Use the ContentImageModel to generate a desktop image src
  desktop.setSrc({
    elementWidth,
    isMobile: false
  });

  // Use the ContentImageModel to generate a mobile image src
  mobile.setSrc({
    elementWidth,
    isMobile: true // So that we get the mobile aspect ratio
  });

  return [
    contentImageModelToImageModel(desktop),
    contentImageModelToImageModel(mobile)
  ];
};

/**
 * Converts a {@link ContentImageModel} to an {@link ImageModel}.
 * The ContentImageModel has a some intersection with the IImage, mainly in the
 * required fields such as uuid, width, height, src, and alt. We need an ImageModel
 * to pass to the `<Picture>` component.
 *
 * @param contentImage - The {@link ContentImageModel} or an {@link IContentImage}.
 * @returns An {@link ImageModel}.
 */
const contentImageModelToImageModel = (
  contentImage: ContentImageModel | IContentImage
): ImageModel => {
  const image = ContentImageModel.from(contentImage);
  return ImageModel.from({
    uuid: image.uuid,
    width: image.width,
    height: image.height,
    src: image.src,
    alt: image.alt
  });
};

/**
 * Images pulled from the content service and optimized according to sizes
 * supplied in the studio.
 *
 * @throws An error if no image is provided.
 */
export const ContentImage: FunctionComponent<IContentImageProps> = ({
  image,
  animation = true,
  layout = 'responsive',
  isBackgroundImage = false,
  className: wrapperClass = ''
}) => {
  if (!image) {
    LoggerService.error(
      new Error('No image provided to ContentImage component.')
    );

    return null;
  }

  const componentRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const onLoadingComplete = useCallback((): void => {
    setIsLoaded(true);
  }, []);

  // The width of the wrapper element
  const [elementWidth, setElementWidth] = useState(ssrImageWidth);

  // Memoized image models for the desktop and the mobile.
  // Using useMemo() since the parent might trigger a re-render for this component
  // when the props don't change at all.
  const [desktop, mobile] = useMemo(() => {
    return constructImageModels(image, elementWidth);
  }, [image, elementWidth]);

  // Updating the element width on every resize is unoptimal.
  // What's optimal is only updating the element width if it is going to
  // have an effect on the render output (changing the images).
  // This function only updates the elementWidth if it will end up
  // changing the image source.
  const setElementWidthDebounced = useCallback(() => {
    const newElementWidth = componentRef.current?.offsetWidth ?? 0;
    if (!newElementWidth) {
      return;
    }

    // Hypothetically, generate new ImageModels using the new element width.
    // If the resulting .src is different than the one already set,
    // then actually update the elementWidth in the state to trigger a re-render
    const [newDesktop, newMobile] = constructImageModels(
      image,
      newElementWidth
    );

    // if either the desktop or the mobile source change, re-render
    // it's better to have up-to-date sources
    if (desktop.src !== newDesktop.src || mobile.src !== newMobile.src) {
      setElementWidth(newElementWidth);
    }
  }, [image, elementWidth]);

  /**
   * Calculate the correct sources once, after the element is mounted and ref is acquired.
   */
  useEffect(setElementWidthDebounced, []);

  /**
   * This allows the src to update based on the current width of the element.
   */
  useEffect(() => {
    window.addEventListener('resize', setElementWidthDebounced);
    return () => window.removeEventListener('resize', setElementWidthDebounced);
  }, [setElementWidthDebounced]);

  const styling: CSSProperties = {};
  if (isBackgroundImage) {
    styling.backgroundImage = `url(${desktop.src})`;
  }

  const className = classes({
    [wrapperClass]: true,
    [S.responsive]: true,
    [S.background]: isBackgroundImage, // If background image
    [S.loading]: animation && !isBackgroundImage && !isLoaded // If not a background image, and loading
  });

  return (
    <div ref={componentRef} className={className} style={styling}>
      {!isBackgroundImage && (
        <Picture>
          <Source media="phone" image={mobile} />
          <Img image={desktop} onLoad={onLoadingComplete} layout={layout} />
        </Picture>
      )}
    </div>
  );
};
