'use client';

import { FunctionComponent, MouseEventHandler } from 'react';

import classNames from 'classnames';
import { Image } from '../../../core-ui/Image';
import {
  useSliderController,
  useSliderOptions,
  useSliderState
} from '../../utils/hooks';

import { getThumbnail } from './helpers';

import S from './styles.base.module.scss';

/**
 * Pagination wrapper. Renders the pagination bullets or thumbnails.
 * @throws - If thumbnails are provided but don't match the slides in count.
 */
export const SliderPagination: FunctionComponent = () => {
  const sliderState = useSliderState();
  const sliderController = useSliderController();
  const options = useSliderOptions().pagination;
  // Grab the direction from the Slider pagination options,
  // which reflects the direction specified for the thumbnails.
  const { direction } = options;

  const {
    inset,
    position,

    buttonClassName,
    thumbnailClassName,
    activeClassName,
    inactiveClassName,
    wrapperClassName,

    thumbnails = []
  } = options;

  if (thumbnails.length > 0 && thumbnails.length !== sliderState.total) {
    // Originally, this component would throw an error in this case.
    // However, it's not clear what the intention of it was, since we don't explicitly handle
    // a render error in the parent component, but we don't want to break the page. So unless
    // the intention is clarified, it makes more sense to return null here.
    return null;
  }

  const children = [];

  for (let i = 0; i < sliderState.total; i++) {
    const onClick: MouseEventHandler<HTMLButtonElement> = () => {
      sliderController.goto(i);
    };

    const btnClasses = [
      S.button,
      buttonClassName // To style buttons in general
    ];

    const thumbnailClasses = [S.thumbnail, thumbnailClassName];

    if (i === sliderState.current) {
      // Button representing the current (active) slide
      btnClasses.push(S.active, activeClassName);
    } else {
      // Button representing the inactive slide
      btnClasses.push(S.inactive, inactiveClassName);
    }

    const thumbnail = getThumbnail(thumbnails[i], i);
    if (thumbnail) {
      btnClasses.push(S.hasThumbnail);
    }

    const title = `Goto slide ${i + 1}`;
    const btnClassName = btnClasses.join(' ');
    const thumbClassName = classNames(
      S.thumbnail,
      thumbnailClassName,
      thumbnail?.className
    );

    children.push(
      <button
        key={i}
        type="button"
        onClick={onClick}
        className={btnClassName}
        title={title}
        aria-label={title}
      >
        {thumbnail && (
          <Image
            image={{
              uuid: '',
              src: thumbnail.src,
              alt: thumbnail.alt
            }}
            className={`${thumbClassName} ${thumbnail.className}`}
            aria-hidden
          />
        )}
      </button>
    );
  }

  const wrapperClasses = [
    S.wrapper,
    direction === 'vertical' ? S.vertical : S.horizontal,
    wrapperClassName,
    inset ? S.inset : ''
  ];

  const wrapperClassString = wrapperClasses.join(' ');
  const finalPosition =
    position ?? (direction === 'vertical' ? 'left' : 'bottom');

  return (
    <div className={wrapperClassString} data-position={finalPosition}>
      {thumbnails.length || children.length > 1 ? children : ''}
    </div>
  );
};
