import { Parallax } from '@/react/components/utility/Parallax';
import { FunctionComponent } from 'react';

import { ContentItemModel, IContentItem } from '@/services/models/Content';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import INestableContent from '../../../traits/INestableContent';
import { getCustomStyling } from '../../../utils/styling-utils';
import S from './styles.module.scss';

/**
 * The variant of the offset overlay.
 */
export type OffsetOverlayVariant = 'default' | 'parallax';

/**
 * Get the styling for the variant.
 * @param variant - The variant of the offset overlay.
 * @returns The styling for the variant.
 */
function getStylingFromVariant(variant: OffsetOverlayVariant): string {
  switch (variant) {
    case 'parallax':
      return S.parallax;
    case 'default':
    default:
      return S.default;
  }
}

export interface IOffsetOverlayProps extends INestableContent {
  /** The collection item, will contain any number of teasers. */
  item: IContentItem;
  /**
   * The variant of the offset overlay.
   */
  variant?: OffsetOverlayVariant;
}

/**
 * Offset Overlay can have any number of teasers as children.
 * The first teaser will be displayed behind the remaining.
 */
export const OffsetOverlay: FunctionComponent<IOffsetOverlayProps> = ({
  item,
  variant = 'default',
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);
  const { items = [], custom } = itemModel;
  const styling = getCustomStyling(custom);
  const variantStyle = getStylingFromVariant(variant);
  const isParallax = variant === 'parallax';

  return (
    <div className={classNames(S.wrapper, variantStyle)} style={styling}>
      {isParallax ? (
        <Parallax offset={80}>
          <div className={S.first}>
            {contentResolver({
              ...items[0],
              viewType: items[0]?.viewType ?? 'collection'
            })}
          </div>
        </Parallax>
      ) : (
        <div className={S.first}>
          {contentResolver({
            ...items[0],
            viewType: items[0]?.viewType ?? 'collection'
          })}
        </div>
      )}
      {items.slice(1).map((item, i) => {
        const viewType = item.viewType || 'collection';
        return (
          <div className={S.teaser} key={uuidv4()}>
            {contentResolver({ ...item, viewType })}
          </div>
        );
      })}
    </div>
  );
};
