'use client';

import { FunctionComponent } from 'react';

import { Slider, Slide } from '../../../../Slider';
import { IContentItem, ContentItemModel } from '@/services/models/Content';

import { TextWithBackground } from '../../core/TextWithBackground';

import S from './styles.module.scss';

export interface ITextBlockSliderProps {
  /** The content item. */
  item: IContentItem;
}

/**
 * `HeroTextSlider` allows us to add slider texts in Hero components.
 */
export const HeroTextSlider: FunctionComponent<ITextBlockSliderProps> = ({
  item
}) => {
  const itemModel = ContentItemModel.from(item);

  if (!itemModel.items || itemModel.items.length === 0) {
    return (
      <TextWithBackground item={itemModel} backgroundImage={itemModel.image} />
    );
  }

  if (itemModel.items.length === 1 && itemModel.item) {
    return (
      <TextWithBackground
        item={itemModel.item}
        backgroundImage={itemModel.item.image}
      />
    );
  }

  return (
    <Slider
      loop
      autoplay={{
        loop: true,
        delay: 4000
      }}
      navigation={false}
      height="100%"
      wrapperClassName={S.sliderWrapper}
      navigationWrapperClassName={S.sliderNavigationWrapper}
    >
      {itemModel.items.map((item) => {
        return (
          <Slide key={item.id}>
            <TextWithBackground item={item} backgroundImage={item.image} />
          </Slide>
        );
      })}
    </Slider>
  );
};
