'use client';

import { FunctionComponent } from 'react';

import { AspectRatioName } from '@/services/models/Media/ContentImage';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import {
  getContainerOptions,
  getCustomStyling,
  getItemOverlay
} from '../../../utils/styling-utils';
import { ContentBlock } from '../../core/ContentBlock';
import { ParallaxItems } from '../ParallaxItems';

import S from './styles.base.module.scss';
import type INestableContent from '../../../traits/INestableContent';

export interface ISplitBannerProps extends INestableContent {
  /** The content item. */
  item: IContentItem;
}

/** Split banner content with two images and one cta. */
export const SplitBanner: FunctionComponent<ISplitBannerProps> = ({
  item,
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);
  const { custom, items, settings } = itemModel;
  const numberOfItems = items?.length;
  const styling = getCustomStyling(custom);
  const { flip, containerClass } = getContainerOptions(custom);
  const overlay = getItemOverlay(settings);

  let containerStyle: string;
  let ratio: AspectRatioName = 'portrait_ratio1x1';
  switch (numberOfItems) {
    case 2: {
      containerStyle = S.twoItems;
      break;
    }
    case 3: {
      ratio = 'portrait_ratio3x4';
      containerStyle = S.threeItems;
      break;
    }
    case 4: {
      ratio = 'portrait_ratio3x4';
      containerStyle = S.fourItems;
      break;
    }
    case 5: {
      containerStyle = S.fiveItems;
      break;
    }
    case 6: {
      containerStyle = S.sixItems;
      break;
    }
    default: {
      containerStyle = S.singleItem;
      break;
    }
  }
  itemModel.items?.forEach((item) => {
    const { image } = item;
    if (image) {
      image.aspectRatio = ratio;
    }
  });

  /**
   * Whether or not to override the SplitBanner component with ParallaxItems container.
   * The AHNU hompeage required very custom styling for this element, so we're using
   * the ParallaxItems component to handle the layout/styling requirements.
   */
  const overrideParallaxContainer = useBrandLocaleValue<boolean>(
    () => ({
      default: false,
      AHNU: true
    }),
    []
  );

  // CUSTOM COMPONENT USAGE FOR AHNU PARALLAX CONTAINER.
  if (
    itemModel.item?.viewType === 'offset-overlay-parallax' &&
    overrideParallaxContainer
  ) {
    return <ParallaxItems items={items} />;
  }

  return (
    <div
      className={`${S.root} ${containerClass}`}
      style={{
        ...styling
      }}
    >
      {items?.map((item, index) => {
        return (
          <div
            className={`${containerStyle} ${overlay.headline}`}
            key={item.id as string}
            data-banner-index={index}
          >
            <div className={S.embeddedContainer}>
              {/*
                If the sub-item has a view type, let the GenericItem determine what to render,
                otherwise render a ContentBlock since the SplitBanner doesn't really support having
                collections or some weird items as its children.
              */}
              {item.viewType ? (
                contentResolver(item)
              ) : (
                <ContentBlock item={item} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
