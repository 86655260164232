'use client';

import {
  FunctionComponent,
  CSSProperties,
  useEffect,
  useState,
  useRef,
  RefObject
} from 'react';
import { motion, useScroll, useInView, useTransform } from 'framer-motion';
import {
  IContentItem,
  ContentItemModel,
  ContentPositionX
} from '@/services/models/Content';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import IStylable from '../../../../traits/IStylable';
import { Button, ButtonVariant } from '../../../../core-ui/Button';
import { Link } from '../../../../core-ui/Link';

import { WithAnchorScroll } from '../../../../core-ui/Anchor/WithAnchorScroll';
import { FragmentParser } from '../../../../utility/FragmentParser';
import { getItemOverlay, getCustomStyling } from '../../../utils/styling-utils';
import S from './styles.base.module.scss';

/**
 * Get the core styling from the variant string.
 * @param variant - String that represents a text block styling type.
 * @returns Returns a className from the styling import.
 */
const getStylingFromVariant = (variant: TextBlockVariant): string => {
  switch (variant) {
    case 'large-headline':
      return S.largeHeadline;
    case 'overlay-mobile':
      return `${S.overlayMobile}`;
    case 'overlay':
      return S.overlay;
    case 'row':
    case 'accordion-button':
      return S.row;
    case 'scroll-effect':
      return S.scrollEffect;
    case 'side-by-side':
    case 'default':
    default:
      return S.default;
  }
};

/**
 *  Text block variant type allow us to change the size of Text Block.
 */
export type TextBlockVariant =
  | 'default'
  | 'large-headline'
  | 'overlay'
  | 'overlay-mobile'
  | 'accordion-button'
  | 'row'
  | 'side-by-side'
  | 'scroll-effect';

export interface ITextBlockProps extends IStylable {
  /** The content item. */
  item: IContentItem;

  /**
   * This allows you to style the container for the buttons rather than
   * the buttons themselves from the parent component.
   */
  buttonContainerStyle?: string;
  /** Wrap the headline in the first link. */
  headlineLink?: boolean;
  /** Wrap the body text in the first link. */
  bodyLink?: boolean;
  /** Style type of text block. */
  variant?: TextBlockVariant;
}

/**
 * TextBlock component is the key Content component. It is used to handle the
 * title, text, and buttons, with their associated styling.
 */
export const TextBlock: FunctionComponent<ITextBlockProps> = ({
  item,
  className,
  buttonContainerStyle = '',
  headlineLink = false,
  bodyLink = false,
  variant = 'default'
}) => {
  const itemModel = ContentItemModel.from(item);

  const { title, text, link, links, settings, custom } = itemModel;
  const overlay = getItemOverlay(settings);
  const { newTab = false } = custom as Record<string, boolean>;
  const { width } = overlay;
  // Indicates this element is part of a larger clickable block.
  const isBlockClickable = links?.at(0)?.href && !links.at(0)?.isCTA;

  const styling = getCustomStyling(custom);
  const [location, setLocation] = useState<string>('');

  let positionXClass: string;

  /**
   * The width comes in from the width slider as a number, which is converted
   * to ch on the text width element; however, the width CAN be changed directly
   * in local settings to a string. This function allows for a valid width
   * in either scenario.
   * @param width - The width of the text block.
   * @returns - A string of the width.
   */
  const toTextWidth = (width: number): string => {
    if (parseInt(`${width}`, 10) > 0 && parseInt(`${width}`, 10) <= 100) {
      return `${width}ch`;
    }
    return `${width}`;
  };

  switch (overlay.positionX) {
    case ContentPositionX.Left: {
      positionXClass = S.left;
      break;
    }
    case ContentPositionX.Right: {
      positionXClass = S.right;
      break;
    }
    default: {
      positionXClass = S.center;
      break;
    }
  }

  const headlineHref = headlineLink && link?.href ? link.href : null;
  const bodyHref = bodyLink && link?.href ? link.href : null;
  const useScrollEffect = variant === 'scroll-effect';
  const scrollInnerRef = useRef(null);
  const textBlockRef = useRef(null);

  const { scrollYProgress } = useScroll({
    offset: ['start end', 'end start'],
    target: textBlockRef
  });
  const classes = `${S.container} ${
    isBlockClickable ? S.clickableBlockElement : ''
  }`;
  const blockContent = (
    <>
      {text &&
        (bodyHref && bodyHref.length > 0 ? (
          <Link href={bodyHref ?? undefined} openInNewTab={newTab}>
            <div className={`${S.text} ${overlay.text}`}>
              <FragmentParser string={text} />
            </div>
          </Link>
        ) : (
          <WithAnchorScroll className={S.link}>
            <div className={`${S.text} ${overlay.text}`}>
              <FragmentParser string={text} />
            </div>
          </WithAnchorScroll>
        ))}
      {links && links.length > 0 && (
        <div
          className={`${S.buttonContainer} ${
            S[buttonContainerStyle] ?? buttonContainerStyle ?? ''
          }`} // Fallback to use passed down style.
        >
          {links?.map((link) => {
            // Don't render CTA if CTA is not enabled.
            if (!link.isCTA) return null;
            /**
             * Used by buttons nested in links to provide a unique
             * id.
             */
            const ariaLabelledById = uuidv4();
            // Remove the trailing slash from the location
            const isActive = location.split('/').at(-1) === link.href;
            return (
              <div className={S.ctaItem} key={link.href}>
                {link.href.length > 0 ? (
                  <Link
                    variant="text"
                    ariaLabelledBy={ariaLabelledById}
                    href={link.href}
                    openInNewTab={newTab}
                  >
                    <Button
                      id={ariaLabelledById}
                      size="lg"
                      variant={overlay.cta as ButtonVariant}
                      className={isActive ? S.active : ''}
                    >
                      {link.text}
                    </Button>
                  </Link>
                ) : (
                  <WithAnchorScroll className={overlay.cta}>
                    <Button size="lg" variant={overlay.cta as ButtonVariant}>
                      {link.text}
                    </Button>
                  </WithAnchorScroll>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );

  /**
   * Set the location of the window to the state. Used temporarily to
   * allow conditional rendering of the link component when the location
   * matches the href. This should be removed from the textblock component
   * when the text block component is refactored to move the link functionality
   * to a separate TextBlockLink component.
   */
  useEffect(() => {
    setLocation(window.location.href);
  }, []);

  return (
    <div
      className={classNames(
        classes,
        getStylingFromVariant(variant),
        positionXClass,
        className
      )}
      style={
        {
          maxWidth: toTextWidth(width),
          ...overlay.props,
          ...styling
        } as CSSProperties
      }
      ref={textBlockRef}
    >
      {useScrollEffect ? (
        <div className={S.scrollEffectInnerContainer} ref={scrollInnerRef}>
          <motion.div
            className={S.block}
            style={{
              backgroundPositionX: useTransform(
                scrollYProgress,
                [0, 1],
                ['100%', '0%']
              ),
              translateY: useTransform(
                scrollYProgress,
                [0, 0.3, 0.7, 1],
                ['50%', '10%', '-10%', '-50%']
              )
            }}
          >
            {blockContent}
          </motion.div>
        </div>
      ) : (
        <>
          {title &&
            (headlineHref && headlineHref.length > 0 ? (
              <Link href={headlineHref ?? undefined} openInNewTab={newTab}>
                <h3 className={`${S.headline} ${overlay.headline}`}>{title}</h3>
              </Link>
            ) : (
              <WithAnchorScroll className={S.link}>
                <h3 className={`${S.headline} ${overlay.headline}`}>{title}</h3>
              </WithAnchorScroll>
            ))}
          <div className={S.block}>{blockContent}</div>
        </>
      )}
    </div>
  );
};
