import { DTO } from '@/type-utils';
import { NotImplementedError } from '@/utils/errors';
import type { IVideo } from '.';
import { MediaModel } from '..';
import { IMediaOptimizationOptions } from '../../../isomorphic/MediaService';
import type { IPlayerSettings } from '../../Content';
import { ITrackCaption, TrackKinds, TrackModel } from '../Track';

/**
 * Represents a video.
 */
export default class VideoModel
  extends MediaModel<DTO<IVideo>>
  implements IVideo
{
  /** The player settings. */
  private readonly _settings: IPlayerSettings;

  /** An image URI to show where playback is impossible or has not started. */
  public readonly thumbnail?: string;

  /** An image URI that is used as a placeholder until the video is loaded. */
  public readonly poster?: string;

  /** The tracks for the video. */
  public readonly tracks: Array<TrackModel>;

  /** Caption for video. Must be VTT format. Will typically take the first track from tracks.
   * Caption must be of type "captions" or "subtitles".
   */
  public readonly captions?: ITrackCaption;

  /**
   * Builds a model from any Video representation.
   * @param video - A Video representation.
   */
  public constructor(video: IVideo) {
    super(video);
    this.thumbnail = video.thumbnail;
    this.poster = video.poster;

    /** Tracks can include any number of .vtt tracks. */
    this.tracks = video.tracks?.map((track) => TrackModel.from(track)) ?? [];

    /** Caption can include a single .vtt track. */
    if ('captions' in video && video.captions) {
      this.captions = TrackModel.from(video.captions) as ITrackCaption;
    } else if (this.tracks.length > 0) {
      const [captions] = this.tracks.filter(
        (track) =>
          track.kind === TrackKinds.Subtitles ||
          track.kind === TrackKinds.Captions
      );
      this.captions = TrackModel.from(captions).toDTO() as ITrackCaption;
    }

    /** Settings can include any number of player settings. If any settings
     * are present on video, use them.
     * */
    let settings: IPlayerSettings = {
      autoplay: false,
      muted: true,
      controls: false,
      loop: false
    };
    // Override default settings with video settings if present
    if ('settings' in video && video.settings) {
      settings = {
        ...settings,
        ...video.settings
      };
    }

    this._settings = settings;
  }

  /** @inheritDoc */
  public optimize(options: IMediaOptimizationOptions): void {
    // TODO: Implement.
    throw new NotImplementedError(
      'Optimizing videos has not been implemented.'
    );
  }

  /**
   * Gets the player settings.
   * @returns The player settings {@link IPlayerSettings}.
   */
  public get settings(): IPlayerSettings {
    return this._settings;
  }

  /**
   * Creates a DTO representation of the Video Model.
   * @returns A DTO representation of this Video Model.
   */
  public override toDTO(): DTO<IVideo> {
    return {
      uuid: this.uuid,
      width: this.width,
      height: this.height,
      src: this.src,
      thumbnail: this.thumbnail,
      poster: this.poster,
      tracks: this.tracks.map((track) => track.toDTO()),
      settings: this.settings,
      captions: this.captions
    };
  }
}
