'use client';

import { FC, PropsWithChildren } from 'react';
import { useAnchor } from './useAnchor';

interface IWithAnchorScrollProps extends PropsWithChildren {
  /** Style to be passed to the wrapping div. */
  className?: string;
}

/**
 * Wraps children in a div that scrolls to anchor on interaction, if the anchor
 * is accessible in context.
 */
export const WithAnchorScroll: FC<IWithAnchorScrollProps> = ({
  children,
  className
}) => {
  const { scrollTo } = useAnchor();

  return (
    <div
      className={className ?? ''}
      style={{ cursor: 'pointer' }}
      onClick={scrollTo ?? undefined}
      onKeyDown={
        scrollTo
          ? (event) => {
              if (event.key === 'Enter') {
                scrollTo(event);
              }
            }
          : undefined
      }
      role="link"
      tabIndex={0}
    >
      {children}
    </div>
  );
};
