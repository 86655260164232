import { FunctionComponent } from 'react';
import { Slider, Slide, ISliderProps } from '../../../../Slider';

import { IContentItem, ContentItemModel } from '@/services/models/Content';
import {
  Breakpoints,
  Breakpoint,
  Default
} from '../../../../core-ui/Breakpoints';
import { Link } from '../../../../core-ui/Link';
import { FragmentParser } from '../../../../utility/FragmentParser';

import { TextBlock } from '../../core/TextBlock';
import { ContentImage } from '../../core/ContentImage';
import { getCustomStyling } from '../../../utils/styling-utils';

import S from './styles.module.scss';

export interface IImageSliderProps {
  /** The content item. */
  item: IContentItem;
}

/**
 * An image slider that displays a title and subtitle below the images.
 * Each slide can have a link, an image, a title and a subtitle.
 */
export const ImageSlider: FunctionComponent<IImageSliderProps> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const { custom, items, settings } = itemModel;
  const styling = getCustomStyling(custom);

  const sliderProps: ISliderProps = {
    direction: 'horizontal',
    loop: true,
    navigation: false,
    centeredSlides: true,
    spaceBetween: 20,
    slidesPerView: 'auto',
    pagination: {
      inset: false,
      buttonClassName: S.paginationButton,
      activeClassName: S.paginationButtonActive
    },
    height: 'auto'
  };

  const phoneProps: ISliderProps = {
    slidesPerView: 1.2
  };

  // The images have the same aspect ratio for both phones and desktops
  // if they were to differ, we would need duplicates of items with correct aspect
  // ratios set
  item.items?.forEach((item) => {
    itemModel.items?.forEach((item) => {
      const { image } = item;
      if (image) {
        // As per the requirements, the aspect ratio is set in the template file and
        // can not be modified from the content service.
        image.aspectRatio = 'portrait_ratio3x5';
      }
    });
  });

  const slides = items?.map((item, index) => {
    const key = `${item.id}_${index}`;
    const { image, title, text } = item;

    if (!image) {
      return null;
    }

    return (
      <Slide key={key}>
        <Link href={item.link?.href} variant="text">
          <div className={S.slideContent}>
            <ContentImage image={image} />
            <div className={S.headlines}>
              <h2>{title}</h2>
              <FragmentParser string={text ?? ''} />
            </div>
          </div>
        </Link>
      </Slide>
    );
  });

  const headline = (
    <TextBlock
      className={S.headline}
      item={{
        ...item,
        links: [] // A hack to prevent rendering of a button
      }}
    />
  );

  return (
    <Breakpoints>
      <Breakpoint media="phone">
        {headline}
        <Slider {...sliderProps} {...phoneProps} style={styling}>
          {slides}
        </Slider>
      </Breakpoint>
      <Default>
        {headline}
        <Slider {...sliderProps} style={styling}>
          {slides}
        </Slider>
      </Default>
    </Breakpoints>
  );
};
