'use client';

import type { FC } from 'react';
import { IBoxProps, allowedElements } from './IBoxProps';

/**
 * Customized next/link with analytics and composbale styling.
 * @throws - If dynamic tag is not on the allowed list.
 */
export const Box: FC<IBoxProps> = ({
  as: Tag = 'div',
  onClick,
  onKeyPress,
  children,
  ariaLabel,
  ...rest
}) => {
  if (!allowedElements.includes(Tag)) {
    throw new Error(Tag + ' is not allowed as a dynamic tag');
  }

  return (
    <Tag
      onClick={onClick ?? undefined}
      onKeyUp={onKeyPress ?? onClick ?? undefined}
      aria-label={ariaLabel}
      {...rest}
    >
      {children}
    </Tag>
  );
};
