'use client';

import type { IContentItem } from '@/services/models/Content';
import type { AspectRatioName } from '@/services/models/Media/ContentImage';
import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { ContentItemModel } from '@/services/models/Content';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { ISliderProps, Slide, Slider } from '../../../../Slider';
import { getCustomStyling } from '../../../utils/styling-utils';
import { ContentMedia } from '../../core/ContentMedia';

import S from './styles.base.module.scss';
import INestableContent from '../../../traits/INestableContent';
import { getComponents } from '../../../utils/getComponent';

export interface IPDPSliderProps extends INestableContent {
  /**
   * The {@link IContentItem} from which the width and height data and image
   * sources are extracted from to render the lifestyle slides.
   */
  item: IContentItem;
}

const DEFAULT_ASPECT_RATIO = 'portrait_ratio1x1' as AspectRatioName;
const DEFAULT_HEIGHT_CONTAIN = 600;
const DEFAULT_WIDTH_CONTAIN = undefined;
/**
 * A slider intended to show product lifestyle images on the PDP.
 */
export const PdpSlider: FunctionComponent<IPDPSliderProps> = ({
  item,
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);
  const { custom, items, title } = itemModel;
  const ref = useRef<HTMLDivElement>(null);
  const styling = getCustomStyling(custom);

  const useLargerDesktopTiles = useBrandLocaleValue<boolean>(
    () => ({
      default: false,
      AHNU: true
    }),
    []
  );

  let {
    widthContain = DEFAULT_WIDTH_CONTAIN,
    heightContain = DEFAULT_HEIGHT_CONTAIN
  } = custom as Record<string, number>;
  const { mainAspectRatio = DEFAULT_ASPECT_RATIO } = custom as Record<
    string,
    AspectRatioName
  >;
  const { width, height } = useWindowSize();

  if (useLargerDesktopTiles && width && width > 1815) {
    heightContain = 640;
  }

  /**
   * SlidesPerView takes in imageWidth and imageHeight as params
   * to calculate the number of slides to show. Checks if current image height
   * is under specified max-height (set to arbitrary 400px height for now).
   * @param imageWidth - Width of the image from ContentItemModel.
   * @param imageHeight - Height of the image from ContentItemModel.
   * @returns SlidesPerView - number of slides to show in the slider.
   */
  const slidesPerView = useCallback(
    (baseImageWidth: number, baseImageHeight: number) => {
      if (width && baseImageWidth && baseImageHeight && ref.current) {
        const maxImageHeight = heightContain || DEFAULT_HEIGHT_CONTAIN;
        // Defaults to size of window
        const maxContainerWidth = widthContain || ref.current.offsetWidth;
        const imageWidth = maxImageHeight * (baseImageWidth / baseImageHeight);

        const slidesPerView = maxContainerWidth / imageWidth;
        // SlidePerView should round to closest 0.5
        return Math.ceil(slidesPerView);
      }

      return 1;
    },
    [width]
  );
  /**
   * Generates slides for the slider when slidesPerView changes.
   * @returns {@link Slide}S - array of slides to be rendered in the slider.
   */
  const slides = useMemo(() => {
    return items?.map((item, index) => {
      const key = `${item.id}_${index}`;
      const { image, video } = item;
      if (image) {
        image.aspectRatio = mainAspectRatio;
        image.activeAspectRatio = mainAspectRatio;
      } else if (video) {
        video.aspectRatio = mainAspectRatio;
        video.activeAspectRatio = mainAspectRatio;
      }
      return (
        <Slide key={key}>
          <div className={S.slideContent}>{getComponents(item)}</div>
        </Slide>
      );
    });
  }, [slidesPerView]);

  /**
   * Retrieves a property from the first slide in the slider.
   * @param property - The property to retrieve.
   * @returns The value of the property.
   */
  const slideSize = useCallback(
    (key: keyof ContentItemModel['image']) => {
      if (width) {
        const imageProperty = items?.[0]?.image?.[key];
        return imageProperty ?? 0;
      }
      return 0;
    },
    [width]
  );

  const sliderProps = useBrandLocaleValue<ISliderProps>(
    () => ({
      default: {
        direction: 'horizontal',
        loop: true,
        centeredSlides: false,
        navigation: {
          inset: true,
          arrowVariant: 'arrow',
          buttonClassName: S.navigation
        },
        height: 'auto'
      },
      AHNU: {
        height: 'auto',
        loop: false,
        navigation: false,
        watchOverflow: true,
        wrapperClassName: S.wrapper,
        navigationWrapperClassName: S.navigationWrapper,
        pagination: {
          inset: false,
          activeClassName: S.activeCircle,
          wrapperClassName: S.pagination,
          buttonClassName: S.circle,
          direction: 'horizontal',
          clickable: true
        }
      }
    }),
    [slides]
  );

  return (
    <div className={S.outerContainer}>
      <div ref={ref} className={S.contentContainer}>
        {title && <h2 className={S.title}>{title}</h2>}
        {width && height && (
          <Slider
            key={`${item.id}_slider`}
            {...sliderProps}
            initialSlide={0}
            slidesPerView={
              slidesPerView(
                slideSize('width' as keyof ContentItemModel['image']),
                slideSize('height' as keyof ContentItemModel['image'])
              ) as number
            }
            sliderClassName={S.sliderContainer}
            style={styling}
          >
            {slides}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default PdpSlider;
