import { FunctionComponent } from 'react';

import {
  IContentItem,
  ContentItemModel,
  ContentPositionX,
  ContentPositionY
} from '@/services/models/Content';
import { Link } from '@/react/components/core-ui/Link';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { Button, ButtonVariant } from '@/react/components/core-ui/Button';
import {
  Breakpoint,
  Breakpoints,
  Default
} from '@/react/components/core-ui/Breakpoints';
import { ContentImageModel } from '@/services/models/Media/ContentImage';
import { ContentVideoModel } from '@/services/models/Media/ContentVideo';
import { ContentMedia } from '@/react/components/cms-content/components/core/ContentMedia';
import { getItemOverlay } from '../../../utils';

import S from './styles.base.module.scss';

/**
 * The interface defining the props accepted by LinkedMedia.
 */
export interface ILinkedMedia {
  /** The content item, will contain any number of images/videos. */
  item: IContentItem;
}

/**
 * Linked Media represents a video or image that houses either:
 * A: A CTA
 * B: A Link without CTA.
 * *
 * If a CTA is specified, the component will display a button given a specific x/y position.
 * If not, the media itself will be wrapped in a link.
 */
export const LinkedMedia: FunctionComponent<ILinkedMedia> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const { link, settings } = itemModel;
  if (!link) return null;
  const { href, isCTA } = link;
  /**
   * This is a stopgap solution to handle the case where a content item has both
   * an image and a video.
   * In the future, this should be handled in the Content Factory.
   */
  let media: Array<ContentImageModel | ContentVideoModel> = [];

  if (itemModel.image && itemModel.images) {
    media = itemModel.images;
  }

  if (itemModel.video && itemModel.videos) {
    media = [...media, ...itemModel.videos];
  }
  /**
   * Make style available for nested content.
   * For now, the text classes will be used for the nested content.
   */
  const overlay = getItemOverlay(settings);

  let positionXClass: string;
  let positionYClass: string;
  switch (overlay.positionX) {
    case ContentPositionX.Left: {
      positionXClass = S.left;
      break;
    }
    case ContentPositionX.Right: {
      positionXClass = S.right;
      break;
    }
    default: {
      positionXClass = S.centerX;
      break;
    }
  }

  switch (overlay.positionY) {
    case ContentPositionY.Top: {
      positionYClass = S.top;
      break;
    }
    case ContentPositionY.Bottom: {
      positionYClass = S.bottom;
      break;
    }
    default: {
      positionYClass = S.centerY;
      break;
    }
  }

  return (
    <>
      {isCTA ? (
        <div className={S.wrapper}>
          <Breakpoints>
            <Breakpoint media={['phone']}>
              {media && media.length > 0 && (
                <ContentMedia item={media.length > 1 ? media[1] : media[0]} />
              )}
            </Breakpoint>
            <Default>
              {media && media.length > 0 && <ContentMedia item={media[0]} />}
            </Default>
          </Breakpoints>
          <Link
            className={classes(S.link, positionXClass, positionYClass)}
            variant="text"
            href={href}
          >
            <Button
              className={classes(S.button)}
              size="lg"
              variant={overlay.cta as ButtonVariant}
            >
              {link.text}
            </Button>
          </Link>
        </div>
      ) : (
        <Link href={href}>
          <Breakpoints>
            <Breakpoint media={['phone']}>
              {media && media.length > 0 && (
                <ContentMedia item={media.length > 1 ? media[1] : media[0]} />
              )}
            </Breakpoint>
            <Default>
              {media && media.length > 0 && <ContentMedia item={media[0]} />}
            </Default>
          </Breakpoints>
        </Link>
      )}
    </>
  );
};
