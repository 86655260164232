import { FunctionComponent } from 'react';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { AspectRatioName } from '@/services/models/Media/ContentImage';

import classNames from 'classnames';
import {
  Breakpoint,
  Breakpoints,
  Default
} from '../../../../core-ui/Breakpoints';
import { setAspectRatioOverrides, TextBlock } from '../../..';
import INestableContent from '../../../traits/INestableContent';
import { TextWithBackground } from '../../core/TextWithBackground';
import { ImageBlock } from '../../core/ImageBlock';
import {
  getContainerOptions,
  getCustomStyling
} from '../../../utils/styling-utils';
import S from './styles.module.scss';

/**
 * Hero Banner constants.
 * */
const DEFAULT_ASPECT_RATIO = 'landscape_ratio4x3' as AspectRatioName;
const DEFAULT_MOBILE_ASPECT_RATIO = 'landscape_ratio2x1' as AspectRatioName;

/**
 * Core styling for Hero Banner component.
 */
interface IStyling {
  /** ClassName based on variant type. */
  className: string;
  /** Aspect ratio based on variant type. */
  imageModelAspectRatio?: AspectRatioName;
}

/**
 * Get the core styling from the variant string.
 * @param variant - String that represents a hero banner styling type.
 * @returns Returns an object containing `className` and `imageModelAspectRatio` from the styling import.
 */
const getStylingFromVariant = (variant: HeroBannerVariant): IStyling => {
  switch (variant) {
    case 'bottom-content-slim':
      return {
        className: S.bottomContentSlim,
        imageModelAspectRatio: DEFAULT_MOBILE_ASPECT_RATIO
      };
    case 'bottom-banner-overlay':
      return {
        className: S.bottomBannerOverlay
      };
    default:
      return {
        className: S.heroBanner
      };
  }
};

/**
 * As Hero Banner has the same structure as Bottom Content Slim,
 * the same component is shared for both, so we use the variant
 * type to decide which style to use:
 *
 * `default`: Will show a higher view, which can contain a headline,
 *  body, CTA and 2 background images.
 * `bottom-content-slim`: Will show a flatter view, containing a body
 *  as a headline and CTA left aligned and 2 background images.
 */
export type HeroBannerVariant =
  | 'default'
  | 'bottom-content-slim'
  | 'bottom-banner-overlay';

export interface IHeroProps {
  /** The content item. */
  item: IContentItem;
  /** Style type of hero banner. */
  variant?: HeroBannerVariant;
}

/** Main hero banner.  */
export const HeroBanner: FunctionComponent<IHeroProps> = ({
  item,
  variant = 'default'
}) => {
  const itemModel = ContentItemModel.from(item);
  const imageModel = itemModel.image;
  const { custom } = itemModel;

  const options = getContainerOptions(custom);
  const styling = getCustomStyling(custom);
  const stylingFromVariant = getStylingFromVariant(variant);

  const classes = classNames(
    S.container,
    options.flip ? S.flip : '',
    stylingFromVariant.className
  );

  const hasBackgroundImage =
    itemModel.images?.[1] !== null && itemModel.images?.[1] !== undefined;

  const hasMobileImage =
    itemModel.images?.[2] !== null && itemModel.images?.[2] !== undefined;

  if (itemModel.image && itemModel.images) {
    setAspectRatioOverrides(itemModel.image, custom);
    if (hasMobileImage) {
      setAspectRatioOverrides(itemModel.images[2], custom);
    }
  }

  return (
    <div className={classes} style={styling}>
      {
        // If there is no background image, we need to add a background color
        // to the container, so we can see the text.
        hasBackgroundImage ? (
          <TextWithBackground
            className={S.heroText}
            variant={
              variant === 'bottom-content-slim' ? 'large-headline' : 'default'
            }
            item={itemModel.item ?? itemModel}
            backgroundImage={itemModel.images?.[1]}
          />
        ) : (
          <TextBlock
            className={S.heroText}
            variant={
              variant === 'bottom-banner-overlay' ? 'overlay-mobile' : 'default'
            }
            item={itemModel.item ?? itemModel}
          />
        )
      }
      <Breakpoints>
        {imageModel && (
          <Breakpoint media={['md', 'lg']}>
            <ImageBlock className={S.heroImage} image={imageModel} />
          </Breakpoint>
        )}
        {imageModel && (
          <Default>
            <ImageBlock className={S.heroImage} image={imageModel} />
          </Default>
        )}
      </Breakpoints>
    </div>
  );
};
