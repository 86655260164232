/**
 * Enumerates valid [Cloudinary Delivery Types](https://cloudinary.com/documentation/image_transformations#delivery_types).
 *
 * **From the docs:**
 * The _delivery type_ element of the URL provides an indication about the way the asset will be delivered, although in most cases, the delivery type value is determined at the time that the asset is stored in your account.
 */
enum CloudinaryDeliveryType {
  /** The default delivery type for uploaded assets. In most cases, this delivery type indicates that the asset is publicly available. However, there are options you can use to restrict access to assets with an upload delivery type. This includes strict transformations and access mode settings). */
  Upload = 'upload',

  /** The URL for the original asset can be accessed only with a signed URL. Transformed versions of the asset are publicly available (unless strict transformations are also defined). */
  Private = 'private',

  /** Both original and transformed versions of the asset can be accessed only with a signed URL or an authentication token. */
  Authenticated = 'authenticated',

  /** Generates a list of assets that have a specified tag. */
  List = 'list',

  /** Enables transforming and delivering remote images on the fly.	Fetch remote images a delivery type in the classic sense, in that it doesn't relate to an individual asset. However, to use this feature, you specify list in the delivery type element of the URL. */
  Fetch = 'fetch',

  // Social media profile pictures
  /** Fetches a profile picture from Facebook, Twitter, or other specified social delivery type based on a specified ID or name. */
  Facebook = 'facebook',
  Twitter = 'twitter',
  TwitterName = 'twitter_name',
  Gravatar = 'gravatar',

  // Fetching thumbnails of public videos

  /** Delivers a thumbnail image generated from the specified video from a public video site. */
  YouTube = 'youtube',
  Hulu = 'hulu',
  Vimeo = 'vimeo',
  Animoto = 'animoto',
  Worldstarhiphop = 'worldstarhiphop',
  Dailymotion = 'dailymotion',

  /** Enables you to deliver a single animated image (GIF, PNG or WebP) from all image assets that have been assigned a specified tag. */
  Multi = 'multi',

  /** Enables you to generate an image in your account from a specified textual string along with style parameters for use as an image overlay. */
  Text = 'text',
  Asset = 'asset'
}

export default CloudinaryDeliveryType;
