/**
 * Image object interface.
 * @property image - The image.
 * @property path - The image path.
 * @property loaded - Whether the image has been loaded.
 * @returns An object containing image object properties.
 */
export interface IImageObject {
  /** Image element. */
  image: HTMLImageElement;
  /** Image path. */
  path: string;
  /** Whether the image is loaded yet. */
  loaded: boolean;
}

/**
 * Canvas dimensions interface.
 * @property width - The width of the canvas.
 * @property height - The height of the canvas.
 * @returns An object containing canvas dimensions.
 */
interface ICanvasDimensions {
  /** The width of the canvas. */
  width: number;
  /** The height of the canvas. */
  height: number;
}

/**
 * Interface for the return type of useAnimUtils function.
 */
interface IAnimUtils {
  /**
   * Function to build a sequence of image paths that are based on
   * a base name string, a numeric indicator, and optional parameters.
   * @param basePath - The base path of the images.
   * @param baseFileName - The base filename of the images.
   * @param numImages - The number of images.
   * @param startNum - The starting number for the images.
   * @param numPadding - The padding for the number.
   * @param width - The width of the images (used for directory name).
   * @param ext - The extension of the images.
   * @returns An array of image paths.
   */
  getImagePaths: (
    basePath: string,
    baseFileName: string,
    numImages: number,
    startNum?: number,
    numPadding?: number,
    width?: number | null,
    ext?: string
  ) => Array<string>;

  /**
   * Function to get preloaded images.
   * @param imagePaths - The paths of the images.
   * @returns An array of image objects.
   */
  getPreloadedImages: (
    imagePaths: Array<string> | undefined
  ) => Array<IImageObject>;

  /**
   * Function to get fitted canvas dimensions.
   * @param canvasWrapRef - The reference to the canvas wrapper element.
   * @param ratio - The ratio for the dimensions.
   * @returns The dimensions of the canvas.
   */
  getFittedCanvasDimensions: (
    canvasWrapRef: React.RefObject<HTMLElement>,
    ratio: number
  ) => ICanvasDimensions;

  /**
   * Function to draw on the canvas.
   * @param canvas - The canvas to draw on.
   * @param imgObj - The image object to draw.
   */
  draw: (canvas: HTMLCanvasElement | null, imgObj: IImageObject | null) => void;

  /**
   * Function to get the color based on the time of day.
   * @returns The color as a string.
   */
  getTimeOfDayColor: () => string;
}

/**
 * Function to build a sequence of image paths that are based on
 * a base name string, a numeric indicator, and optional parameters.
 * @param basePath - The base path of the images.
 * @param baseFileName - The base filename of the images.
 * @param numImages - The number of images.
 * @param startNum - The starting number for the images.
 * @param numPadding - The padding for the number.
 * @param width - The width of the images (used for directory name).
 * @param ext - The extension of the images.
 * @returns An array of image paths.
 */
export const getImagePaths = (
  basePath: string,
  baseFileName: string,
  numImages: number,
  startNum: number = 1,
  numPadding: number = 0,
  width: number | null = null,
  ext: string = 'webp'
): Array<string> => {
  const images: Array<string> = [];
  for (let i = 0; i < numImages; i++) {
    const num = (startNum + i).toString().padStart(numPadding, '0');
    const image = `${basePath}${width}/${baseFileName}${num}.${ext}`;
    images.push(image);
  }
  return images;
};

/**
 * Function to get preloaded images.
 * @param imagePaths - The paths of the images.
 * @returns An array of image objects.
 */
export const getPreloadedImages = (
  imagePaths: Array<string> | undefined
): Array<IImageObject> => {
  return imagePaths
    ? imagePaths?.map((path, index) => {
        const image = new Image();
        const imgObj: IImageObject = {
          image,
          path,
          loaded: false
        };
        image.addEventListener('load', () => {
          imgObj.loaded = true;
        });
        if (index === 0 && image.fetchPriority) {
          image.fetchPriority = 'high';
        }
        image.src = path;
        return imgObj;
      })
    : [];
};

/**
 * Function to get fitted canvas dimensions.
 * @param canvasWrapRef - The reference to the canvas wrapper element.
 * @param ratio - The ratio for the dimensions.
 * @returns The dimensions of the canvas.
 */
export const getFittedCanvasDimensions = (
  canvasWrapRef: React.RefObject<HTMLElement>,
  ratio: number
): ICanvasDimensions => {
  const canvasWrap = canvasWrapRef.current;
  if (canvasWrap) {
    canvasWrap.style.width = '';
    canvasWrap.style.height = '';
  }
  let width = canvasWrap?.offsetWidth;
  let height = canvasWrap?.offsetHeight;
  if (canvasWrap) {
    canvasWrap.style.width = 'var(--canvas-width)';
    canvasWrap.style.height = 'var(--canvas-height)';
  }
  if (!width || !height) {
    return { width: width ?? 0, height: height ?? 0 };
  }
  if (width * ratio > height) {
    width = height / ratio;
  }
  height = width * ratio;
  return {
    width: Math.round(width),
    height: Math.round(height)
  };
};

/**
 * Function to draw on the canvas.
 * @param canvas - The canvas to draw on.
 * @param imgObj - The image object to draw.
 */
export const draw = (
  canvas: HTMLCanvasElement | null,
  imgObj: IImageObject | null
): void => {
  if (canvas?.getContext && imgObj?.loaded) {
    drawImageToCanvas(canvas, imgObj?.image);
  } else {
    const img = new Image();
    img.addEventListener(
      'load',
      () => {
        if (canvas) {
          drawImageToCanvas(canvas, img);
        }
      },
      false
    );
    img.src = imgObj?.path ?? '';
  }
};

/**
 * Function to draw an image to a canvas.
 * @param canvas - The canvas to draw on.
 * @param image - The image to draw.
 */
export const drawImageToCanvas = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement
): void => {
  const ctx = canvas?.getContext('2d');
  ctx?.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);
  ctx?.drawImage(image, 0, 0, canvas.offsetWidth, canvas.offsetHeight);
};

/**
 * Function to get the color based on the time of day.
 * @returns The color as a string.
 */
export const getTimeOfDayColor = (): string => {
  const noon = 43200;
  const now = new Date();
  const midnight = new Date(now);
  midnight.setHours(0, 0, 0, 0);
  const timeInSeconds = Math.floor((now.getTime() - midnight.getTime()) / 1000);
  const progressToNoon = 1 - Math.abs(noon - timeInSeconds) / noon;
  const timeOfDayColor = `hsl(0, 0, ${Math.round(progressToNoon * 100)})`;
  return timeOfDayColor;
};
