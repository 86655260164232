import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';

/**
 * Determines whether the user prefers "reduced motion". This function should be used
 * to ascertain the user's preference before running any logic in JS / TS which would
 * display an animation. This should also override any prop passed to a component
 * which would dictate that the component animate.
 * @returns Whether the user prefers "reduced motion".
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 */
export const userPrefersReducedMotion = (): boolean =>
  (typeof window !== "undefined")
    ? window.matchMedia('(prefers-reduced-motion: reduce)').matches
    : false;
