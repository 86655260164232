'use client';

import { useRef, RefObject, FunctionComponent } from 'react';
import { useInView } from 'framer-motion';
import type { IContentItem } from '@/services/models/Content';
import type { AspectRatioName } from '@/services/models/Media/ContentImage';
import { ContentItemModel } from '@/services/models/Content';
import { ContentImageModel } from '@/services/models/Media/ContentImage';
import { ContentVideoModel } from '@/services/models/Media/ContentVideo';
import { ContentMedia } from '../../../core/ContentMedia';
import { FragmentParser } from '../../../../../utility/FragmentParser';
import { Button } from '../../../../../core-ui/Button';
import { Link } from '../../../../../core-ui/Link';
import { useAnimateElements } from '../../../../../../hooks/useAnimateElements';
import S from './styles.module.scss';
import { v4 as uuidv4 } from 'uuid';
export interface ISlideContentProps {
  /**
   * The {@link IContentItem} from which the text and link content
   * for the slide are pulled.
   */
  item: ContentItemModel;

  /**
   * Aspect ratio of the media element.
   */
  aspectRatio: AspectRatioName;

  /**
   * Screen size reference - determines which image to grab.
   */
  size: string;

  /**
   * Whether the slide is currently active.
   */
  isActive: boolean;

  /**
   * Callback function for hiding the drag cursor (if used).
   * @param isHidden - Whether the cursor should be hidden.
   */
  onHideCursor?: (isHidden: boolean) => void;
}

/**
 * Progress indicator component for a video element.
 * @param progress - Current progress of the video (0-1 value).
 * @returns SlideContent component.
 */
export const SlideContent: FunctionComponent<ISlideContentProps> = ({
  item,
  aspectRatio,
  size,
  isActive,
  onHideCursor
}) => {
  /**
   * Value used by Link component to designate contained button as the
   * link label.
   */
  const linkAriaLabelId = uuidv4();
  const { images, videos } = item;
  let responsiveSourceItem: ContentImageModel | ContentVideoModel | undefined;
  // If an images or videos array is present, use the asset corresponding
  // to the current screen size. Otherwise, use the single image or video.
  if (images && images.length > 0) {
    if (size === 'desktop') {
      [responsiveSourceItem] = images;
    } else {
      [, responsiveSourceItem] = images;
    }
  } else if (videos && videos.length > 0) {
    if (size === 'desktop') {
      [responsiveSourceItem] = videos;
    } else {
      [, responsiveSourceItem] = videos;
    }
  }
  if (responsiveSourceItem) {
    responsiveSourceItem.aspectRatio = aspectRatio;
    responsiveSourceItem.activeAspectRatio = aspectRatio;
  } else {
    const { image, video } = item;
    if (image) {
      image.aspectRatio = aspectRatio;
      image.activeAspectRatio = aspectRatio;
    } else if (video) {
      video.aspectRatio = aspectRatio;
      video.activeAspectRatio = aspectRatio;
    }
  }

  // Set up animation for the slide content.
  const containerRef = useRef(null);
  const isInView = useInView(containerRef as RefObject<Element>, {
    amount: 0.85,
    once: false
  });

  // This approach allows us to deal with different types of content,
  // which may include nested elements that need to be individually
  // animated.
  const contentScope = useAnimateElements(isActive && isInView, [
    'h3',
    'p',
    'button'
  ]);

  return (
    <div className={S.container} ref={containerRef}>
      <div className={S.contentWrap} ref={contentScope}>
        {item?.title && (
          <h3 className={S.heading}>
            <FragmentParser string={item.title ?? ''} />
          </h3>
        )}
        {item?.text && (
          <div className={S.copy}>
            <FragmentParser string={item.text ?? ''} />
          </div>
        )}
        {item?.link && (
          <>
            {/* Container with handlers for DragCursor hide/show, if used. */}
            <div
              onMouseEnter={onHideCursor ? () => onHideCursor(true) : undefined}
              onMouseLeave={
                onHideCursor ? () => onHideCursor(false) : undefined
              }
              role="presentation"
            >
              <Link className={S.link} ariaLabelledBy={linkAriaLabelId} variant="text" href={item?.link.href}>
                <Button id={linkAriaLabelId} title={item?.title ?? item?.link.text ?? ""} className={S.button} variant="secondary">
                  {item?.link.text}
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
      <ContentMedia
        item={responsiveSourceItem ?? item}
        className={S.fullHeightMobile}
      />
    </div>
  );
};
