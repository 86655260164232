'use client';

import { CSSProperties, FunctionComponent } from 'react';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { AspectRatioName } from '@/services/models/Media/ContentImage';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { ContentMedia } from '../../core/ContentMedia';
import { Link } from '../../../../core-ui/Link';
import { FragmentParser } from '../../../../utility/FragmentParser';
import { getItemOverlay } from '../../../utils';
import {
  Breakpoints,
  Breakpoint,
  Default
} from '../../../../core-ui/Breakpoints';

import { WithAnchorScroll } from '../../../../core-ui/Anchor/WithAnchorScroll';
import S from './styles.module.scss';

const DEFAULT_ASPECT_RATIO = 'portrait_ratio3x4' as AspectRatioName;

export interface ISwappableImageProps {
  /** The content item (which should contain two images). */
  item: IContentItem;
}

/**
 * Given two images, renders an the first image that gets swapped with
 * the second image on hover (For Desktop).
 * For tablets or mobiles, will render a third image distinct from the first two.
 * Otherwise, will render the second image.
 */
export const SwappableImage: FunctionComponent<ISwappableImageProps> = ({
  item
}) => {
  const itemModel = ContentItemModel.from(item);
  const {
    title,
    text = '',
    link,
    images = [],
    videos = [],
    custom,
    settings
  } = itemModel;

  const overlay = getItemOverlay(settings);

  const {
    mainAspectRatio = DEFAULT_ASPECT_RATIO,
    mobileAspectRatio = DEFAULT_ASPECT_RATIO
  } = custom as Record<string, AspectRatioName>;

  const { order = 0 } = custom as Record<string, number>;

  const _videos = videos.map((video) => {
    video.aspectRatio = mainAspectRatio ?? DEFAULT_ASPECT_RATIO;
    video.activeAspectRatio = mainAspectRatio ?? DEFAULT_ASPECT_RATIO;
    video.mobileAspectRatio = mobileAspectRatio ?? DEFAULT_ASPECT_RATIO;
    return {
      media: video,
      type: 'video'
    };
  });

  const _images = images.map((image) => {
    image.aspectRatio = mainAspectRatio ?? DEFAULT_ASPECT_RATIO;
    image.activeAspectRatio = mainAspectRatio ?? DEFAULT_ASPECT_RATIO;
    image.mobileAspectRatio = mobileAspectRatio ?? DEFAULT_ASPECT_RATIO;
    return {
      media: image,
      type: 'image'
    };
  });
  let media = [..._videos, ..._images];

  if (order === 1) {
    media = [..._images, ..._videos];
  }

  const enhancedDisplay = useBrandLocaleValue<boolean>(
    () => ({
      default: false,
      AHNU: true
    }),
    []
  );

  if (enhancedDisplay) {
    const textOverlay = (
      <div
        className={S.textOverlay}
        style={{ ...overlay.props } as CSSProperties}
      >
        <div className={S.textOverlayContent}>
          <div className={classes(S.title, overlay.headline)}>{title}</div>
          <div className={classes(S.body, overlay.text)}>
            <FragmentParser string={text} />
          </div>
        </div>
      </div>
    );

    return (
      <WithAnchorScroll>
        <div className={S.container}>
          <Breakpoints>
            <Breakpoint media="desktop">
              <div className={S.enhancedHoverWrapper}>
                <Link ariaLabel={`Link to ${title}`} href={link?.href ?? ''}>
                  <div className={S.image}>
                    {/* Shoe */}
                    {media[0]?.media && <ContentMedia item={media[0].media} />}
                  </div>
                  <div className={S.enhancedHoverImage}>
                    {/* Lifestyle */}
                    {media[1]?.media && <ContentMedia item={media[1].media} />}
                  </div>
                  <div
                    className={S.textOverlay}
                    style={{ ...overlay.props } as CSSProperties}
                  >
                    <div className={S.textOverlayContent}>
                      <div className={classes(S.title, overlay.headline)}>
                        {title}
                      </div>
                      <div className={classes(S.body, overlay.text)}>
                        <FragmentParser string={text} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </Breakpoint>
            <Default>
              {media.length > 2 ? (
                <Link ariaLabel={`Link to ${title}`} href={link?.href ?? ''}>
                  <div className={S.image}>
                    {/* Mobile Shoe */}
                    <ContentMedia item={media[2].media} />
                  </div>
                  {textOverlay}
                </Link>
              ) : (
                <Link ariaLabel={`Link to ${title}`} href={link?.href ?? ''}>
                  <div className={S.image}>
                    {/* Lifestyle */}
                    <ContentMedia item={media[1].media} />
                  </div>
                  {textOverlay}
                </Link>
              )}
            </Default>
          </Breakpoints>
        </div>
      </WithAnchorScroll>
    );
  }

  // Get media as content items
  return (
    <WithAnchorScroll>
      <Breakpoints>
        <Breakpoint media="phone">
          {media.length > 2 ? (
            <div className={S.hoverWrapper}>
              <div className={S.image}>
                <ContentMedia item={media[2].media} />
              </div>
            </div>
          ) : (
            <div className={S.hoverWrapper}>
              <div className={S.image}>
                <ContentMedia item={media[1].media} />
              </div>
            </div>
          )}
        </Breakpoint>
        <Default>
          <div className={S.hoverWrapper}>
            <div className={S.image}>
              {media[0]?.media && <ContentMedia item={media[0].media} />}
            </div>
            <div className={S.image}>
              {media[1]?.media && <ContentMedia item={media[1].media} />}
            </div>
          </div>
        </Default>
      </Breakpoints>
    </WithAnchorScroll>
  );
};
