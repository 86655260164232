'use client';

import { FunctionComponent, useState, useCallback } from 'react';

import { Button } from '../../../../core-ui/Button';
import { Modal } from '../../../../core-ui/Modal';
import { ModalHeader } from '../../../../core-ui/Modal/ModalHeader';
import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { FragmentParser } from '../../../../utility/FragmentParser';

import { TextWithLink } from './TextWithLink';

import S from './styles.module.scss';
import type INestableContent from '../../../traits/INestableContent';

export interface ITextBannerProps extends INestableContent {
  /** Content Item Model used for content options. */
  item: IContentItem;
}

/**
 * A sticky TextBanner that currently encapsulates two components:
 *
 * 1. The header sticky text banner, which hides after scrolling down and appears
 * after scrolling up.
 *
 * 2. The footer banner that sticks to the bottom of the screen, until the
 * footer is reached, after which it sticks to the top of the footer.
 */
export const TextBanner: FunctionComponent<ITextBannerProps> = ({
  item,
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);
  const { text, link, settings } = itemModel;
  const { style } = settings ?? {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <div className={S.text}>
        {itemModel.item ? (
          <Button onClick={handleModalOpen} variant="text">
            <FragmentParser string={text ?? ''} />
          </Button>
        ) : (
          <TextWithLink text={text ?? ''} link={link} />
        )}
      </div>

      {itemModel?.item && (
        <Modal
          className={S.modal}
          isOpen={isModalOpen}
          onClose={handleModalClose}
        >
          <ModalHeader className={S.modalHeader} onClose={handleModalClose}>
            {text && <TextWithLink text={text} link={link} />}
          </ModalHeader>
          <div className={S.modalBody}>{contentResolver(itemModel.item)}</div>
        </Modal>
      )}
    </>
  );
};
