import { FunctionComponent } from 'react';

import { IContentItem, ContentItemModel } from '@/services/models/Content';
import { Link } from '../../../../core-ui/Link';
import { Slider, Slide } from '../../../../Slider';
import { Button, ButtonVariant } from '../../../../core-ui/Button';
import {
  Breakpoint,
  Breakpoints,
  Default
} from '../../../../core-ui/Breakpoints';
import { FragmentParser } from '../../../../utility/FragmentParser';

import { ContentImage } from '../../core/ContentImage';
import { getItemOverlay } from '../../../utils/styling-utils';

import S from './styles.module.scss';

export interface IFiveImageSliderProps {
  /** The collection item, will contain five teasers. */
  item: IContentItem;
}

/**
 * Five Image Slider should have exactly 5 teasers as children.
 * For each teaser:
 *
 * 1. The picture will be treated as a background image.
 * 2. The text (body) of the teaser will be treated as the heading. We're not
 * using the title of the teaser for the heading because we want to allow
 * the content creators to insert newlines.
 * (A good example is sanuk, which has "Gifts\nFor Her").
 * Even though the body will not actually have newlines but rather a paragraph
 * per line, it still gets the job done.
 * 3. A call to action URL and a text to show (used as the button label).
 *
 * For desktop, 5 teasers are shown as a row.
 * For tablets and phones, this turns into a looped carousel, with 1 teaser fully visible
 * and half of each teasers visible on the left and the right.
 */
export const FiveImageSlider: FunctionComponent<IFiveImageSliderProps> = ({
  item
}) => {
  const itemModel = ContentItemModel.from(item);
  const { items = [] } = itemModel;

  for (const item of items) {
    if (item?.image?.aspectRatio) {
      item.image.aspectRatio = 'portrait_ratio3x4';
    }
  }

  // Render the children (teasers) as usual divs.
  // Those teaser can be used either directly, for the desktop version
  // or wrapped inside of <Slide>-s.
  const teasers = items.map(({ image, text, link, settings, id }) => {
    // Get the settings for this teaser
    const overlay = getItemOverlay(settings);
    const buttonVariant = (overlay.cta || 'primary') as ButtonVariant;

    if (!image) {
      return null;
    }

    return (
      <div className={S.teaser} key={id}>
        <ContentImage image={image} />
        <div className={S.content}>
          <div className={S.heading}>
            <FragmentParser string={text ?? ''} />
          </div>
          {link && (
            <Link variant="text" href={link.href}>
              <Button size="lg" variant={buttonVariant}>
                {link.text}
              </Button>
            </Link>
          )}
        </div>
      </div>
    );
  });

  return (
    <Breakpoints>
      <Breakpoint media="desktop">
        <div className={S.wrapper}>{teasers}</div>
      </Breakpoint>
      <Default>
        <Slider
          loop
          navigation={false}
          pagination={{
            inset: false,
            buttonClassName: S.paginationButton,
            activeClassName: S.paginationButtonActive
          }}
          slidesPerView={2}
          centeredSlides
          height="auto"
          spaceBetween={4}
        >
          {/* Wrap every teaser in a <Slide> component. */}
          {teasers.map((teaser, index) => (
            <Slide key={items[index].id}>{teaser}</Slide>
          ))}
        </Slider>
      </Default>
    </Breakpoints>
  );
};
