'use client';

import { useEffect, RefObject } from 'react';
import {
  useAnimate,
  stagger,
  EasingDefinition,
  StyleKeyframesDefinition
} from 'framer-motion';

import { Nullable } from '@/type-utils';

/**
 * `useAnimateElements` animates child HTML elements found within a container.
 * @param isVisible
 * @param elementTypes - The types of elements to animate (by default, all child elements are selected).
 * @param duration - The duration of each element animation.
 * @param staggerAmount - The amount of time between each element's animation.
 * @param startDelay - The delay before the first element is animated.
 * @param visibleStyle
 * @param hiddenStyle
 * @param easeType
 * @returns A RefObject to be applied to the container.
 */
export const useAnimateElements = (
  isVisible: boolean,
  elementTypes: Array<keyof JSX.IntrinsicElements | ':scope > *'> = [
    ':scope > *'
  ],
  duration: number = 0.7,
  staggerAmount: number = 0.1,
  startDelay: number = 0.0,
  visibleStyle: StyleKeyframesDefinition = {
    opacity: 1,
    transform: 'translateY(0px)'
  },
  hiddenStyle: StyleKeyframesDefinition = {
    opacity: 0,
    transform: 'translateY(40px)'
  },
  easeType: EasingDefinition = 'easeOut'
): RefObject<HTMLDivElement> => {
  const [scope, animate] = useAnimate();
  const staggerElements = stagger(staggerAmount, {
    startDelay,
    ease: easeType
  });
  const elements = elementTypes.join(', ');

  useEffect(() => {
    animate(elements, isVisible ? visibleStyle : hiddenStyle, {
      duration,
      delay: isVisible ? staggerElements : 0
    });
  }, [isVisible]);

  return scope;
};
