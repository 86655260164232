'use client';

import { useDebounce } from '@/react/hooks/useDebounce';
import { ContentItemModel, IContentItem } from '@/services/models/Content';
import {
  motion,
  useInView,
  useScroll,
  useSpring,
  useTransform
} from 'framer-motion';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Button } from '../../../../core-ui/Button';
import { Link } from '../../../../core-ui/Link';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { getCustomStyling } from '../../../utils/styling-utils';
import BackgroundPanel from './BackgroundPanel';
import UserInfo from './UserInfo';
import {
  IImageObject,
  draw,
  getFittedCanvasDimensions,
  getImagePaths,
  getPreloadedImages
} from './animation-utils';

import S from './styles.module.scss';
import { v4 as uuidv4 } from 'uuid';

export interface IImageSequenceRollingProps {
  /** The content item. */
  item: IContentItem;
}

/** Interface for our dimensions object. */
interface IDimensions {
  /** The width. */
  width: number | undefined;
  /** The height. */
  height: number | undefined;
}

/**
 * A component that renders an image sequence animation.
 * @returns The ImageSequenceRolling component.
 */
export const ImageSequenceRolling: FunctionComponent<
  IImageSequenceRollingProps
> = ({ item }) => {
  const ariaLabelledById = uuidv4();
  const itemModel = ContentItemModel.from(item as IContentItem);
  const { custom } = itemModel;

  const styling = getCustomStyling(custom);

  const debounce = useDebounce();

  const windowSize = useWindowSize();

  // console.log(itemModel);

  const CTA_LINK = '/p/Sequence?style=1166650';
  const CTA_LABEL = 'Shop';

  const NUM_SEQ_IMAGES = 29; // Number of images in rotation seq
  const TOTAL_IMAGES = 31; // Total number of shoe images
  // const BASE_NAME = '';
  // const IMG_ASPECT_RATIO = 0.7171875; // -> original (1920 × 1377)
  const IMG_ASPECT_RATIO = 0.75; // -> CMS-ified 4x3

  // Sequence triggers, based on scroll progress
  const MARK_SHOE_ROLL_START = 0.1;
  const MARK_SHOE_ROLL_END = 0.25;
  const MARK_SHOE1_EXIT_START = 0.4;
  const MARK_SHOE2_ENTRY_START = 0.45;
  const MARK_SHOE2_EXIT_START = 0.7;
  const MARK_SHOE3_ENTRY_START = 0.75;

  const ENTRY_DURATION = 0.08;
  const EXIT_DURATION = 0.16;

  const DEFAULT_OFFSET_LEFT = -200;
  const DEFAULT_OFFSET_RIGHT = 100;
  const DEFAULT_OFFSET_CENTER = -50;

  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasWrapRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [seqIndex, setSeqIndex] = useState(0);
  const [dimensions, setDimensions] = useState<IDimensions>({
    width: undefined,
    height: undefined
  });
  const [images, setImages] = useState(Array<IImageObject>);

  const [screenWidth, setScreenWidth] = useState(windowSize.width ?? 0);
  const [screenSize, setScreenSize] = useState<string | null>(null); // desktop | tablet | mobile
  const [offscreenOffsetLeft, setOffscreenOffsetLeft] =
    useState(DEFAULT_OFFSET_LEFT);
  const [offscreenOffsetRight, setOffscreenOffsetRight] =
    useState(DEFAULT_OFFSET_RIGHT);

  const isInView = useInView(wrapperRef, {
    amount: 'all',
    once: true,
    margin: '0% 10% 10% 10%'
  });

  const { scrollYProgress } = useScroll({
    // Track from the START (top) of the container hitting the
    // START (top) of the viewing pane, to the END (bottom) of
    // the container hitting the END (bottom) of the viewing pane.
    offset: ['start start', 'end end'],
    target: containerRef
  });
  // Let's use a smoothed representation of scroll progress, to
  // avoid jumpiness in non-scroll-smoothed browsers/systems.
  const scrollYSmooth = useSpring(scrollYProgress, {
    stiffness: 300,
    damping: 30,
    restDelta: 0.001
  });

  const [scrollYSmoothHooked, setScrollYSmoothHooked] = useState(null);

  const shoe1TranslateX = useTransform(
    scrollYSmooth,
    [MARK_SHOE1_EXIT_START, MARK_SHOE1_EXIT_START + EXIT_DURATION],
    [DEFAULT_OFFSET_CENTER, offscreenOffsetRight]
  );
  const shoe2TranslateX = useTransform(
    scrollYSmooth,
    [
      MARK_SHOE2_ENTRY_START,
      MARK_SHOE2_ENTRY_START + ENTRY_DURATION,
      MARK_SHOE2_EXIT_START,
      MARK_SHOE2_EXIT_START + EXIT_DURATION
    ],
    [
      offscreenOffsetLeft,
      DEFAULT_OFFSET_CENTER,
      DEFAULT_OFFSET_CENTER,
      offscreenOffsetRight
    ]
  );
  const shoe3TranslateX = useTransform(
    scrollYSmooth,
    [MARK_SHOE3_ENTRY_START, MARK_SHOE3_ENTRY_START + ENTRY_DURATION],
    [offscreenOffsetLeft, DEFAULT_OFFSET_CENTER]
  );

  /**
   * Update the sequence index based on scroll progress.
   *
   * @param latest - The latest scroll progress value.
   * @param progress - Optional progress value to use.
   */
  const updateSequence = useCallback(
    (latest: number, progress: number | null = null): void => {
      const seqProgress =
        progress ??
        (1 / (MARK_SHOE_ROLL_END - MARK_SHOE_ROLL_START)) *
          (latest - MARK_SHOE_ROLL_START);
      const index = Math.round((NUM_SEQ_IMAGES - 1) * seqProgress);
      if (index !== seqIndex) {
        setSeqIndex(index);
      }
    },
    [seqIndex]
  );

  /**
   * Prep our scroll interactivity.
   */
  useEffect(() => {
    const unsubscribe = scrollYSmooth.on('change', (latest) => {
      // We track the state of the smoothed Y value primarily
      // to trigger a render for all of the properties that
      // rely on the updated value to properly refresh.
      setScrollYSmoothHooked(latest);

      // Roll the shoe if we're meant to roll...
      if (latest <= MARK_SHOE_ROLL_START) {
        // Ensure we're on the first sequence frame...
        updateSequence(latest, 0);
      } else if (latest <= MARK_SHOE_ROLL_END) {
        // Updating our shoe roll process
        updateSequence(latest);
      } else {
        // Ensure we're on the final sequence frame...
        updateSequence(latest, 1);
      }
    });
    return unsubscribe;
  }, [scrollYSmooth, seqIndex, updateSequence]);

  /**
   * Prep our images.
   */
  useEffect(() => {
    if (!screenSize) return;
    const imageWidth =
      screenSize === 'mobile' ? 600 : screenSize === 'tablet' ? 1280 : 1920;

    // Load from public directory
    const imgPaths: Array<string> = getImagePaths(
      '/images/roll-sequence/',
      'ahnu-shoe-roll-',
      TOTAL_IMAGES,
      1,
      3,
      imageWidth,
      'webp'
    );

    // Load from Core Media
    // const imgPaths: Array<string> | undefined = itemModel?.images?.map(
    //   (image) => {
    //     if (image) {
    //       image.setSrc({ elementWidth: imageWidth });
    //       return image.src;
    //     }
    //     return '';
    //   }
    // );

    const imgs: Array<IImageObject> = getPreloadedImages(imgPaths);
    setImages(imgs);
  }, [screenSize]);

  /**
   * Adjust our offscreen offset values, based on screen ratio.
   */
  useEffect(() => {
    const baseHeight = windowSize?.height ? windowSize.height - 210 : null;
    const baseWidth = windowSize?.width ? windowSize.width : null;

    if (!baseHeight || !baseWidth) return;
    let leftOffset = DEFAULT_OFFSET_LEFT;
    let rightOffset = DEFAULT_OFFSET_RIGHT;
    if (baseWidth / baseHeight > 2.28) {
      const multiplier = baseWidth / baseHeight / 2;
      leftOffset *= multiplier;
      rightOffset *= multiplier;
    }
    setOffscreenOffsetLeft(leftOffset);
    setOffscreenOffsetRight(rightOffset);
  }, [windowSize, DEFAULT_OFFSET_LEFT, DEFAULT_OFFSET_RIGHT]);

  /**
   * Set the canvas dimensions based on the wrapper dimensions.
   */
  const setCanvasDimensions = useCallback((): void => {
    const { width, height } = getFittedCanvasDimensions(
      canvasWrapRef,
      IMG_ASPECT_RATIO
    );

    setDimensions({ width, height });

    // Let's update a CSS variable rather than the inline style
    // of our containers, as motion-framer does not consistently
    // pick up the value changes.
    const container = containerRef.current;
    container?.style.setProperty(
      '--canvas-width',
      width > 0 ? `${width}px` : ''
    );
    container?.style.setProperty(
      '--canvas-height',
      height > 0 ? `${height}px` : ''
    );
  }, [canvasWrapRef]);

  /**
   * Prep our canvas.
   */
  const resizeHandler = useCallback((): void => {
    debounce(500, () => {
      setCanvasDimensions();
      const newScreenWidth = windowSize.width ?? 0;
      const newScreenSize =
        newScreenWidth < 600
          ? 'mobile'
          : newScreenWidth < 1280
          ? 'tablet'
          : 'desktop';
      setScreenWidth(newScreenWidth);
      setScreenSize(newScreenSize);
    });
  }, [
    debounce,
    setCanvasDimensions,
    windowSize,
    setScreenWidth,
    setScreenSize
  ]);

  useEffect(() => {
    resizeHandler();
  }, [resizeHandler]);

  useEffect(() => {
    setCanvasDimensions();
  }, [setCanvasDimensions]);

  // Ensure that the canvas gets updated any time the image
  // collection is updated or the sequence index changes.
  useEffect(() => {
    if (images.length) {
      draw(canvasRef.current, images[seqIndex]);
    }
  }, [images, seqIndex, dimensions.width, dimensions.height]);

  return (
    <div className={S.container} style={styling} ref={containerRef}>
      <motion.div className={S.wrapper} ref={wrapperRef}>
        <BackgroundPanel isInView={isInView} />
        <div className={S.header}>
          <h2 className={S.title}>
            <span>CAPSULE 1 /</span> SS24 / SEQUENCE 1
          </h2>
        </div>
        <div className={S.infoContainer}>
          <div className={S.infoWrap}>
            <UserInfo isInView={isInView} />
          </div>
        </div>
        <div className={S.contentContainer}>
          <div className={S.contentWrap}>
            <div className={S.heading}>
              <span>Performance</span> Footwear designed for the everyday
            </div>
            <div className={S.subHeading}>
              Uncompromised design meets precise engineering and attention to
              detail.
            </div>
            <div className={S.cta}>
              <Link className={S.link} ariaLabelledBy={ariaLabelledById} variant="text" href={CTA_LINK}>
                <Button id={ariaLabelledById} className={S.button} variant="secondary">
                  {CTA_LABEL}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <motion.div
          className={S.canvasWrap}
          ref={canvasWrapRef}
          style={{
            width: 'var(--canvas-width)',
            height: 'var(--canvas-height)',
            transform: `translate(${shoe1TranslateX.get()}%, -50%)`
          }}
        >
          <canvas
            ref={canvasRef}
            className={S.canvas}
            width={dimensions.width}
            height={dimensions.height}
          />
        </motion.div>
        <div
          className={S.imageWrap}
          style={{
            width: 'var(--canvas-width)',
            height: 'var(--canvas-height)',
            transform: `translate(${shoe2TranslateX.get()}%, -50%)`
          }}
        >
          <img src={images[TOTAL_IMAGES - 2]?.path} alt="" />
        </div>
        <div
          className={S.imageWrap}
          style={{
            width: 'var(--canvas-width)',
            height: 'var(--canvas-height)',
            transform: `translate(${shoe3TranslateX.get()}%, -50%)`
          }}
        >
          <img src={images[TOTAL_IMAGES - 1]?.path} alt="" />
        </div>
      </motion.div>
    </div>
  );
};
