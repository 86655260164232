'use client';

import type { IContentItem } from '@/services/models/Content';
import type { AspectRatioName } from '@/services/models/Media/ContentImage';
import { FunctionComponent, ReactElement, useMemo } from 'react';
import { ContentItemModel } from '@/services/models/Content';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { ContentImageModel } from '@/services/models/Media/ContentImage';
import { ContentVideoModel } from '@/services/models/Media/ContentVideo';
import { Slider, Slide, ISliderProps } from '../../../../Slider';
import { ContentMedia } from '../../core/ContentMedia';
import {
  Breakpoint,
  Breakpoints,
  Default
} from '../../../../core-ui/Breakpoints';

import S from './styles.base.module.scss';

export interface IPdpSliderLifestyleProps {
  /**
   * The {@link IContentItem} from which the width and height data and image
   * sources are extracted from to render the lifestyle slides.
   */
  item: IContentItem;
}

const DEFAULT_DESKTOP_RATIO = 'landscape_ratio4x3' as AspectRatioName;
const DEFAULT_MOBILE_RATIO = 'portrait_ratio3x5' as AspectRatioName;

/**
 * Generates slides based on an array of items.
 * @param items - Images off the product model.
 * @param aspectRatio - Aspect ratio.
 * @param size - Screen size reference - determines which image to grab.
 * @returns An array of react elements.
 */
function generateSlides(
  items: ReadonlyArray<ContentItemModel> | undefined,
  aspectRatio: AspectRatioName,
  size: string
): Array<ReactElement> {
  if (!items) return [];

  return items?.map((item, index) => {
    const key = `${item.id}_${index}`;
    const { images, videos } = item;
    let responsiveSourceItem: ContentImageModel | ContentVideoModel | undefined;
    // If an images or videos array is present, use the asset corresponding
    // to the current screen size. Otherwise, use the single image or video.
    if (images && images.length > 0) {
      if (size === 'desktop') {
        [responsiveSourceItem] = images;
      } else {
        [, responsiveSourceItem] = images;
      }
    } else if (videos && videos.length > 0) {
      if (size === 'desktop') {
        [responsiveSourceItem] = videos;
      } else {
        [, responsiveSourceItem] = videos;
      }
    }
    if (responsiveSourceItem) {
      responsiveSourceItem.aspectRatio = aspectRatio;
      responsiveSourceItem.activeAspectRatio = aspectRatio;
    } else {
      const { image, video } = item;
      if (image) {
        image.aspectRatio = aspectRatio;
        image.activeAspectRatio = aspectRatio;
      } else if (video) {
        video.aspectRatio = aspectRatio;
        video.activeAspectRatio = aspectRatio;
      }
    }

    return (
      <Slide key={key}>
        <div className={S.slideContent}>
          <ContentMedia item={responsiveSourceItem ?? item} />
        </div>
      </Slide>
    );
  });
}

/** The lifestyle image carousel for the PDP. */
export const PdpSliderLifestyle: FunctionComponent<
  IPdpSliderLifestyleProps
> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const { custom, items } = itemModel;

  const { mainAspectRatio = DEFAULT_DESKTOP_RATIO } = custom as Record<
    string,
    AspectRatioName
  >;
  const { mobileAspectRatio = DEFAULT_MOBILE_RATIO } = custom as Record<
    string,
    AspectRatioName
  >;
  const desktopItems = useMemo(
    () => generateSlides(items, mainAspectRatio, 'desktop'),
    [items]
  );
  const mobileItems = useMemo(
    () => generateSlides(items, mobileAspectRatio, 'mobile'),
    [items]
  );

  // Arrow icon type depends on brand
  const arrowVariantType = useBrandLocaleValue<string>(
    () => ({
      default: 'arrow',
      AHNU: 'angleLight'
    }),
    []
  );

  const sliderConfig = {
    height: 'auto',
    navigationWrapperClassName: S.navigationWrapper,
    loop: true,
    navigation: {
      inset: true,
      arrowVariant: arrowVariantType,
      buttonClassName: S.navigation
    },
    pagination: {
      inset: false,
      activeClassName: S.activeCircle,
      wrapperClassName: S.pagination,
      buttonClassName: S.circle,
      direction: 'horizontal'
    }
  } as ISliderProps;

  return (
    <div className={S.container}>
      <Breakpoints>
        <Breakpoint media={['xxs', 'xs']}>
          <Slider {...sliderConfig}>{mobileItems}</Slider>
        </Breakpoint>
        <Default>
          <Slider {...sliderConfig}>{desktopItems}</Slider>
        </Default>
      </Breakpoints>
    </div>
  );
};
