import { FunctionComponent } from 'react';

import { IContentItem, ContentItemModel } from '@/services/models/Content';

import classNames from 'classnames';

import { HeroTextSlider } from '../HeroTextSlider';
import { HeroImageSlider } from '../HeroImageSlider';
import {
  getCustomStyling,
  getContainerOptions
} from '../../../utils/styling-utils';

import S from './styles.module.scss';

export interface IHeroSliderProps {
  /** The content item. */
  item: IContentItem;
}

/**
 * `HeroSlider` is similar to `HeroBanner`, but this can
 * contain sliding Texts and Images.
 *
 * To build it we need to create a collection with `hero-slider` layout
 * variant, then add two new collections to items sections in `CoreMedia`,
 * the first collection holds the texts and the second the images.
 *
 */
export const HeroSlider: FunctionComponent<IHeroSliderProps> = ({ item }) => {
  const itemModel = ContentItemModel.from(item);
  const { custom } = itemModel;

  const options = getContainerOptions(custom);
  const styling = getCustomStyling(custom);

  const textBlockSliderItem = itemModel.items?.[0];
  const imageSliderItem = itemModel.items?.[1];

  const classes = classNames(S.container, options.flip ?? '');

  return (
    <div className={classes} style={styling}>
      <div className={S.heroTextSliderContainer}>
        {textBlockSliderItem && <HeroTextSlider item={textBlockSliderItem} />}
      </div>
      <div className={S.heroImageSliderContainer}>
        {imageSliderItem && <HeroImageSlider item={imageSliderItem} />}
      </div>
    </div>
  );
};
